export const GetItemPrice = (itemList: any) => {
  return (itemList?.variation_status === null ||
    itemList?.variation_status === 0) &&
    itemList?.map_store_item?.length > 0
    ? parseFloat(
        itemList?.map_store_item?.sort(
          (a: any, b: any) => a.offered_price - b.offered_price
        )[0]?.offered_price
      ).toFixed(2)
    : itemList?.variation_status == 1 &&
      itemList?.map_store_item_variation?.length > 0 &&
      itemList?.customize_status == 1
    ? parseFloat(
        itemList?.map_store_item_variation?.sort(
          (a: any, b: any) =>
            a.offered_variation_price - b.offered_variation_price
        )[0]?.offered_variation_price
      ).toFixed(2)
    : itemList?.variation_status == 1 &&
      itemList?.map_store_item_variation?.length > 0 &&
      (itemList?.customize_status == null || itemList?.customize_status == 0)
    ? parseFloat(
        itemList?.map_store_item_variation?.sort(
          (a: any, b: any) =>
            a.offered_variation_price - b.offered_variation_price
        )[0]?.offered_variation_price
      ).toFixed(2)
    : itemList?.item_variation?.length > 0
    ? parseFloat(
        itemList?.item_variation?.sort(
          (a: any, b: any) => a.web_price - b.web_price
        )[0]?.web_price
      ).toFixed(2)
    : itemList.web_price
    ? parseFloat(itemList.web_price).toFixed(2)
    : parseFloat("0").toFixed(2);
};

// let fixedTax=0
// let percentageTax=0
// taxdata.map_tax_class.map((tax:any)=>{
//   return(
//     tax?.tax_rate[0]?.tax_type=="fixed"?
//     (
//       fixedTax+=tax?.tax_rate[0].tax_amount
//     ):(
//       percentageTax+=tax?.tax_rate[0].tax_amount
//     )

//    )
// })
