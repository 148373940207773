import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShortName from "../../utils/ShortName";

const HeaderProfilPic = ({ user, handleSidebar }: any) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const checkMobileView = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);

    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  const handleClick = () => {
    if (isMobile) {
      handleSidebar();
    } else {
      navigate("/profile");
    }
  };

  return (
    <div className="" onClick={handleClick}>
      {sessionStorage.getItem("yop-token") !== undefined ? (
        user?.profile_image ? (
          <div className="bgBlue text-white rounded-[7px] text-base text-center py-2 px-3 cursor-pointer">
            {ShortName(`${user?.first_name} ${user?.last_name}`)}
          </div>
        ) : user?.first_name ? (
          <div className="bgBlue text-white rounded-[7px] text-base text-center py-2 px-3 cursor-pointer">
            {ShortName(`${user?.first_name} ${user?.last_name}`)}
          </div>
        ) : (
          <div className="bgBlue text-white rounded-[7px] text-base text-center py-3 px-3 flex cursor-pointer">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M8.00008 1.33334C6.16505 1.33334 4.66675 2.83165 4.66675 4.66668C4.66675 6.5017 6.16505 8.00001 8.00008 8.00001C9.83511 8.00001 11.3334 6.5017 11.3334 4.66668C11.3334 2.83165 9.83511 1.33334 8.00008 1.33334ZM8.00008 2.33334C9.29467 2.33334 10.3334 3.37209 10.3334 4.66668C10.3334 5.96126 9.29467 7.00001 8.00008 7.00001C6.70549 7.00001 5.66675 5.96126 5.66675 4.66668C5.66675 3.37209 6.70549 2.33334 8.00008 2.33334ZM3.99292 9.33334C3.26632 9.33334 2.66675 9.93291 2.66675 10.6595V11.1667C2.66675 12.3681 3.4265 13.2974 4.43563 13.8542C5.44475 14.4109 6.72278 14.6667 8.00008 14.6667C9.27738 14.6667 10.5554 14.4109 11.5645 13.8542C12.425 13.3795 13.0725 12.6209 13.254 11.6667H13.3341V10.6595C13.3341 9.93291 12.7338 9.33334 12.0072 9.33334H3.99292ZM3.99292 10.3333H12.0072C12.1933 10.3333 12.3341 10.4735 12.3341 10.6595V10.6667H12.3334V11.1667C12.3334 11.9653 11.8848 12.5359 11.0815 12.9792C10.2781 13.4224 9.13945 13.6667 8.00008 13.6667C6.86071 13.6667 5.72208 13.4224 4.9187 12.9792C4.11532 12.5359 3.66675 11.9653 3.66675 11.1667V10.6595C3.66675 10.4735 3.80686 10.3333 3.99292 10.3333Z"
                fill="white"
              />
            </svg>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default HeaderProfilPic;
