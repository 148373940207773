const ParseFloat = (value: any) => {
  // const {
  //   store: { selectedStore },
  // }: any = useSelector((state) => state);
  // return `${selectedStore?.country_currency}${Number(value).toFixed(2)}`;
  return `${sessionStorage.getItem("country_currency")}${Number(value).toFixed(
    2
  )}`;
};
export default ParseFloat;
