import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  SETCARTCOUNT,
  SETCARTDATA,
  SETCARTTOTALPRICE,
} from "../../redux/features/CartData";

export default function EmptyCart() {
  const { itemCount } = useSelector((state: any) => state.cartData);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const clearCart = () => {
    sessionStorage.removeItem("items");
    dispatch(SETCARTCOUNT(0));
    dispatch(SETCARTDATA([]));
    dispatch(SETCARTTOTALPRICE(0));
  };
  return (
    <>
      <div className="emptyCart">
        {!itemCount ? (
          <div>
            <div className="Icon">
              <svg width="111" height="111" viewBox="0 0 111 111" fill="none">
                <path
                  d="M8.09335 13.875C7.6337 13.8685 7.17734 13.9534 6.75079 14.1248C6.32425 14.2962 5.93602 14.5507 5.60868 14.8734C5.28133 15.1962 5.0214 15.5808 4.84398 16.0048C4.66656 16.4289 4.5752 16.884 4.5752 17.3437C4.5752 17.8034 4.66656 18.2585 4.84398 18.6826C5.0214 19.1067 5.28133 19.4913 5.60868 19.814C5.93602 20.1368 6.32425 20.3913 6.75079 20.5627C7.17734 20.7341 7.6337 20.819 8.09335 20.8125H14.4663C16.1496 20.8125 17.5528 21.9755 17.8718 23.6308L18.5628 27.2441L26.0197 66.4076C27.3682 73.4735 33.5936 78.625 40.789 78.625H84.0852C91.2806 78.625 97.5086 73.4755 98.8545 66.4076L106.311 27.2441C106.407 26.7427 106.391 26.2263 106.264 25.7319C106.137 25.2374 105.903 24.777 105.577 24.3836C105.252 23.9902 104.844 23.6734 104.382 23.4558C103.92 23.2383 103.416 23.1253 102.906 23.125H24.8364L24.6873 22.3346C23.7551 17.449 19.4407 13.875 14.4663 13.875H8.09335ZM26.1598 30.0625H98.7144L92.0389 65.1113C91.3081 68.9492 87.9944 71.6875 84.0852 71.6875H40.789C36.8812 71.6875 33.5697 68.949 32.8353 65.1113V65.1068L26.1598 30.0625ZM46.2496 83.25C44.4097 83.25 42.6451 83.9809 41.344 85.2819C40.043 86.583 39.3121 88.3475 39.3121 90.1875C39.3121 92.0274 40.043 93.792 41.344 95.093C42.6451 96.3941 44.4097 97.125 46.2496 97.125C48.0895 97.125 49.8541 96.3941 51.1552 95.093C52.4562 93.792 53.1871 92.0274 53.1871 90.1875C53.1871 88.3475 52.4562 86.583 51.1552 85.2819C49.8541 83.9809 48.0895 83.25 46.2496 83.25ZM78.6246 83.25C76.7847 83.25 75.0201 83.9809 73.719 85.2819C72.418 86.583 71.6871 88.3475 71.6871 90.1875C71.6871 92.0274 72.418 93.792 73.719 95.093C75.0201 96.3941 76.7847 97.125 78.6246 97.125C80.4645 97.125 82.2291 96.3941 83.5301 95.093C84.8312 93.792 85.5621 92.0274 85.5621 90.1875C85.5621 88.3475 84.8312 86.583 83.5301 85.2819C82.2291 83.9809 80.4645 83.25 78.6246 83.25Z"
                  fill="#707070"
                />
              </svg>
            </div>
            <h4 className="fs-30">{t("cartEmpty")}</h4>
          </div>
        ) : (
          <button className="btn" onClick={clearCart}>
            {t("clearCart")}
          </button>
        )}
      </div>
    </>
  );
}
