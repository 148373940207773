import { yupResolver } from "@hookform/resolvers/yup";
import { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import updateProfile from "../../api/UpdateProfile";
import { interfaceProfileState } from "../../interface/ProfileInterface";
import { SETUSERDATA } from "../../redux/features/userData";
import LoaderButton from "../Buttons/LoaderButton";
import InputNumber from "../FormFields/InputNumber";
import InputText from "../FormFields/InputText";
import LabelText from "../FormLabels/LabelText";
import { ErrorToast } from "../ToastComponent/ErrorToast";
import { SuccessToast } from "../ToastComponent/SuccessToast";
import { initialmyAccountState, myAccountSchema } from "./MyAccountValidation";

export default function MyAccount() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [profileImage, setProfileImage] = useState();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getFieldState,
  } = useForm({
    resolver: yupResolver(myAccountSchema),
    defaultValues: initialmyAccountState,
  });
  const {
    userData: { user },
  }: any = useSelector((state) => state);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (Object.keys(user).length > 0) {
      setValue("mobileNumber", user.phone);
      setValue("first_name", user?.first_name);
      setValue("last_name", user?.last_name);
      setValue("email", user.email);
      setPreviewUrl(
        `${user?.profile_image?.base_url ?? ""}${
          user?.profile_image?.attachment_url ?? ""
        }`
      );
    }
  }, [user]);
  const onSubmitHandler = async (state: interfaceProfileState) => {
    setLoading(true);
    try {
      const data = await updateProfile({ ...state, profileImage });

      if (data.success) {
        dispatch(SETUSERDATA(data.user_data));
        SuccessToast(data?.message);
      } else {
        ErrorToast(data?.message);
      }
      setLoading(false);
    } catch (error: any) {
      ErrorToast(error?.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="p-3">
          <div className="flex gap-3 items-center mb-3 md:flex-row flex-col">
            <div className="profileImage mb-3">
              <div className="addImage">
                <div className="showProfile">
                  <img
                    src={
                      previewUrl ? previewUrl : "/images/profile-user-icon.svg"
                    }
                    alt="Profile Image"
                  />
                </div>
              </div>
            </div>
            <label className="bg-[#030027] text-white px-8 py-2 font-semibold text-[.8em] rounded-[13px] cursor-pointer">
              <input
                type="file"
                placeholder="Name"
                onChange={(e: any) => {
                  setProfileImage(e.target.files[0]);
                  setPreviewUrl(URL.createObjectURL(e.target.files[0]));
                }}
                accept="image/*"
                className="hidden"
              />
              {t("Profile.upload")}
            </label>
          </div>

          <div className="formItem">
            <LabelText text={t("Profile.firstName")} />
            <InputText
              placeholder={t("Profile.firstName")}
              name="first_name"
              register={register}
            />
            <div className="errorField">
              {errors.first_name?.message ? t(errors.first_name?.message) : ""}
            </div>
          </div>
          <div className="formItem">
            <LabelText text={t("Profile.lastName")} />
            <InputText
              placeholder={t("Profile.lastName")}
              name="last_name"
              register={register}
            />
            <div className="errorField">
              {errors.last_name?.message ? t(errors.last_name?.message) : ""}
            </div>
          </div>
          <div className="formItem read-only">
            <LabelText text={t("Profile.phoneNumber")} />
            <InputNumber
              placeholder="1234567890"
              name="mobileNumber"
              register={register}
              readOnly={true}
            />
            <div className="errorField">
              {errors.mobileNumber?.message
                ? t(errors.mobileNumber?.message)
                : ""}
            </div>
          </div>
          <div className="formItem">
            <LabelText text={t("Profile.email")} />
            <InputText
              placeholder="useremail@gmail.com"
              name="email"
              register={register}
            />
            <div className="errorField">{errors.email?.message || ""}</div>
          </div>
        </div>
        <div className="flex md:justify-end gap-3 mt-3 md:static fixed bottom-0 p-2 bg-white w-full">
          <div className="btnAction md:w-auto w-full">
            {loading ? (
              <LoaderButton />
            ) : (
              <button className="md:w-auto w-full btn-secondary" type="submit">
                {t("Profile.save")}
              </button>
            )}
          </div>
        </div>
      </form>
      <ToastContainer />
    </>
  );
}
