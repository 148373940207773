export const SET_ADDRESS_DATA = "SET_ADDRESS_DATA";
export const SELECTED_NEW_ADDRESS = "SELECTED_NEW_ADDRESS";
export const SELECTED_SAVED_ADDRESS = "SELECTED_SAVED_ADDRESS";

export const SET_SELECTED_ADDRESS_TYPE = "SET_SELECTED_ADDRESS_TYPE";
export const SET_TEMP_ADDRESS = "SET_TEMP_ADDRESS";
export const SETADDRESSDATA = (data: any) => {
  return {
    type: SET_ADDRESS_DATA,
    payload: data,
  };
};
export const SETSELECTEDNEWADDRESS = (data: any) => {
  return {
    type: SELECTED_NEW_ADDRESS,
    payload: data,
  };
};
export const SETSELECTEDSAVEDADDRESS = (data: any) => {
  return {
    type: SELECTED_SAVED_ADDRESS,
    payload: data,
  };
};
export const SETSELECTEDADDRESSType = (data: any) => {
  return {
    type: SET_SELECTED_ADDRESS_TYPE,
    payload: data,
  };
};
export const SETTEMPADDRESS = (data: any) => {
  return {
    type: SET_TEMP_ADDRESS,
    payload: data,
  };
};
const initialState: any = {
  address: [],
  selectedAddress: { newAddress: {}, saveAddress: {} },
  selectedAddressType: 0,
  tempAddress: {},
};

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ADDRESS_DATA:
      return {
        ...state,
        address: payload,
      };
    case SELECTED_NEW_ADDRESS:
      return {
        ...state,
        selectedAddress: { ...state.selectedAddress, newAddress: payload },
      };

    case SELECTED_SAVED_ADDRESS:
      return {
        ...state,
        selectedAddress: { ...state.selectedAddress, saveAddress: payload },
      };
    case SET_SELECTED_ADDRESS_TYPE:
      return {
        ...state,
        selectedAddressType: payload,
      };
    case SET_TEMP_ADDRESS:
      return {
        ...state,
        selectedAddress: { ...state.selectedAddress, tempAddress: payload },
      };
    default:
      return state;
  }
};

export default reducer;
