import axiosInstance from "../Axios-Config/Axios-Config";
import { SETADDRESSDATA } from "../redux/features/address";

export async function GetAllOrderApi() {
  try {
    const {
      data: {
        success,
        order_list: { data },
      },
    } = await axiosInstance.get("recent/order");
    if (success) {
      return { order_list: data };
    } else {
      return { order_list: [] };
    }
  } catch (error: any) {
    return { order_list: [] };
  }
}
export async function GetAllAddress(dispatch: Function) {
  try {
    const response = await axiosInstance.get("get/user/address");
    dispatch(SETADDRESSDATA(response?.data?.user_address_list));
  } catch (error: any) {
    dispatch(SETADDRESSDATA([]));
  }
}
