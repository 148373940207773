import {
  SETCARTCOUNT,
  SETCARTDATA,
  SETCARTTOTALPRICE,
} from "../redux/features/CartData";
import {
  CLEARCUSTOMIZEDDATA,
  SETCUSTOMIZEDDATA,
} from "../redux/features/CustomizedData";
import {
  CLEARVARIATIONDATA,
  SETVARIATIONDATA,
} from "../redux/features/Variation";
import { GetItemPrice } from "./GetItemPrice";
import { GetTotalPrice } from "./GetTotalPrice";
import { ItemCount } from "./ItemCount";
import { TaxCalcualate } from "./TaxCalculate";

const AddToCart = async (
  data: any,
  item: any,
  items: any,
  disPatch: Function,
  itemCount: number,
  menu_id: number,
  taxdata: any,
  setOpen: Function
) => {
  disPatch(CLEARVARIATIONDATA({}));
  disPatch(CLEARCUSTOMIZEDDATA({}));
  if (
    data?.item_variation?.length > 0 &&
    data?.variation_status != 0 &&
    data?.customize_status == 0
  ) {
    disPatch(SETVARIATIONDATA(item));
    setOpen(true);
  } else if (data?.customize_status === 0) {
    if (items?.length) {
      const find = items.find((d: any) => d.item_id === data.item_id);
      let itemPrice = GetItemPrice(data);
      if (find) {
        let TaxData = TaxCalcualate(
          taxdata,
          parseFloat(find.sub_total) + parseFloat(itemPrice),
          parseInt(find.quantity || 0) + 1
        );

        data["quantity"] = parseInt(find.quantity) + 1;
        data["selectedCategory"] = [];
        data["selectedoptions"] = [];
        data["selected_addOn"] = [];
        data["sub_total"] = parseFloat(find.sub_total) + parseFloat(itemPrice);
        data["tax_rate_list"] = TaxData.taxArray;

        data["total_price"] =
          parseFloat(find.total_price) + parseFloat(itemPrice);
        data["uniqueIndex"] = find.uniqueIndex;
        data["price"] = itemPrice;
        data["menu_id"] = menu_id;

        const index = items.indexOf(find);
        items.splice(index, 1, data);
        disPatch(SETCARTDATA(items));
        disPatch(SETCARTTOTALPRICE(GetTotalPrice(items)));
        disPatch(SETCARTCOUNT(ItemCount(items)));
        sessionStorage.setItem("items", JSON.stringify(items));
      } else {
        let TaxData = TaxCalcualate(taxdata, Number(itemPrice), 1);

        data["uniqueIndex"] = items[items.length - 1]?.uniqueIndex + 1;
        data["quantity"] = 1;
        data["sub_total"] = Number(itemPrice);
        data["total_price"] =
          Number(itemPrice) +
          TaxData.fixedTax +
          (Number(itemPrice) * TaxData.percentageTax) / 100;
        data["selectedCategory"] = [];
        data["selectedoptions"] = [];
        data["selected_addOn"] = [];
        data["price"] = itemPrice;
        data["menu_id"] = menu_id;
        item["map_tax_class"] = taxdata.map_tax_class;
        item["tax_class"] = taxdata.tax_class;
        data["tax_rate_list"] = TaxData.taxArray;

        items.push(data);
        disPatch(SETCARTTOTALPRICE(GetTotalPrice(items)));
        disPatch(SETCARTCOUNT(ItemCount(items)));
        disPatch(SETCARTDATA(items));
        sessionStorage.setItem("items", JSON.stringify(items));
      }
    } else {
      let itemPrice = GetItemPrice(data);
      let TaxData = TaxCalcualate(taxdata, parseFloat(itemPrice), 1);
      data["uniqueIndex"] = 1;
      data["tax_rate_list"] = TaxData.taxArray;
      data["quantity"] = 1;
      data["sub_total"] = Number(itemPrice);
      data["total_price"] =
        Number(itemPrice) +
        TaxData.fixedTax +
        (Number(itemPrice) * TaxData.percentageTax) / 100;
      data["selectedCategory"] = [];
      data["selectedoptions"] = [];
      data["selected_addOn"] = [];
      data["price"] = itemPrice;
      data["menu_id"] = menu_id;
      item["map_tax_class"] = taxdata.map_tax_class;
      item["tax_class"] = taxdata.tax_class;
      disPatch(SETCARTDATA([data]));
      disPatch(SETCARTTOTALPRICE(GetTotalPrice([data])));
      disPatch(SETCARTCOUNT(ItemCount([data])));
      sessionStorage.setItem("items", JSON.stringify([data]));
    }
  } else {
    disPatch(SETCUSTOMIZEDDATA(item));
    setOpen(true);
  }
};

export default AddToCart;
