export default function Suspence() {
  return (
    <>
      <div className='logoFullPage'>
        <div className='onLoading'>
          <img src="/logo.png" width={180} height={37} alt="Logo" />

        </div>
      </div>
    </>
  )
}
