import { SETSELECTEDCATEGORY } from "../../redux/features/categoryList";

function debouce(call: any, time: any) {
  var timeId: any;
  return function (args: any, dis: any) {
    if (timeId) clearTimeout(timeId);

    timeId = setTimeout(() => {
      call(args, dis);
    }, time);
  };
}
function callApi(a: any, dispatch: any) {
  dispatch(SETSELECTEDCATEGORY(a));
}
export const debouceFunction = debouce(callApi, 200);
