import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Axios-Config/Axios-Config";
import { SETUSERDATA } from "../../redux/features/userData";
import InputText from "../FormFields/InputText";
import { ErrorToast } from "../ToastComponent/ErrorToast";
import { SuccessToast } from "../ToastComponent/SuccessToast";
import { registerFormInitial, registerFormSchema } from "./RegisterValidation";

export default function RegisterView() {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(registerFormSchema),
    defaultValues: registerFormInitial,
  });

  const registerFormSubmit = async (state: any) => {
    setIsSubmitting(false);
    try {
      const { data } = await axiosInstance.post("/complete/profile", state);
      if (data.success) {
        SuccessToast(data.message);
        dispatch(SETUSERDATA(data?.user_data));
        reset();
        navigate("/menu");
      } else {
        ErrorToast(data.message);
      }
      return false;
    } catch (error: any) {
      ErrorToast(error.message);
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(registerFormSubmit)}>
        <div className=" bg-[#F3F5FA] h-screen md:p-10">
          <div className="store-card max-w-[30rem] !p-6 m-auto h-screen md:h-auto">
            <h1 className="font-bold text-2xl">Complete your profile</h1>
            <p className="text-[#606060] text-lg mb-3">
              We need your name & email in order to complete your profile.
            </p>
            <div className="formItem">
              <InputText
                placeholder={t("Register.enterFirstName")}
                name="first_name"
                register={register}
              />
              <div className="errorField">
                {errors.first_name?.message
                  ? t(errors.first_name?.message)
                  : ""}
              </div>
            </div>
            <div className="formItem">
              <InputText
                placeholder={t("Register.enterLastName")}
                name="last_name"
                register={register}
              />
              <div className="errorField">
                {errors.last_name?.message ? t(errors.last_name?.message) : ""}
              </div>
            </div>
            <div className="formItem">
              <InputText
                placeholder={t("Register.enterEmail")}
                name="email"
                register={register}
              />
              <div className="errorField">
                {errors.email?.message ? t(errors.email?.message) : ""}
              </div>
            </div>

            <button className="bgBlue text-white text-center radius p-3 w-full font-bold">
              Submit
            </button>
          </div>
        </div>
      </form>
      {/* <div className="grid md:grid-cols-2 p-3 h-screen">
        <div className="md:mx-20 mx-5">
          <div className="flex justify-between">
            <div className="logo">
              <img src="/logo.png" />
            </div>
          </div>
          <div className="my-16">
            <h1 className="text-4xl font-medium mb-3">
              {t("Register.register")}
            </h1>
            <p className="text-base font-medium">
              {t("Register.registerText")}
            </p>
          </div>
          <form onSubmit={handleSubmit(registerFormSubmit)} className="mb-10">
            <div className="formItem">
              <LabelText text={t("Register.firstName")} />
              <InputText
                placeholder={t("Register.enterFirstName")}
                name="first_name"
                register={register}
              />
              {errors?.first_name?.message ? errors?.first_name?.message : ""}
            </div>
            <div className="formItem">
              <LabelText text={t("Register.lastName")} />
              <InputText
                placeholder={t("Register.enterLastName")}
                name="last_name"
                register={register}
              />
              {errors?.last_name?.message ? errors?.last_name?.message : ""}
            </div>
            <div className="formItem">
              <LabelText text={t("Register.email")} />
              <InputText
                placeholder={t("Register.enterEmail")}
                name="email"
                register={register}
              />
              {errors?.email?.message ? errors?.email?.message : ""}
            </div>
            <button type="submit" className="btnBlue !rounded-full mt-10">
              {t("Register.register")}
            </button>
            <Link
              to="/menu"
              className="text-center textBlue p-2 cursor-pointer block"
            >
              {t("Register.skip")}
            </Link>
          </form>
        </div>

        <div className="rounded-lg overflow-hidden hidden md:block">
          <img src="/images/login-banner.png" />
        </div>
      </div> */}
    </>
  );
}
