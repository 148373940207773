export const SET_CUSTOMIZED_DATA = "SET_CUSTOMIZED_DATA";
export const CLEAR_CUSTOMIZED_DATA = "CLEAR_CUSTOMIZED_DATA";

export const SETCUSTOMIZEDDATA = (data: any) => {
  return {
    type: SET_CUSTOMIZED_DATA,
    payload: data,
  };
};
export const CLEARCUSTOMIZEDDATA = (data: any) => {
  return {
    type: CLEAR_CUSTOMIZED_DATA,
    payload: data,
  };
};

const initialState: Object = {};

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CUSTOMIZED_DATA:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_CUSTOMIZED_DATA:
      return {
        ...payload,
      };

    default:
      return state;
  }
};

export default reducer;
