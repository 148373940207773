export const SET_ALL_ORDER_DATA = "SET_ALL_ORDER_DATA";
export const CLEAR_ALL_ORDER = "CLEAR_ALL_ORDER";
export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL";
export const SETALLORDERDATA = (data: any) => {
  return {
    type: SET_ALL_ORDER_DATA,
    payload: data,
  };
};
export const CLEARALLORDER = () => {
  return {
    type: CLEAR_ALL_ORDER,
    payload: [],
  };
};
export const SETORDERDETAIL = (data: any) => {
  return {
    type: SET_ORDER_DETAIL,
    payload: data,
  };
};
const initialState: Object = { orders: [], orderDetail: {} };

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ALL_ORDER_DATA:
      return {
        ...state,
        orders: payload,
      };
    case CLEAR_ALL_ORDER:
      return {
        ...state,
        orders: payload,
      };
    case SET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: payload,
      };
    default:
      return state;
  }
};

export default reducer;
