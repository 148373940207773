export const Arabic = {
  Extra_Large: "كبير جدا",
  Large: "كبير",
  Medium: "واسطة",
  Small: "صغير",
  Customize_Shake: "تخصيص اهتز",
  Shakes001: "يهز001",
  Vanila_Choc_Shake: "فانيلا شوك شيك",
  Mixed_Fruit_Juice: "عصير فواكه مشكلة",
  Mocktail: "موكتيل",
  Shakes: "يهز",
  Juices: "العصائر",
  clearCart: "عربة واضحة",
  Login: {
    forgot: "پاسورڈ بھول گے؟",
    customerName: "گاہک کا نام",
    mobile: "موبائل فون کانمبر",
    signme: "مجھے دستخط کریں۔",
    submit: "جمع کرائیں",
    enterCustomer: "گاہک کا نام درج کریں۔",
    register: "إذا لم يكن لديك حساب سجل",
    welcome: "مرحبا بكم في يوب",
  },
  VerifyOtp: {
    heading: "Enter OTP sent on your number",
    submit: "Verify OTP",
    error: "Enter correct OTP",
    dontReceive: "Didn’t receive any OTP ?",
    resentOtp: "Resend OTP",
    enterOtp: "أدخل كلمة المرور لمرة واحدة",
    continue: "يكمل",
    info: "تم إرسال الرمز المكون من 6 أرقام إلى هذا الرقم",
    newNumber: "أدخل رقما جديدا",
    cancel: "يلغي",
    update: "بدلاً من",
  },
  Forgot: {
    newPassword: "كلمة المرور الجديدة",
    confirmPassword: "تأكيد كلمة المرور الجديدة",
    enterNewPassword: "أدخل كلمة المرور الجديدة",
    enterConfirmPassword: "أدخل تأكيد كلمة المرور الجديدة",
    submit: "يُقدِّم",
  },
  NotFound: {
    line1: "لم يتم العثور على الصفحة",
    line2: "أُووبس! لم نتمكن من العثور على الصفحة التي تبحث عنها",
  },
  Register: {
    register: "يسجل",
    registerText: "إذا لم يكن لديك حساب سجل",
    firstName: "الاسم الأول",
    lastName: "اسم العائلة",
    email: "بريد إلكتروني",
    enterFirstName: "أدخل اسمك الأول",
    enterLastName: "أدخل اسمك الأخير",
    enterEmail: "أدخل البريد الإلكتروني",
    skip: "اتركه",
  },
  Settings: {
    settings: "إعدادات",
    profileDetails: "تفاصيل الملف الشخصي",
    orders: "طلبات",
    myAddress: "عنواني",
    changePassword: "تغيير كلمة المرور",
    oldPassword: "كلمة المرور القديمة",
    newPassword: "كلمة المرور الجديدة",
  },
  Profile: {
    profile: "حساب تعريفي",
    upload: "تحميل الصورة",
    firstName: "الاسم الأول",
    lastName: "اسم العائلة",
    phoneNumber: "رقم التليفون",
    email: "بريد إلكتروني",
    cancel: "يلغي",
    save: "يؤمن",
    edit: "يحرر",
    mobileNumberRequired: "رقم الجوال مطلوب",
    firstNameRequired: "الاسم الأول مطلوب",
    lastNameRequired: "الاسم الأخير مطلوب",
    invalidEmail: "عنوان البريد الإلكتروني غير صالح",
    emailRequired: "البريد الإلكتروني مطلوب",
  },
  MyOrders: {
    noOrders: "لا أوامر",
    reOrder: "إعادة الترتيب",
    orderPlaceOn: "تم وضع الطلب على",
  },
  MyAddress: {
    myAddress: "عنواني",
    noAddress: "لم تتم إضافة أي عنوان بعد",
    newAddress: "العنوان الجديد",
    edit: "يحرر",
    delete: "يمسح",
    addAddress: "إضافة عنوان",
    editAddress: "تحرير العنوان",
    addressType: "نوع العنوان",
    home: "بيت",
    office: "مكتب",
    other: "آخر",
    fullAddress: "العنوان الكامل",
    enterFullAddress: "أدخل عنوانك الكامل",
    houseNo: "رقم المنزل",
    enterHouseNo: "أدخل رقم منزلك.",
    streetName: "اسم الشارع",
    enterStreetName: "أدخل اسم الشارع الخاص بك",
    country: "دولة",
    enterCountry: "أدخل بلدك",
    state: "ولاية",
    district: "يصرف",
    enterDistrict: "أدخل منطقتك",
    city: "مدينة",
    enterCity: "أدخل مدينتك",
    zipCode: "الرمز البريدي",
    enterZipCode: "أدخل الرمز البريدي الخاص بك",
    submit: "يُقدِّم",
    addressRequired: "العنوان مطلوب",
    fullAddressRequired: "العنوان الكامل مطلوب",
    houseNoRequired: "مطلوب رقم البيت",
    streetNameRequired: "اسم الشارع مطلوب",
    countryRequired: "البلد مطلوب",
    districtRequired: "المنطقة المطلوبة",
    cityRequired: "المدينة مطلوبة",
    stateRequired: "الدولة المطلوبة",
  },
  OrderSuccess: {
    orderConfirmed: "تم تأكيد الطلب",
    thankYou: "شكرًا لك",
    orderOnWay: "طلبك في الطريق",
    continueOrder: "متابعة الطلب",
  },
  CartDetails: {
    subTotal: "المجموع الفرعي",
    total: "المجموع",
    items: "أغراض",
    price: "سعر",
    checkOut: "الدفع",
  },
  SearchFilter: {
    enterAddress: "الرجاء إدخال العنوان",
  },
  Header: {
    notification: "إشعار",
    profile: "حساب تعريفي",
    myOrders: "أوامري",
    logout: "تسجيل الخروج",
    selectOneItem: "الرجاء تحديد عنصر واحد على الأقل",
  },
  Checkout: {
    delivery: "توصيل",
    collection: "مجموعة",
    deliveryDetails: "تفاصيل التسليم",
    firstName: "الاسم الأول",
    lastName: "اسم العائلة",
    enterFirstName: "الرجاء إدخال الاسم الأول",
    enterLastName: "الرجاء إدخال الاسم الأخير",
    paymentMethod: "طريقة الدفع",
    addPaymentMethod: "أضف طريقة الدفع",
    orderSummary: "ملخص الطلب",
    items: "أغراض",
    price: "سعر",
    continueToPayment: "الاستمرار في الدفع",
    orderTotal: "إجمالي الطلب",
    subTotal: "المجموع الفرعي",
    serviceFee: "رسوم الخدمة",
    deliveryFee: "رسوم التوصيل",
    firstNameLength: "يجب أن يتكون الاسم الأول من أربعة أحرف على الأقل",
    lastNameLength: "يجب أن يتكون الاسم الأخير من أربعة أحرف على الأقل",
    pleaseSelect: "الرجاء التحديد",
    firstNameRequired: "الاسم الأول مطلوب",
    lastNameRequired: "الاسم الأخير مطلوب",
    deliveryAddress: "عنوان التسليم",
  },
};
