const GetRemainingAmount = (
  price: number,
  discount: number,
  store: any,
  type: number
) => {
  if (
    type === 3 &&
    store?.store_setting?.minimum_order_fees - (price - discount) > 0
  ) {
    return store.store_setting?.minimum_order_fees - (price - discount);
  }
  return 0;
};

export default GetRemainingAmount;
