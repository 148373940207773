import { ErrorToast } from "../../components/ToastComponent/ErrorToast";
interface selectOptionData {
  e: any;
  optionData: any;
  stepList: any;
  stepOptionId: any;
  setStepOptionId: Function;
}
const selectOptionData = ({
  e,
  optionData,
  stepList,
  stepOptionId,
  setStepOptionId,
}: selectOptionData) => {
  if (stepList.option_selection === 0) {
    const steps = [...stepOptionId];
    const find = stepOptionId.find((e: any) => e.step_id === optionData.step_id);
    if (find) {
      const stepIndex = stepOptionId.indexOf(find);
      steps.splice(stepIndex, 1);
      steps.push(optionData);
      setStepOptionId(steps);
    } else {
      steps.push(optionData);
      setStepOptionId(steps);
    }
  }
  if (stepList.option_selection === 1) {
    if (e.target.checked) {
      const steps = [];
      if (stepOptionId.length > 0) {
        var result = stepOptionId.filter((obj: any) => {
          return obj.step_id === stepList.item_step_id;
        });
        if (stepList.max_count > result.length) {
          const addData = [...stepOptionId, optionData];
          setStepOptionId(addData);
        } else {
          (
            document.getElementById(
               optionData.item_step_option_id
            ) as HTMLInputElement
          ).checked = false;
          ErrorToast(`You can add maximum ${stepList?.max_count}`);
        }
      } else {
        if (stepList.max_count == 0) {
          (
            document.getElementById(
               optionData.item_step_option_id
            ) as HTMLInputElement
          ).checked = false;
          ErrorToast(`You can add maximum ${stepList?.max_count}`);
        } else {
          steps.push(optionData);
          setStepOptionId(steps);
        }
      }
    } else {
      const removeData = [...stepOptionId];
      const find = stepOptionId.find(
        (dataa: any) => dataa?.item_step_option_id == optionData?.item_step_option_id
      );
      const index = removeData.indexOf(find);
      if (index != -1) {
        removeData.splice(index, 1);
        const newData = removeData;
        setStepOptionId(newData);
      }
    }
  }
};
export default selectOptionData;
