import React from "react";

export default function ItemTitleDetail(props) {
  return (
    <>
      <div className="bg-[#F3F5FA] px-3 py-2">
        {props.itemTitle && (
          <h4 className="text-base font-bold ">{props.itemTitle}</h4>
        )}
        {props.itemDetail && <p className="textMd">{props.itemDetail}</p>}
      </div>
    </>
  );
}
