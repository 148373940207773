export const English = {
  poweredBy: "Powered by",
  cartEmpty: "Your cart is empty",
  change: "Change",
  mycart: "My Cart",
  clearCart: "Clear Cart",
  NotFound: {
    line1: "Page Not Found.",
    line2: "Oops! We couldn't find the page that you are looking for.",
  },
  Login: {
    forgot: "Forgot Password?",
    customerName: "Customer Name",
    mobile: "Mobile Number",
    signme: "Sign me in",
    submit: "Next",
    enterCustomer: "Enter Customer Name",
    enterMobile: "Enter Mobile Number",
    customerRequired: "Customer Name is required.",
    customerChar: "Customer name must be at lease 4 characters",
    mobileRequired: "Invalid Phone No.",
    welcome: "Welcome to YOP",
    register: "If you don’t have an account register",
  },
  VerifyOtp: {
    heading: "Enter OTP sent on your number",
    submit: "Verify OTP",
    error: "Enter correct OTP",
    dontReceive: "Didn’t receive any OTP ?",
    resentOtp: "Resend OTP",
    enterOtp: "Enter OTP",
    continue: "Continue",
    info: "An 6 digit code has been sent to",
    newNumber: "Enter new number",
    cancel: "Cancel",
    update: "Update",
  },
  Forgot: {
    newPassword: "New Password",
    confirmPassword: "Confirm New Password",
    enterNewPassword: "Enter New Password",
    enterConfirmPassword: "Enter Confirm New Password",
    submit: "Submit",
  },
  Home: {
    delivery: "Delivery",
    collection: "Collection",
    pickCol: "Click & Collect / Delivery",
    orderOnline: "Order Online",
    away: "away",
  },
  Register: {
    register: "Register",
    registerText: "If you don’t have an account register",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    enterFirstName: "Enter Your First Name",
    enterLastName: "Enter Last Name",
    enterEmail: "Enter Email",
    skip: "Skip",
  },
  Settings: {
    settings: "Settings",
    profileDetails: "Profile Details",
    orders: "Orders",
    myAddress: "My Address",
    changePassword: "Change Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
  },
  Profile: {
    profile: "Profile",
    upload: "Upload Picture",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    email: "Email",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    mobileNumberRequired: "Mobile Number required",
    firstNameRequired: "First Name required",
    lastNameRequired: "Last Name required",
    invalidEmail: "Invalid email address",
    emailRequired: "Email required",
  },
  MyOrders: {
    noOrders: "No Orders",
    reOrder: "Reorder",
    orderPlaceOn: "Order Placed on",
  },
  MyAddress: {
    myAddress: "My Address",
    noAddress: "No address added yet",
    newAddress: "New Address",
    edit: "Edit",
    delete: "Delete",
    addAddress: "Add Address",
    editAddress: "Edit Address",
    addressType: "Address Type",
    home: "Home",
    office: "Office",
    other: "Other",
    fullAddress: "Full Address",
    enterFullAddress: "Enter Your Full Address",
    houseNo: "House No.",
    enterHouseNo: "Enter Your House No.",
    streetName: "Street Name",
    enterStreetName: "Enter Your Street Name",
    country: "Country",
    enterCountry: "Enter Your Country",
    state: "State",
    district: "District",
    enterDistrict: "Enter Your District",
    city: "City",
    enterCity: "Enter Your City",
    zipCode: "Postal Code",
    enterZipCode: "Enter Your Zip Code",
    submit: "Submit",
    addressRequired: "Address Required",
    fullAddressRequired: "Please enter your full address",
    houseNoRequired: "Please enter your house number",
    streetNameRequired: "Please enter your street name",
    countryRequired: "Please enter your country",
    districtRequired: "Please enter your district",
    cityRequired: "Please enter your city",
    stateRequired: "Please enter your state",
    loading : "Loading..",
    actions : "Actions",
    cancel: "Cancel",

  },
  OrderSuccess: {
    orderConfirmed: "Order Confirmed",
    thankYou: "Thank you!",
    orderOnWay: "Your order is on the way",
    continueOrder: "Continue Order",
    viewOrder : "View Order"
  },
  CartDetails: {
    subTotal: "SubTotal",
    total: "Total",
    items: "Items",
    price: "Price",
    checkOut: "Checkout",
  },
  SearchFilter: {
    enterAddress: "Please enter address",
  },
  Header: {
    notification: "Notification",
    profile: "Profile",
    myOrders: "My Orders",
    logout: "Logout",
    selectOneItem: "Please select atleast one item",
  },
  Checkout: {
    delivery: "Delivery",
    collection: "Collection",
    deliveryDetails: "Delivery details",
    firstName: "First Name",
    lastName: "Last Name",
    enterFirstName: "Please Enter First Name",
    enterLastName: "Please Enter Last Name",
    paymentMethod: "Payment method",
    addPaymentMethod: "Add Payment Method",
    orderSummary: "Order Summary",
    items: "Items",
    price: "Price",
    continueToPayment: "Continue to payment",
    orderTotal: "Order total",
    subTotal: "Subtotal",
    serviceFee: "Service Fee",
    deliveryFee: "Delivery Fee",
    firstNameLength: "First Name must be at least 4 characters",
    lastNameLength: "First Name must be at least 4 characters",
    pleaseSelect: "Please select",
    firstNameRequired: "First Name is required",
    lastNameRequired: "Last Name is required",
    deliveryAddress: "Delivery address",
    confirmOrder : "Confirm & Pay",
    cancel : "Cancel",
    save : "Save"
  },

  NoStoreFound :{
    NotFound : {}
  },

  HamburgerMenu : {
     logout: "Logout"
  },
  ProfileSidebar : {
     logout: "Logout"
  },

  EditAddress : {
    cancel: "Cancel",
    save: "Save",
  }
};
