import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { GetAllAddress } from "../../../api/GetAddress";
import { SETSELECTEDSAVEDADDRESS } from "../../../redux/features/address";
import { DeliveryPriceAndOrderFee } from "../../../utils/DeliveryPriceAndOrderFee";

const SavedAddress = () => {
  const {
    addressData: {
      address,
      selectedAddress: { saveAddress },
    },
    store: { selectedStore },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!address?.length) {
      GetAllAddress(dispatch);
    }
  }, []);
  const onHandleDropDownCLick = async (e: any) => {
    const data = await DeliveryPriceAndOrderFee(e.zip_code, selectedStore);
    dispatch(
      SETSELECTEDSAVEDADDRESS({
        ...e,
        distance: data?.distance,
        latitude: data?.latitude,
        longitude: data?.longitude,
        price: data?.price,
      })
    );
  };

  return (
    <>
      {address?.length ? (
        <Select
          placeholder="Select your address"
          onChange={onHandleDropDownCLick}
          className="basic-single "
          //           classNamePrefix="select"
          isSearchable={true}
          defaultValue={{ ...saveAddress, label: saveAddress.full_address }}
          options={address?.map((item: any) => ({
            ...item,
            label: item.full_address,
          }))}
          styles={{
            control: (base) => ({
              ...base,
              borderRadius: `13px`,
              fontSize: `14px`,
            }),
            option: (base) => ({
              ...base,
              backgroundColor: `white`,
              color: `black`,
              fontSize: `14px`,
            }),
          }}
        />
      ) : (
        ""
      )}
      <textarea
        className="border border-[#C5C5C5] rounded-[13px] px-4 py-3 text-[.8em] w-full mt-3"
        rows={4}
        placeholder="Instruction for delivery partner"
      ></textarea>
    </>
  );
};

export default SavedAddress;
