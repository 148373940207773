import Checkout from "../pages/checkout/page";
import MyAddress from "../pages/my-address/page";
import MyOrder from "../pages/my-order/page";
import OrderDetails from "../pages/order-details/page";
import OrderSuccess from "../pages/order-success/Order-Success";
import Profile from "../pages/profile/page";
import Register from "../pages/register/page";
import Settings from "../pages/Settings/page";
import ProtectedRoute from "../Route/ProtectedRoute";

const ProtectedRouter = [
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/order-success",
    element: <OrderSuccess />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },

  {
    path: "/my-order",
    element: <MyOrder />,
  },
  {
    path: "/my-address",
    element: <MyAddress />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/order-detail/:id",
    element: <OrderDetails />,
  },
  {
    path: "/register",
    element: <Register />,
  },
];

export default ProtectedRouter?.map((item) => ({
  ...item,
  element: <ProtectedRoute>{item.element}</ProtectedRoute>,
}));
