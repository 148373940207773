import axiosInstance from "../Axios-Config/Axios-Config";

async function GetAllOrderApi() {
  try {
    const {
      data: {
        success,
        order_list: { data },
      },
    } = await axiosInstance.get("recent/order");
    if (success) {
      return { order_list: data };
    } else {
      return { order_list: [] };
    }
  } catch (error: any) {
    return { order_list: [] };
  }
}
export default GetAllOrderApi;
