import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBarSkelton from "../Sidebar/SideBarSkelton";
import { SETSELECTEDCATEGORY } from "../../redux/features/categoryList";
import { useNavigate } from "react-router-dom";
import NoInternet from "../Modal/NoInternet";

const MobileMenu = () => {
  const { store } = useSelector((store: any) => store);
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const goToViolation = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const {
    categoryData: { category, selectedCategory },
    store: { selectedStore, allCategory },
  } : any = useSelector((state: any) => state);

  const onHandleChangeCategory = (slug : string) =>{
    dispatch(SETSELECTEDCATEGORY(slug))
    goToViolation(slug);
    navigate(`?slug=${slug}`);

  }

  return (
    // This map is for main menu at mobile page

    <>
      <div className="bg-white sticky top-0 md:hidden block z-[9]">
      <ul className="flex gap-2 scroll-hidden overflow-x-auto ">
      
        {loading ? (
          <SideBarSkelton />
        ) : (
          allCategory?.map((value : any)=>{
            return (
              <li
              className={`mob-menu-item ${
                value?.slug == selectedCategory ? "active" : ""
              }`}
              onClick={()=>onHandleChangeCategory(value?.slug)}
              key={value?.created_at}
            >
              {/* {value?.slug} */}
              <button type="button" className=" navBtn">
                        {value?.name}
                      </button>
            </li>
            )
          })
        )}
      </ul>

        {/* {store?.allCategory.map((item: any) => (
          <li
            key={item.slug}
            className={`text-base p-2 ${
              item?.slug == selectedCategory ? "active" : ""
            }`}
          >
            {item?.slug}
          </li>
        ))} */}
    </div>
    {!isOnline && <NoInternet isOnline={isOnline} />}
    </>
  );
};

export default MobileMenu;
