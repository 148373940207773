import MyInfoWrapper from "../../Wrapper/MyInfoWrapper";
import MyAccount from "../../components/Profile/MyAccount";

export default function Profile() {
  return (
    <>
      <MyInfoWrapper>
        <MyAccount />
      </MyInfoWrapper>
    </>
  );
}
