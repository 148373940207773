import { Link } from 'react-router-dom'
export default function Logo() {
    return (
        <>
            <Link to="/">
                <img src="/logo.png" width={180} height={37} alt="Logo" />
                {/* <h1 className='logoText'>Foodies Cafe</h1> */}
            </Link>
        </>
    )
}
