import {  object, string } from "yup";

export const passwordInitialValues = {
  old_password: "",
  new_password: "",
};

export const passwordSchemaInitial = object().shape({
  old_password: string().required("Old Password Required"),
  new_password : string().required("New Password Required")
});
