const CustomerServiceCharge = (price: number, store: any) => {
  return store?.domain_setting?.is_customer == 1
    ? (price * parseFloat(store?.domain_setting?.service_charge)) / 100 <
      parseFloat(store?.domain_setting?.min_service_charge)
      ? store?.domain_setting?.min_service_charge
      : (Number(price) * parseFloat(store?.domain_setting?.service_charge)) /
          100 >
        parseFloat(store?.domain_setting?.max_service_charge)
      ? store?.domain_setting?.max_service_charge
      : (price * parseFloat(store?.domain_setting?.service_charge)) / 100
    : 0;
};

export default CustomerServiceCharge;
