import { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import GetAllStore from "../../api/GetAllStore";
import Header from "../../components/Header/Header";
import KitchenCard from "../../components/Home/KitchenCard";
import StoreSkelton from "../../components/Home/StoreSkelton";
import MenuCard from "../../components/Menu/MenuCard";
import MobileMenu from "../../components/Menu/MobileMenu";
import MobileMenuPopup from "../../components/Menu/MobileMenuPopup";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import CartItemDetail from "../../components/RightSideBar/CartItemDetail/CartItemDetail";
import EmptyCart from "../../components/RightSideBar/EmptyCart";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import HamburgerMenu from "../../components/Sidebar/HamburgerMenu";
import Sidebar from "../../components/Sidebar/Sidebar";
import Suspence from "../../components/Suspence/Suspence";
import { SETSELECTEDCATEGORY } from "../../redux/features/categoryList";
import {
  SETALLCATEGORY,
  SETALLSTORE,
  SETDOMAINSETTING,
  SETSELECTEDSTORE,
  SETSTORETYPE,
} from "../../redux/features/store";
import { debouceFunction } from "../../utils/Debouncing/Debouncing";
import IndivisualQuantity from "../../utils/IndivisualQuantity";

export default function Menu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuOpen = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };
  const [sidebar, setSidebar] = useState(false);
  const handleSidebar = () => {
    sidebar ? setSidebar(false) : setSidebar(true);
  };
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const {
    customizedData,
    cartData: { itemCount, items },
    store: { allCategory, selectedStore, allStore },
  }: any = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!allStore.length) {
      setLoading(true);
    }
    navigator.geolocation.getCurrentPosition(success, error);
    sessionStorage.setItem("store_type", "3");
    dispatch(SETSTORETYPE(3));
    sessionStorage.removeItem("store");
    sessionStorage.removeItem("items");
    sessionStorage.removeItem("backCheckout");
    sessionStorage.removeItem("domain_setting");
    function success(positions: any) {
      const search = {
        search_item: "",
        longitude: positions.coords.longitude,
        latitude: positions.coords.latitude,
        store_type: 3,
      };
      if (!allStore?.length) {
        setLoading(true);
        getData({ lat: search.latitude, long: search.longitude });
      } else if (!sessionStorage.getItem("longitude")) {
        setLoading(true);
        getData({ lat: search.latitude, long: search.longitude });
      } else {
        setLoading(false);
      }
      sessionStorage.setItem("longitude", positions.coords.longitude);
      sessionStorage.setItem("latitude", positions.coords.latitude);
    }
    function error(err: any) {
      sessionStorage.removeItem("longitude");
      sessionStorage.removeItem("latitude");
      sessionStorage.removeItem("store");
      sessionStorage.removeItem("items");
      sessionStorage.removeItem("backCheckout");
      sessionStorage.removeItem("domain_setting");
      const search = {
        search_item: "",
        longitude: "",
        latitude: "",
        store_type: 3,
      };
      if (!allStore?.length) {
        setLoading(true);
        getData({ lat: search.latitude, long: search.longitude });
      } else {
        setLoading(false);
      }
    }
  }, []);

  const getData = async ({ lat, long }: { lat: any; long: any }) => {
    const data = await GetAllStore({ latitude: lat, longitude: long });
    if (data.all_Store?.length) {
      let selectedStore = data.all_Store[0];
      sessionStorage.setItem(
        "country_currency",
        selectedStore.store_data?.country_currency
      );
      sessionStorage.setItem(
        "store-id",
        selectedStore.store_data?.store_id || ""
      );
      dispatch(SETSELECTEDSTORE(selectedStore.store_data));
      dispatch(SETALLCATEGORY(selectedStore?.Category_data));
      dispatch(SETSELECTEDCATEGORY(selectedStore?.Category_data?.[0]?.slug));

      dispatch(SETALLSTORE(data.all_Store));
      navigate(`?slug=${selectedStore?.Category_data?.[0]?.slug}`);
    }
    if (data?.domain_details) {
      dispatch(SETDOMAINSETTING(data?.domain_details));
    }
    setLoading(false);
  };

  if (loading) {
    return <Suspence />;
  }

  const setInView = (inView: any, entry: any) => {
    if (inView) {
      debouceFunction(entry.target.getAttribute("id"), dispatch);
    }
  };

  return (
    <>
      <div className="topheader">
        <div className="pageHeader">
          <Header handleSidebar={handleSidebar} />
        </div>
      </div>

      <div
        className={`${
          sidebar ? "left-0" : "left-[-30rem]"
        } md:hidden block absolute h-screen w-screen transition-all z-[51] bg-white`}
      >
        <HamburgerMenu handleSidebar={handleSidebar} />
      </div>

      <div className="flex pt-16 overflow-hidden relative">
        <div className="sideBarWrapper md:block hidden">
          <Sidebar />
        </div>
        <div className="h-[calc(100vh-4rem)] overflow-y-auto scroll-hidden bg-[#F3F5FA] w-full">
          <div className="store-banner md:relative static">
            <div className="md:absolute static md:top-[8rem] md:left-2/4 md:translate-x-[-50%] md:translate-y-[-50%]">
              <KitchenCard
                kitchenName={selectedStore?.store_name}
                kitchenDistance={selectedStore?.distance}
                kitchenAddress={selectedStore?.address}
                kitchenStatus={2}
                store={selectedStore}
                kitchenDescription={selectedStore?.description}
                className="menu"
              />
            </div>
          </div>
          <MobileMenu />
          <div className="centerSiteContent md:pt-16 pt-0">
            {loading ? (
              <StoreSkelton />
            ) : (
              allCategory?.map((category: any, index: number) => {
                return (
                  <InView
                    onChange={setInView}
                    threshold={0.3}
                    // triggerOnce={true}
                    key={index}
                  >
                    {({ ref: inViewRef }) => {
                      return (
                        <div id={category?.slug} ref={inViewRef}>
                          {category?.sub_category_data?.length > 0
                            ? category?.sub_category_data?.map((data: any) => {
                                return data?.item_data?.length > 0 ? (
                                  <div
                                    key={data?.menu_id}
                                    className="secWrapper px-3"
                                  >
                                    <SectionTitle sectionTitle={data?.name} />
                                    <div
                                      className={`${
                                        itemCount > 0
                                          ? " md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 "
                                          : " md:grid-cols-3  lg:grid-cols-4 xl:grid-cols-4"
                                      } cardWrapper grid grid-cols-1 sm:grid-cols-2 gap-3`}
                                    >
                                      {data?.item_data?.length == 0 ? (
                                        <NoDataFound content="No Data Found" />
                                      ) : (
                                        data?.item_data?.map((item: any) => {
                                          const exist = IndivisualQuantity(
                                            items,
                                            item.item_id
                                          );
                                          return (
                                            <MenuCard
                                              key={item?.item_id}
                                              classNameType={
                                                item?.item_type == 0
                                                  ? "Veg"
                                                  : "nonVeg"
                                              }
                                              item={item}
                                              quantity={exist || 0}
                                              menu_id={data.menu_id}
                                              taxdata={data}
                                            />
                                          );
                                        })
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                );
                              })
                            : ""}
                        </div>
                      );
                    }}
                  </InView>
                );
              })
            )}
          </div>
        </div>
        {itemCount > 0 && (
          <div
            className={
              Object.keys(customizedData).length == 0
                ? "rightSideBarWrapper"
                : "rightSideBarWrapper show"
            }
          >
            {itemCount > 0 && <CartItemDetail />}
            {itemCount == 0 && <EmptyCart />}
          </div>
        )}
      </div>

      {itemCount > 0 && (
        <Link
          to="/checkout"
          className="bgBlue z-[40] text-white py-3 text-center fixed w-full bottom-0 md:hidden block"
        >
          <p>
            {itemCount} {itemCount > 1 ? "items" : "item"} added
            <i className="fa fa-arrow-circle-right ml-2"></i>
          </p>
        </Link>
      )}
      <button
        onClick={handleMenuOpen}
        className="md:hidden absolute bottom-16 right-5 bg-white border-2 border-[#34558B] rounded-[12px] h-[4.5rem] w-[4.5rem] flex flex-col justify-center items-center"
      >
        <img src="/images/menu-icon.svg" />
        <p className="font-bold textMd">Menu</p>
      </button>
      {menuOpen && (
        <>
          <MobileMenuPopup menuOpen={menuOpen} onCloseModal={handleMenuOpen} />
          <div className="bg-black opacity-15 h-screen absolute top-0 w-screen z-[49]"></div>
        </>
      )}
    </>
  );
}
