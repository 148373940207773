import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginView from "../../components/Login/LoginView";
import MainWrapper from "../../Wrapper/MainWrapper";
export default function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("yop-token")) {
      navigate("/");
    }
  }, [navigate]);
  return (
    <>
      <MainWrapper>
        <LoginView />
      </MainWrapper>
    </>
  );
}
