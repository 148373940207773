import moment from "moment";

const FilterCurrentTimeInStore = (data: any, days: string) => {
  if (days == moment().format("dddd")) {
    let today = new Date();
    let array: any = [];
    let time = today.getHours();

    let minutes = today.getMinutes();

    data[days]?.map((item: any) => {
      if (
        item.includes("AM") &&
        !item.includes("12") &&
        time <= Number(item.split(":")[0]) &&
        (time - Number(item.split(":")[0]) == 0
          ? minutes <= Number(item.split(":")[1].split(" ")[0])
          : true)
      ) {
        array.push(item);
      } else if (
        item.includes("PM") &&
        !item.includes("12") &&
        time <= Number(item.split(":")[0]) + 12 &&
        (time - (Number(item.split(":")[0]) + 12) == 0
          ? minutes <= Number(item.split(":")[1].split(" ")[0])
          : true)
      ) {
        array.push(item);
      } else if (
        item.includes("12") &&
        item.includes("AM") &&
        time == 0 &&
        (time == 0 ? minutes <= Number(item.split(":")[1].split(" ")[0]) : true)
      ) {
        array.push(item);
      } else if (
        item.includes("12") &&
        item.includes("PM") &&
        time <= 12 &&
        (time == 12
          ? minutes <= Number(item.split(":")[1].split(" ")[0])
          : true)
      ) {
        array.push(item);
      }
    });

    return array;
  } else {
    return data[days];
  }
};

export default FilterCurrentTimeInStore;
