import "react-tabs/style/react-tabs.css";
import CurrentOrder from "../../components/MyOrder/CurrentOrder/CurrentOrder";
import MyInfoWrapper from "../../Wrapper/MyInfoWrapper";

export default function MyOrder() {
  return (
    <MyInfoWrapper>
      {" "}
      <CurrentOrder />
    </MyInfoWrapper>
  );
}
