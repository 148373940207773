interface SubTotal {
  stepOptionId: any;
  selectAddOn: any;
  categoryId: any;
  selectedVariation: any;
  customizedData: any;
}
const GetCustomizedSubTotal = ({
  stepOptionId,
  selectAddOn,
  categoryId,
  selectedVariation,
  customizedData,
}: SubTotal) => {
  let price = 0;

  // option price ......
  stepOptionId?.map((item: any) => {
    price += Number(item.web_price || 0);
  });

  selectAddOn?.map((item: any) => {
    price += Number(item.web_offered_price || 0);
  });
  categoryId?.map((item: any) => {
    price += Number(item?.mapNewItem?.web_price || 0);
  });
  // variation  ......
  price += selectedVariation?.web_price
    ? Number(selectedVariation?.web_price)
    : customizedData?.web_price;
  return price;
};
export default GetCustomizedSubTotal;
