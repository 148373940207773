import axiosInstance from "../Axios-Config/Axios-Config";
import { STOREPICKUPDATE, STOREPICKUPTIME } from "../redux/features/CartData";

export const GetPickupDate = async (dispatch: Function) => {
  try {
    const {
      data: { success, available_days },
    } = await axiosInstance.get("available/day/by/storeId");
    if (success) {
      dispatch(STOREPICKUPDATE(available_days));
    } else {
      dispatch(STOREPICKUPDATE([]));
    }
  } catch (error: any) {
    dispatch(STOREPICKUPDATE([]));
  }
};

export const GetPickupTime = async (dispatch: Function, day: string) => {
  try {
    const {
      data: { success, slots },
    } = await axiosInstance.post("available/slot/by/currentDay", {
      current_day: day,
    });
    if (success) {
      dispatch(STOREPICKUPTIME(day, slots));
    } else {
      dispatch(STOREPICKUPTIME(day, []));
    }
  } catch (error: any) {
    dispatch(STOREPICKUPTIME(day, []));
  }
};
