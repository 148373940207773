import axiosInstance from "../Axios-Config/Axios-Config";
const GetAllStore = async ({
  longitude,
  latitude,
}: {
  longitude: any;
  latitude: any;
}) => {
  try {
    const { data }: any = await axiosInstance.get(
      `store/all/data/list?longitude=${longitude}&lattitude=${latitude}`
    );
    return {
      all_Store: data?.all_Store,
      domain_details: data?.domain_details,
    };
  } catch (error: any) {
    return {
      all_Store: [],
      domain_details: {},
    };
  }
};
export default GetAllStore;
