import Suspence from '../../components/Suspence/Suspence'

export default function Suspense() {
  return (
    <>
        <div className='pageWrapper'>
            <div className='siteWidth'>
                <Suspence />
            </div>
        </div>
    </>
  )
}
