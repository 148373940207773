import ConvertUkTimeZone from "./convert-time-zone";

const MarketCloseOpen = (data: any) => {
  if (!data) {
    return {};
  } else if (data.length > 0) {
    const currentDay = data.find(
      (item: any) =>
        item?.available_days === ConvertUkTimeZone().format("dddd") &&
        item?.status === "open"
    );

   

    if (currentDay?.availability?.length > 0) {
      let startMinutes = 0;
      let startHour = 0;
      let endHour = 0;
      let endMinutes = 0;

      for (let i = 0; i < currentDay.availability.length; i++) {
        // Determine startHour and startMinutes
        if (currentDay.availability[i]?.from_time.split(" ")[1] === "AM" && currentDay.availability[i]?.from_time.split(":")[0] === "12") {
          startHour = 0;
          startMinutes = currentDay.availability[i]?.from_time.split(":")[1]?.split(" ")[0];
        } else if (currentDay.availability[i]?.from_time.split(" ")[1] === "PM" && currentDay.availability[i]?.from_time.split(":")[0] !== "12") {
          startHour = Number(currentDay.availability[i]?.from_time.split(":")[0]) + 12;
          startMinutes = currentDay.availability[i]?.from_time.split(":")[1]?.split(" ")[0];
        } else {
          startHour = currentDay.availability[i]?.from_time.split(":")[0];
          startMinutes = currentDay.availability[i]?.from_time.split(":")[1]?.split(" ")[1];
        }

        // Determine endHour and endMinutes
        if (currentDay.availability[i]?.to_time.split(" ")[1] === "AM" && currentDay.availability[i]?.to_time.split(":")[0] === "12") {
          endHour = 0;
          endMinutes = currentDay.availability[i]?.to_time.split(":")[1]?.split(" ")[0];
        } else if (currentDay.availability[i]?.to_time.split(" ")[1] === "PM" && currentDay.availability[i]?.to_time.split(":")[0] !== "12") {
          endHour = Number(currentDay.availability[i]?.to_time.split(":")[0]) + 12;
          endMinutes = currentDay.availability[i]?.to_time.split(":")[1]?.split(" ")[0];
        } else {
          endHour = currentDay.availability[i]?.to_time.split(":")[0];
          endMinutes = currentDay.availability[i]?.to_time.split(":")[1]?.split(" ")[1];
        }

        // Check if current time is within the availability range
        if (
          ConvertUkTimeZone() <=
            ConvertUkTimeZone().set({
              hour: endHour,
              minute: endMinutes,
              second: 0,
            }) &&
          ConvertUkTimeZone() >=
            ConvertUkTimeZone().set({
              hour: startHour,
              minute: startMinutes,
              second: 0,
            })
        ) {
          if (currentDay?.availability[i]) {
            let getSeconds = currentDay.availability[i].to_time
              ?.split(":")[2]
              ?.split(" ");
        
        
            const clone = { ...currentDay.availability[i] };
        
            if (getSeconds?.length > 1) {
              clone.to_time = `12:00 AM`;
            }
            return clone;
          } else {
            return {};
          }
        }
      }
      return {};
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export default MarketCloseOpen;

