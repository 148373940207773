import {
  SETCARTCOUNT,
  SETCARTDATA,
  SETCARTTOTALPRICE,
} from "../redux/features/CartData";
import { GetTotalPrice } from "./GetTotalPrice";
import { TaxCalcualate } from "./TaxCalculate";
import checkItemType from "./checkItemType";

const DecrementItemData = (
  data: any,
  cardData: any,
  disPatch: Function,
  itemCount: number,
  taxdata?: any
) => {
  const index = cardData.indexOf(data);
  let TaxData = TaxCalcualate(
    taxdata,
    Number(data.sub_total) - Number(data.price),
    Number(data.quantity) - 1
  );
  if (data.quantity == 1) {
    cardData.splice(index, 1);
  } else if (checkItemType(data) != 3) {
    cardData.splice(index, 1);
  } else {
    data["quantity"] = Number(data.quantity) - 1;
    data["sub_total"] = Number(data.sub_total) - Number(data.price);
    data["tax_rate_list"] = TaxData.taxArray;

    data["total_price"] =
      Number(data.total_price) -
      Number(data.price) -
      TaxData.fixedTax -
      (Number(data.price) * TaxData.percentageTax) / 100;
    cardData.splice(index, 1, data);
  }

  disPatch(SETCARTDATA(cardData));
  disPatch(SETCARTTOTALPRICE(GetTotalPrice(cardData)));

  if (cardData?.length) {
    sessionStorage.setItem("items", JSON.stringify(cardData));
    disPatch(SETCARTCOUNT(itemCount - 1));
  } else {
    sessionStorage.removeItem("items");
    disPatch(SETCARTCOUNT(0));
  }
};

export default DecrementItemData;
