import axiosInstance from "../Axios-Config/Axios-Config";
const GetStore = async (param: any) => {
  try {
    const { data }: any = await axiosInstance.get(
      `store/list?search_item=${param?.search_item}&longitude=${param?.longitude}&lattitude=${param.latitude}&pickup_status=undefined&page=0&store_type=${param?.store_type}`
    );
    return {
      store: data?.store_data,
      domain_setting: data?.domain_setting,
      loading: false,
    };
  } catch (error: any) {
    return { store: {}, domain_setting: {}, loading: false };
  }
};
export default GetStore;
