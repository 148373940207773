import RegisterView from "../../components/Register/RegisterView";
import MainWrapper from "../../Wrapper/MainWrapper";

export default function Register() {
  return (
    <>
      <MainWrapper>
        <RegisterView />
      </MainWrapper>
    </>
  );
}
