import ForgotPassword from "../pages/forgot-password/page";
import Login from "../pages/login/page";
import Menu from "../pages/menu/page";
import Otp from "../pages/otp/page";
import PageNotFound from "../pages/page-not-found/PageNotFound";

const WithoutProtectdRouter = [
  {
    path: "/",
    element: <Menu />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/menu",
    element: <Menu />,
  },
  {
    path: "/verify-otp",
    element: <Otp />,
  },
];

export default WithoutProtectdRouter;
