export function CheckDeliveryAvailable(store: any) {
  return store?.store_delivery_radius?.sort(
    (a: any, b: any) => b?.to - a?.to
  )?.[0]?.to;
}
export function CheckDeliveryPrice(distance: number, store: any) {
  return (
    store?.store_delivery_radius?.find(
      (item: any) => item.from <= distance && item.to >= distance
    )?.price ?? 0
  );
}
