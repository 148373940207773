import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Axios-Config/Axios-Config";
import InputNumber from "../../components/FormFields/InputNumber";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import {
  passwordInitialValues,
  passwordSchemaInitial,
} from "./SettingValidation";
import Header from "../../components/Header/Header";

function Settings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getFieldState,
  } = useForm({
    resolver: yupResolver(passwordSchemaInitial),
    defaultValues: passwordInitialValues,
  });

  const onSubmit = async (data: any) => {
    try {
      const { response }: { response: any } = await axiosInstance.post(
        "/change-password",
        data
      );
      if (response) {
        navigate("/login");
      } else {
        console.error("Password change failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <>
      <Header />
      <div className="bgGray pt-32 px-4">
        <div className="flex  gap-4 min-h-[calc(100vh-8rem)]">
          <div className="md:w-1/5 w-full bg-white rounded-md hidden md:block">
            <ProfileSidebar />
          </div>
          <div className="md:w-4/5 card card--block cardBorder">
            <div>
              <h5 className="font-medium text-black dark:text-white">
                <button
                  className="btn btn-primary mb-2 ml-auto flex justify-center rounded-md bgBlue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm "
                  type="button"
                >
                  {t("Settings.changePassword")}
                </button>
              </h5>
            </div>

            <div className="profileDetail">
              <div className="addressDetail">
                <div className="formGroup">
                  <div className="formItem">
                    <label htmlFor="old_password">
                      {t("Settings.oldPassword")}
                    </label>

                    <InputNumber
                      placeholder={t("Settings.oldPassword")}
                      name="mobileNumber"
                      register={register}
                    />
                    <input type="text" id="old_password" name="old_pasword" />
                  </div>
                  <div className="formItem">
                    <label htmlFor="old_password">
                      {t("Settings.oldPassword")}
                    </label>

                    <InputNumber
                      placeholder={t("Settings.oldPassword")}
                      name="mobileNumber"
                      register={register}
                    />
                    <input type="text" id="old_password" name="old_pasword" />
                  </div>
                  <div className="formItem">
                    <label htmlFor="old_password">
                      {t("Settings.newPassword")}
                    </label>

                    <InputNumber
                      placeholder={t("Settings.newPassword")}
                      name="mobileNumber"
                      register={register}
                    />
                    <input type="text" id="new_password" name="new_pasword" />
                  </div>

                  <div className="logoutSection">
                    <button
                      className="btn btn-danger mb-2 ml-auto flex justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                      type="button"
                      onClick={onSubmit}
                    >
                      {t("Settings.changePassword")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Settings;
