export default function InputMobileNumber({
  placeholder,
  register,
  name,
  value,
  readOnly,
  disabled,
}: {
  placeholder: string;
  register: Function;
  name: string;
  value?: number | string;
  readOnly?: boolean;
  disabled? : boolean;
}) {
  return (
    <>
      <input
        type="number"
        placeholder={placeholder}
        name={name}
        {...register(name)}
        value={value}
        readOnly={readOnly}
        disabled = {disabled}
      />
    </>
  );
}
