import Modal from "react-modal";

const NoInternet = ({ isOnline }: { isOnline: Boolean }) => {
    return (
        <Modal
            ariaHideApp={false}
            className="confirmation"
            isOpen={!isOnline}
            contentLabel=""
        >
            <div className="modalHeader center">
                <div className="headerTitle">No Internet</div>

            </div>

            <div className="modalBody">
                <div className="card card--block mb-6">
                    <button

                        className="btn tryAgain"
                    //   onClick={handleReload}
                    >
                        Try Again
                    </button>
                </div>
            </div>
        </Modal>
    )
}
export default NoInternet
