import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CategoryApi from "../../api/categoryApi";
import {
  SETCATEGORYLIST,
  SETSELECTEDCATEGORY,
} from "../../redux/features/categoryList";
import NoInternet from "../Modal/NoInternet";
import SideBarSkelton from "./SideBarSkelton";

export default function Sidebar() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const {
    categoryData: { category,   selectedCategory },
    store: { selectedStore, allCategory },
  }: any = useSelector((state: any) => state);
  // useEffect(() => {
  //   if (category?.length > 0) {
  //     navigate(`/menu?slug=${selectedCategory ?? category[0]?.slug}`);
  //     setLoading(false);
  //   } else if (Object.keys(selectedStore).length > 0) {
  //     getCategoryList();
  //   } else {
  //     navigate("/");
  //   }
  // }, [navigator.onLine]);
  const getCategoryList = async () => {
    const { category, loading } = await CategoryApi();
    dispatch(SETCATEGORYLIST(category));
    dispatch(SETSELECTEDCATEGORY(category[0]?.slug));
    navigate(`/menu?slug=${category[0]?.slug}`);
    setLoading(loading);
  };
  const handleChangeCategory = (slug: string) => {
    dispatch(SETSELECTEDCATEGORY(slug));
    goToViolation(slug);
    navigate(`?slug=${slug}`);
  };
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);
  const goToViolation = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };
  useEffect(() => {
    navigate(`?slug=${selectedCategory}`);
  }, [selectedCategory]);
  return (
    <>
      <div className="pageSideBar">
        <div className="sideBarLogo">
          <div className="menuList">
            <ul>
              <li className="menuTitle ">Menu</li>

              {loading ? (
                <SideBarSkelton />
              ) : (
                allCategory?.map((value: any) => {
                  return (
                    <li
                      className={
                        value?.slug == selectedCategory ? "active" : ""
                      }
                      onClick={() => handleChangeCategory(value?.slug)}
                      key={value?.created_at}
                    >
                      <button type="button" className=" navBtn">
                        {value?.name}
                      </button>
                    </li>
                  );
                })
              )}
            </ul>
            {/* <div className='sideBarFooter'>
                            <div className='footerButton'>

                                <Link to="/" className='btn'>

                                    <img src="../logo.png" alt="Logo" />
                                </Link>
                            </div>
                            <ul>
                                <li>
                                    <Link to="#" className='btn'>
                                        <img src="../googleplay.svg" alt="Button" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className='btn'>
                                        <img src="../googleplay.svg" alt="Button" />
                                    </Link>
                                </li>
                            </ul>
                        </div> */}
          </div>
        </div>
      </div>
      {!isOnline && <NoInternet isOnline={isOnline} />}
    </>
  );
}
