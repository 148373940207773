const checkItemType = (item: any) => {
  if (item.customize_status == 1) {
    return 1;
  } else if (item.customize_status != 1 && item.variation_status == 1) {
    return 2;
  } else {
    return 3;
  }
};
export default checkItemType;
