import {
  SETCARTCOUNT,
  SETCARTDATA,
  SETCARTTOTALPRICE,
} from "../redux/features/CartData";
import { GetTotalPrice } from "./GetTotalPrice";

const duplicateItemData = async (
  data: any,
  allItem: any,
  disPatch: Function,
  itemCount: number
) => {
  const newData = { ...data };
  newData["uniqueIndex"] = allItem[allItem.length - 1].uniqueIndex + 1;
  allItem.push(newData);
  disPatch(SETCARTDATA(allItem));
  disPatch(SETCARTCOUNT(itemCount + 1));
  disPatch(SETCARTTOTALPRICE(GetTotalPrice(allItem)));
  sessionStorage.setItem("items", JSON.stringify(allItem));
};
export default duplicateItemData;
