import ConfettiExplosion from "react-confetti-explosion";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const OrderSuccess = () => {
  const {t} = useTranslation()
  const [isExploding, setIsExploding] = useState(false);

  useEffect(() => {
    setIsExploding(true);
  }, []);
  return (
    <div className="md:flex justify-center h-screen items-center bg-[#F3F5FA]">
      <div className="card rounded-xl p-4 md:w-2/5 shadow-lg bg-white h-screen md:h-auto">
        <div className="flex items-center flex-col">
          {isExploding && (
            <ConfettiExplosion particleCount={250} particleSize={10} />
          )}
          <img src="/images/order-success.png" />
          <img src="/images/Checkmark.svg" className="mt-2"/>
          <p className="text-base font-semibold mt-2 ">
           Hey John
          </p>
          <p className="text-base font-bold mt-2 ">
           {t("OrderSuccess.orderConfirmed")}
          </p>
          <p className="text-base font-semibold mt-2 ">
          Expected delivery time 15 mins
          </p>
          <div className="p-2 fixed md:static bottom-0 w-full">
          <Link to="/my-order" replace={true} className="block w-full">
            
            <button className="btn-secondary mt-3 w-full">
              {t("OrderSuccess.viewOrder")}
            </button>
          </Link>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
