import { useTranslation } from "react-i18next";
import PoweredBy from "../PoweredBy/PoweredBy";

export default function PaymentMethod({
  loader,
  sub_total,
  tax_amount,
  delivery_charge,
  grand_total,
  service_charge,
  tax,
}: {
  loader: boolean;
  sub_total: number;
  tax_amount: number;
  delivery_charge: number;
  grand_total: number;
  service_charge: number;
  tax: any;
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="paymentMethod">
        {/* <div className="Payment">
          {loader ? (
            <LoaderButton />
          ) : (
            <PaymentButton
              buttonText={t("Checkout.continueToPayment")}
              btnClass="btnBlue"
              paymentText={ParseFloat(grand_total)}
            />
          )}
        </div> */}
        {/* <div className="card card--block cardBorder mt-4">
          <h4 className="fs-18 font-bold">{t("Checkout.orderTotal")}</h4>
          <div className="orderTotal mb-4">
            <div className="orderRow flexBox justify-between mt-4">
              <h4 className="fs-18 font-semibold">{t("Checkout.subTotal")}</h4>
              <h4 className="fs-18 font-semibold">{ParseFloat(sub_total)}</h4>
            </div>
            {tax?.map((item: any) => {
              return (
                <div className="orderRow flexBox justify-between mt-4">
                  <h4 className="fs-18 font-semibold">{item?.tax_name}</h4>
                  <h4 className="fs-18 font-semibold">
                    {ParseFloat(item?.actual_tax_amount)}
                  </h4>
                </div>
              );
            })}

            {service_charge > 0 ? (
              <div className="orderRow flexBox justify-between mt-4">
                <h4 className="fs-18 font-semibold">
                  {t("Checkout.serviceFee")}
                </h4>
                <h4 className="fs-18 font-semibold">
                  {ParseFloat(service_charge)}
                </h4>
              </div>
            ) : (
              ""
            )}
            {delivery_charge ? (
              <div className="orderRow flexBox justify-between mt-4">
                <h4 className="fs-18 font-semibold">
                  {t("Checkout.deliveryFee")}
                </h4>
                <h4 className="fs-18 font-semibold">
                  {ParseFloat(delivery_charge)}
                </h4>
              </div>
            ) : (
              ""
            )}
            <div className="orderRow flexBox justify-between mt-4">
              <h4 className="fs-18 font-semibold">
                {t("Checkout.orderTotal")}
              </h4>
              <h4 className="fs-18 font-semibold">{ParseFloat(grand_total)}</h4>
            </div>
          </div>
        </div> */}
        <div className="fixFooter mt-8 singleRow md:hidden">
          <PoweredBy />
        </div>
      </div>
      {/* <Modal
                ariaHideApp={false}
                className="confirmation cartItemPopup"
                isOpen={paymentModal}
                contentLabel=""
            >
                <div className="modalHeader noBg">
                    <div className="headerTitle">jhfjhf</div>
                    <button className="close" onClick={() => setPaymentModal(!paymentModal)}>
                        <svg
                            id="Icons_Alerts_alarm"
                            data-name="Icons/Alerts/alarm"
                            xmlns="http://www.w3.org/2000/svg"
                            width="21.037"
                            height="21.04"
                            viewBox="0 0 21.037 21.04"
                        >
                            <path
                                id="close"
                                d="M2.4.412A1.405,1.405,0,1,0,.412,2.4l8.117,8.117L.412,18.634A1.405,1.405,0,1,0,2.4,20.622l8.117-8.112,8.117,8.117a1.405,1.405,0,1,0,1.988-1.988L12.51,10.517,20.627,2.4A1.407,1.407,0,1,0,18.634.412L10.516,8.529Z"
                                transform="translate(0 0.001)"
                                fill="#171717"
                            />
                        </svg>
                    </button>
                </div>
                <div className="modalBody">
                    <div className="card card--block mb-6">
                        <div className="grid--2">
                            <div className="colLeftBlock imgBlock">
                            </div>
                            <div className="colRightBlock">
                                <div className="card--img--desc">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal> */}
    </>
  );
}
