export const SET_VARIATION_DATA = "SET_VARIATION_DATA";
export const CLEAR_VARIATION_DATA = "CLEAR_VARIATION_DATA";

export const SETVARIATIONDATA = (data: any) => {
  return {
    type: SET_VARIATION_DATA,
    payload: data,
  };
};
export const CLEARVARIATIONDATA = (data: any) => {
  return {
    type: CLEAR_VARIATION_DATA,
    payload: data,
  };
};

const initialState: Object = {};

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_VARIATION_DATA:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_VARIATION_DATA:
      return {
        ...payload,
      };

    default:
      return state;
  }
};

export default reducer;
