import "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLayoutEffect } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import CreatePaymentIntentApi from "../../api/create-payment-intent";
import StripePayment from "../Stripe-Payment/Stripe-Payment";

interface customeType {
  openPayment: boolean;
  onClosePaymentModal: any;
}
const PaymentModal = ({ openPayment, onClosePaymentModal }: customeType) => {
  const stripe = loadStripe(process.env.REACT_APP_PUBLIC_API_KEY || "", {
    stripeAccount: process.env.REACT_APP_CONNECTED_API_KEY,
  });
  useLayoutEffect(() => {
    getPaymentElementId();
  }, []);
  function getPaymentElementId() {
    CreatePaymentIntentApi({
      totalPrice: 200,
      commission: 2,
      email: "nitesh",
      first_name: "nitesh",
      phone: "905949999",
      user_id: 2,
    });
  }

  return (
    <Modal
      open={openPayment}
      onClose={onClosePaymentModal}
      center
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
        closeIcon: "modal-close",
      }}
    >
      <StripePayment />
    </Modal>
  );
};
export default PaymentModal;
