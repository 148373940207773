import axiosInstance from "../Axios-Config/Axios-Config";
import TrimAndCapital from "../utils/TrimAndCapital";

export async function GetPostCodeData(postcode: string) {
  try {
    const { data }: { data: any } = await axiosInstance.get(
      `search/postcode/${TrimAndCapital(postcode)}`
    );

    if (data.success) {
      return data;
    } else {
      return data;
    }
  } catch (error: any) {
    return error;
  }
}
