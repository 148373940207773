export const SET_LOADER = "SET_LOADER";

export const SETLOADER = (data: any) => {
  return {
    type: SET_LOADER,
    payload: data,
  };
};

const initialState: Object = { loader: false };

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADER:
      return {
        ...state,
        loader: payload,
      };

    default:
      return state;
  }
};

export default reducer;
