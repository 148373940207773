export const SET_STORE_DELIVERY = "SET_STORE_DELIVERY";
export const SET_STORE_COLLECTION = "SET_STORE_COLLECTION";
export const SET_SELECTED_STORE = "SET_SELECTED_STORE";
export const SET_DOMAIN_SETTING = "SET_DOMAIN_SETTING";
export const SET_ALL_STORE = "SET_ALL_STORE";
export const SET_ALL_CATEGORY = "SET_ALL_CATEGORY";
export const SET_STORE_TYPE = "SET_STORE_TYPE";

export const SETSTOREDELIVERYDATA = (data: any) => {
  return {
    type: SET_STORE_DELIVERY,
    payload: data,
  };
};
export const SETSTORETYPE = (data: any) => {
  return {
    type: SET_STORE_TYPE,
    payload: data,
  };
};
export const SETSTORECOLLECTIONDATA = (data: any) => {
  return {
    type: SET_STORE_COLLECTION,
    payload: data,
  };
};
export const SETSELECTEDSTORE = (data: any) => {
  return {
    type: SET_SELECTED_STORE,
    payload: data,
  };
};
export const SETDOMAINSETTING = (data: any) => {
  return {
    type: SET_DOMAIN_SETTING,
    payload: data,
  };
};
export const SETALLSTORE = (data: any) => {
  return {
    type: SET_ALL_STORE,
    payload: data,
  };
};
export const SETALLCATEGORY = (data: any) => {
  return {
    type: SET_ALL_CATEGORY,
    payload: data,
  };
};

const initialState: Object = {
  collection: [],
  delivery: [],
  allStore: [],
  allCategory: [],
  selectedStore: {},
  domain_setting: {},
  selectedType: sessionStorage.getItem("store_type") || "3",
};

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_STORE_DELIVERY:
      return {
        ...state,
        delivery: payload,
      };
    case SET_STORE_COLLECTION:
      return {
        ...state,
        collection: payload,
      };
    case SET_SELECTED_STORE:
      return {
        ...state,
        selectedStore: payload,
      };
    case SET_DOMAIN_SETTING:
      return {
        ...state,
        domain_setting: payload,
      };
    case SET_ALL_STORE:
      return {
        ...state,
        allStore: payload,
      };
    case SET_ALL_CATEGORY:
      return {
        ...state,
        allCategory: payload,
      };
    case SET_STORE_TYPE:
      return {
        ...state,
        selectedType: payload,
      };
    default:
      return state;
  }
};

export default reducer;
