import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import ProfileSidebar from "../components/ProfileSidebar/ProfileSidebar";
import HamburgerMenu from "../components/Sidebar/HamburgerMenu";

const BackButton = ({ to }: { to: string }) => (
  <Link to={to}>
    <img src="/images/back-icon.svg" alt="Back" />
  </Link>
);

const MyInfoWrapper = ({ children }: { children: any }) => {
  const [sidebar, setSidebar] = useState(false);
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };
  const location = useLocation();

  // Get the current path and its corresponding label
  const getPageTitle = () => {
    switch (location.pathname) {
      case "/profile":
        return "Profile Details";
      case "/my-order":
        return "Orders";
      case "/order-detail":
        return "Orders";
      case "/my-address":
        return "My Address";
      default:
        return "";
    }
  };

  return (
    <>
      {/* Mobile: Show BackButton and path name */}
      <div className="topheader md:hidden block">
        <div className="pageHeader flex gap-2 items-center font-semibold">
          {location.pathname !== "/" && (
            <>
              <BackButton to="/menu" />
              {getPageTitle()}
            </>
          )}
        </div>
      </div>

      {/* Desktop: Show Header */}
      <div className="topheader hidden md:block">
        <div className="pageHeader">
          <Header handleSidebar={handleSidebar} />
        </div>
      </div>

      {/* Sidebar for mobile */}
      <div
        className={`${
          sidebar ? "left-0" : "left-[-30rem]"
        } md:hidden block absolute h-screen w-screen transition-all z-[51] bg-white`}
      >
        <HamburgerMenu handleSidebar={handleSidebar} />
      </div>

      <div className="md:pt-20 pt-16 md:px-6">
        <div className="flex gap-6">
          {/* Sidebar for desktop */}
          <div className="md:w-2/12 w-full bg-white rounded-md hidden md:block md:min-h-[calc(100vh-6rem)] min-h-[calc(100vh-4rem)] overflow-y-auto">
            <ProfileSidebar />
          </div>

          {/* Main Content */}
          <div className="md:w-10/12 w-full md:h-[calc(100vh-6rem)] h-[calc(100vh-4rem)] overflow-y-auto scroll-hidden">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyInfoWrapper;
