import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import axiosInstance from "../../Axios-Config/Axios-Config";
import addressFactor from "../../utils/AddressFactor";
import TrimAndCapital from "../../utils/TrimAndCapital";
import InputText from "../FormFields/InputText";
import LabelText from "../FormLabels/LabelText";
import { ErrorToast } from "../ToastComponent/ErrorToast";
import { SuccessToast } from "../ToastComponent/SuccessToast";
import MyAddressSkelton from "./MyAddressSkelton";
import { formInitialValues, formSchemaInitial } from "./MyAddressValidation";
import NoAddressFound from "./NoAddressFound";
export default function MyAddressView() {
  const { t } = useTranslation();
  const [postcode, setPostCode] = useState("");
  const [address, setAddress] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [postData, setPostData] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const [isDropdownVisible, setDropDownVisible] = useState(false);
  const onCloseModal = () => {
    reset();
    setOpen(false);
  };

  const [openDltModal, setOpenDltModal] = useState(false);
  const onOpenDltModal = () => setOpenDltModal(true);
  const onCloseDltModal = () => setOpenDltModal(false);
  const [deletedId, setDeletedId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    reset,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(formSchemaInitial),
    defaultValues: formInitialValues,
  });

  const getAddress = async () => {
    try {
      const response = await axiosInstance.get("get/user/address");
      setAddress(response?.data?.user_address_list);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAddress();
  }, []);

  const handleEdit = (item: any) => {
    setValue("id", item.id);
    setValue("address_type", item?.address_type);
    setValue("full_address", item?.full_address);
    setValue("house_no", item?.house_no);
    setValue("street_name", item?.street_name);
    setValue("country", item?.country);
    setValue("state", item?.state);
    setValue("city", item?.city);
    const postalcode = sessionStorage.getItem("yop-postcode");
    setValue("zip_code", postalcode || "");
    onOpenModal();
  };

  const formSubmit = async (state: any) => {
    try {
      const { data } = await axiosInstance.postForm(
        state?.id ? `update/user/address` : "save/user/address",
        state
      );

      if (data.success) {
        SuccessToast(data.message);
        reset(formInitialValues);
        getAddress();
        onCloseModal();
      } else {
        ErrorToast(data.message);
      }
      return false;
    } catch (error: any) {
      ErrorToast(error.message);
      return false;
    } finally {
    }
  };

  const getPostCode = async () => {
    clearErrors();
    reset(formInitialValues);
    setErrorMsg("");
    try {
      const { data }: { data: any } = await axiosInstance.get(
        `search/postcode/${TrimAndCapital(postcode)}`
      );

      if (data.success) {
        sessionStorage.setItem("yop-postcode", postcode);
        setValue("zip_code", data?.data?.postcode);
        setPostData(
          data?.data?.address.map((item: any) => ({
            ...item,
            label: item.address,
          }))
        );
        setDropDownVisible(true);
      } else {
        setPostData([]);
        setErrorMsg("No postal code found");
        setPostCode("");
        setDropDownVisible(false);
      }
    } catch (error: any) {
      setErrorMsg(error.message);
      setPostData([]);

      setPostCode("");
      setDropDownVisible(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.post("delete/user/address", { id: deletedId });

      setAddress((prevAddress: any) =>
        prevAddress.filter((item: any) => item.id !== deletedId)
      );
      SuccessToast("Address deleted successfully");
      setOpenDltModal(false);
      setDeletedId("");
    } catch (error: any) {
      setError(error.message);
      setOpenDltModal(false);
    }
  };

  const onHandleDropDownCLick = (e: any) => {
    const data = addressFactor(e.address);
    clearErrors();
    setValue("full_address", data?.full_address);
    setValue("street_name", data?.street);
    setValue("state", data?.state);
    setValue("country", "UK");
    setValue("city", data?.city);
  };

  return (
    <>
      <div className="p-3 md:p-0 mb-12 md:mb-0">
        <div className="md:flex justify-between items-center mb-3 hidden">
          <h1 className="text-lg font-bold ">{t("MyAddress.myAddress")}</h1>
          <button className="btn-secondary" onClick={onOpenModal}>
            <i className="fa fa-plus mr-1"></i> {t("MyAddress.newAddress")}
          </button>
        </div>
        {loading ? (
          <MyAddressSkelton />
        ) : address?.length > 0 ? (
          <>
            {address.map((item: any, i: any) => (
              <div className="border rounded-md p-3 text-[.9em] mb-2" key={i}>
                <div className="grid grid-cols-3 mb-1">
                  <p className="text-[#565656]">{t("MyAddress.addressType")}</p>
                  <p className="col-span-2 capitalize">
                    {item?.address_type || "N/A"}
                  </p>
                </div>
                <div className="grid grid-cols-3 mb-1">
                  <p className="text-[#565656]">{t("MyAddress.fullAddress")}</p>
                  <p className="col-span-2">{item?.full_address || "N/A"}</p>
                </div>
                <div className="grid grid-cols-3 mb-1">
                  <p className="text-[#565656]">{t("MyAddress.houseNo")}</p>
                  <p className="col-span-2">{item?.house_no || "N/A"}</p>
                </div>
                <div className="grid grid-cols-3 mb-1">
                  <p className="text-[#565656]">{t("MyAddress.streetName")}</p>
                  <p className="col-span-2">{item?.street_name || "N/A"}</p>
                </div>
                <div className="grid grid-cols-3 mb-1">
                  <p className="text-[#565656]">{t("MyAddress.country")}</p>
                  <p className="col-span-2">{item?.country || "N/A"}</p>
                </div>

                <div className="grid grid-cols-3 mb-1">
                  <p className="text-[#565656]">{t("MyAddress.city")}</p>
                  <p className="col-span-2">{item?.city || "N/A"}</p>
                </div>
                <div className="grid grid-cols-3 mb-2">
                  <p className="text-[#565656]">{t("MyAddress.zipCode")}</p>
                  <p className="col-span-2">
                    {sessionStorage.getItem("yop-postcode")}
                  </p>
                </div>
                <div className="grid grid-cols-3 ">
                  <p className="text-[#565656]">{t("MyAddress.actions")}</p>
                  <div className="flex gap-5 items-center">
                    <p
                      className="text-[#34558B]  cursor-pointer font-bold flex gap-3 items-center"
                      onClick={() => handleEdit(item)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M18.7275 1.99988C18.4715 1.99988 18.2155 2.09785 18.0205 2.29285L16.0205 4.29285L14.6064 5.70691L3.31348 16.9999V20.9999H7.31348L22.0205 6.29285C22.4115 5.90185 22.4115 5.26878 22.0205 4.87878L19.4346 2.29285C19.2396 2.09785 18.9835 1.99988 18.7275 1.99988ZM18.7275 4.41394L19.8994 5.58582L18.6064 6.87878L17.4346 5.70691L18.7275 4.41394ZM16.0205 7.12097L17.1924 8.29285L6.48535 18.9999H5.31348V17.828L16.0205 7.12097Z"
                          fill="#34558B"
                        />
                      </svg>
                      {t("MyAddress.edit")}
                    </p>
                    <p
                      className="text-[#34558B]  cursor-pointer font-bold flex gap-3 items-center"
                      onClick={() => {
                        onOpenDltModal();
                        setDeletedId(item.id);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M10.9619 1.99988L9.96192 2.99988H3.96191V4.99988H5.07129L6.85449 20.2557V20.2635C6.98552 21.2502 7.84224 21.9999 8.83691 21.9999H17.085C18.0796 21.9999 18.9364 21.2502 19.0674 20.2635L19.0693 20.2557L20.8525 4.99988H21.9619V2.99988H15.9619L14.9619 1.99988H10.9619ZM7.08691 4.99988H18.8369L17.085 19.9999H8.83691L7.08691 4.99988Z"
                          fill="#34558B"
                        />
                      </svg>
                      {t("MyAddress.delete")}
                    </p>
                  </div>
                </div>
              </div>
            ))}

            <div className="flex gap-2 radio mb-3">
              {/* Example radio button for Home, Work, and Office */}
              {/* <input type="radio" id="home" name="addressType" />
                  <label htmlFor="home">Home</label> */}
              {/* Add similar radio buttons for Work and Office if needed */}
            </div>
          </>
        ) : (
          <NoAddressFound content={t("MyAddress.noAddress")} />
        )}
        {/* <div className="my-3">
              <div className="flex gap-2 radio mb-3">
                <input type="radio" />
                <label>Home</label>
              </div>
              <div className="flex justify-between px-5 mb-3">
                <p>
                {address?.map((item: any, i: number) => (
                   <div className="" key={i}>
                    {`${item?.house_no || "N/A"}/${item?.full_address || "N/A"}, ${item?.street_name || "N/A"}, ${item?.country || "N/A"}, ${item?.district || "N/A"} ${item?.city || "N/A"} ${item?.zip_code || "N/A"}`}
                    <div className="flex gap-3">
                  <p className="text-[#34558B] cursor-pointer font-semibold"  onClick={() => handleEdit(item)}>
                    Edit
                  </p>
                  <p
                    className="cursor-pointer font-semibold"
                    onClick={onOpenDltModal}
                  >
                    Delete
                  </p>
                </div>
                   </div>
                    ))}
                
                </p>
               
              </div>
              <hr className="border-[#919191]" />
            </div> */}
        {/* <div className="profileDetail"> */}
        <div className="">
          {address?.map((item: any, i: number) => (
            <div className="" key={i}>
              {/* <div className="addressDetail"> */}
              <div className="">
                {/* <div className="addressRow">
                      <div className="addressType">Address Type</div>
                      <div className="addressName">
                        {item?.address_type || "N/A"}
                      </div>
                    </div> */}
                {/* <div className="addressRow">
                      <div className="addressType">Full address</div>
                      <div className="addressName">
                        {item?.full_address || "N/A"}
                      </div>
                    </div> */}
                {/* <div className="addressRow">
                      <div className="addressType">House No</div>
                      <div className="addressName">
                        {item?.house_no || "N/A"}
                      </div>
                    </div> */}
                {/* <div className="addressRow">
                      <div className="addressType">Street Name</div>
                      <div className="addressName">
                        {item?.street_name || "N/A"}
                      </div>
                    </div> */}
                {/* <div className="addressRow">
                      <div className="addressType">Country</div>
                      <div className="addressName">
                        {item?.country || "N/A"}
                      </div>
                    </div> */}
                {/* <div className="addressRow">
                      <div className="addressType">District</div>
                      <div className="addressName">
                        {item?.district || "N/A"}
                      </div>
                    </div> */}
                {/* <div className="addressRow">
                      <div className="addressType">City</div>
                      <div className="addressName">{item?.city || "N/A"}</div>
                    </div> */}
                {/* <div className="addressRow">
                      <div className="addressType">Zip Code</div>
                      <div className="addressName">
                        {item?.zip_code || "N/A"}
                      </div>
                    </div> */}
                <div className="addressRow">
                  {/* <div className="addressType">Actions</div> */}
                  <div className="addressName">
                    <div className="iconGroup flexBox gap-4">
                      {/* <div
                            className="iconType flexBox gap-2 textBlue"
                            onClick={() => handleEdit(item)}
                          >
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                            >
                              <path
                                d="M15.9349 2.20898C15.7216 2.20898 15.5082 2.29062 15.3457 2.45312L13.679 4.11979L12.5007 5.29818L3.08984 14.709V18.0423H6.42318L18.679 5.78646C19.0049 5.46062 19.0049 4.93307 18.679 4.60807L16.5241 2.45312C16.3616 2.29062 16.1482 2.20898 15.9349 2.20898ZM15.9349 4.2207L16.9115 5.19727L15.834 6.27474L14.8574 5.29818L15.9349 4.2207ZM13.679 6.47656L14.6556 7.45312L5.73307 16.3757H4.75651V15.3991L13.679 6.47656Z"
                                fill="#34558B"
                              />
                            </svg>
                            Edit
                          </div> */}

                      {/* <div
                            className="iconType flexBox gap-2 textBlue"
                            //
                            onClick={onOpenDltModal}
                          >
                            <svg
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                            >
                              <path
                                d="M8.98177 2.20898L8.14844 3.04232H3.14844V4.70898H4.07292L5.55892 17.4222V17.4287C5.66811 18.2509 6.38204 18.8757 7.21094 18.8757H14.0843C14.9132 18.8757 15.6271 18.2509 15.7363 17.4287L15.738 17.4222L17.224 4.70898H18.1484V3.04232H13.1484L12.3151 2.20898H8.98177ZM5.7526 4.70898H15.5443L14.0843 17.209H7.21094L5.7526 4.70898Z"
                                fill="#34558B"
                              />
                            </svg>
                            Delete
                          </div> */}
                      {/* delete modal */}

                      <Modal
                        open={openDltModal}
                        onClose={onCloseDltModal}
                        center
                        showCloseIcon={false}
                        classNames={{
                          modal: "rounded-md ",
                        }}
                      >
                        <div>
                          <h2 className="text-base mt-6 mb-6 font-semibold">
                            Are you sure want to delete this address?
                          </h2>

                          <div className="flex gap-2 justify-between">
                            <button
                              onClick={() => setOpenDltModal(false)}
                              className="border border-[#34558B] rounded-md text-[#34558B] font-semibold w-full p-3"
                            >
                              {t("MyAddress.cancel")}
                            </button>
                            <button
                              onClick={() => handleDelete()}
                              className="bg-[#34558B] rounded-md text-white font-semibold w-full p-3"
                            >
                              {t("MyAddress.delete")}
                            </button>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* <button
                onClick={onOpenModal}
                className="rightButton flexBox justifyEnd btnBlue arrowBtn"
              >
                + Add Address
              </button> */}
        </div>
      </div>
      <div className="md:hidden fixed bottom-0 p-2 bg-white w-full">
        <button
          className="btn-secondary w-full md:w-auto"
          onClick={onOpenModal}
        >
          <i className="fa fa-plus mr-1"></i> {t("MyAddress.newAddress")}
        </button>
      </div>
      <ToastContainer />
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "add-address-modal ",
        }}
      >
        <div className="md:p-0 p-3">
          {getValues("id") ? (
            <h1 className="font-bold text-base">
              {t("MyAddress.editAddress")}
            </h1>
          ) : (
            <h1 className="font-bold text-base">{t("MyAddress.addAddress")}</h1>
          )}
        </div>

        <form onSubmit={handleSubmit(formSubmit)} className="mt-2 h-full">
          <div className="formFields md:p-0 p-3 md:h-[calc(100vh-12rem)] h-[calc(100vh-10rem)] pb-10 overflow-y-auto scroll-hidden pointer-events-auto touch-auto">
            <div className="formItem radio flex gap-3">
              <p className="textMd font-medium">{t("MyAddress.addressType")}</p>
              <div className="flex md:gap-3 gap-2 items-center">
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    id="home"
                    value="home"
                    defaultChecked={true}
                    {...register("address_type", {
                      required: "Address Type is required",
                    })}
                  />
                  <label htmlFor="home">{t("MyAddress.home")}</label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    id="office"
                    value="office"
                    {...register("address_type", {
                      required: "Address Type is required",
                    })}
                  />
                  <label htmlFor="office">{t("MyAddress.office")}</label>
                </div>
                <div className="flex gap-2 items-center">
                  <input
                    type="radio"
                    id="other"
                    value="other"
                    {...register("address_type", {
                      required: "Address Type is required",
                    })}
                  />
                  <label htmlFor="other">{t("MyAddress.other")}</label>
                </div>
              </div>
            </div>
            <div className="border border-[#C5C5C5] rounded-[13px] flex mb-3 relative">
              <input
                autoComplete="new"
                className="formControl !border-none"
                placeholder="Search for a postcode"
                onChange={(e) => {
                  setPostCode(e.target.value);
                  // setValue("zip_code", e.target.value);
                }}
              />
              <button
                type="button"
                className="rounded-[13px] flex flex-nowrap whitespace-nowrap items-center gap-1 text-[.8em] text-white bg-[#34558B] px-10 font-semibold"
                disabled={
                  postcode?.trim()?.length < 4 ||
                  TrimAndCapital(postcode) == getValues("zip_code")
                }
                onClick={getPostCode}
              >
                <i className="fa fa-search"></i>
                Find Address
              </button>
              {errorMsg && <p className="errorField">{errorMsg}</p>}
            </div>

            {postData?.length ? (
              <div className="  text-[.8em] my-3">
                <Select
                  onChange={onHandleDropDownCLick}
                  className="basic-single "
                  classNamePrefix="select"
                  // isRtl={isRtl}
                  isSearchable={true}
                  // name="color"
                  options={postData}
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: `13px`,
                      fontSize: `14px`,
                      padding: `.2rem`,
                    }),
                    option: (base) => ({
                      ...base,
                      backgroundColor: `white`,
                      color: `black`,
                      fontSize: `14px`,
                    }),
                  }}
                />
              </div>
            ) : (
              ""
            )}

            <div className="formItem">
              <LabelText text={t("MyAddress.fullAddress")} />
              <InputText
                placeholder={t("MyAddress.enterFullAddress")}
                name="full_address"
                register={register}
              />
              <div className="errorField">
                {errors.full_address?.message
                  ? t(errors.full_address?.message)
                  : ""}
              </div>
            </div>
            <div className="formItem">
              <LabelText text={t("MyAddress.houseNo")} />
              <InputText
                placeholder={t("MyAddress.enterHouseNo")}
                name="house_no"
                register={register}
              />
              <div className="errorField">
                {errors.house_no?.message ? t(errors.house_no?.message) : ""}
              </div>
            </div>
            <div className="formItem">
              <LabelText text={t("MyAddress.streetName")} />
              <InputText
                placeholder={t("MyAddress.enterStreetName")}
                name="street_name"
                register={register}
              />
              <div className="errorField">
                {errors.street_name?.message
                  ? t(errors.street_name?.message)
                  : ""}
              </div>
            </div>
            <div className="formItem">
              <LabelText text={t("MyAddress.country")} />
              <InputText
                placeholder={t("MyAddress.enterCountry")}
                name="country"
                register={register}
              />
              <div className="errorField">
                {errors.country?.message ? t(errors.country?.message) : ""}
              </div>
            </div>
            <div className="formItem">
              <LabelText text={t("MyAddress.state")} />
              <InputText
                placeholder={t("MyAddress.state")}
                name="state"
                register={register}
              />
              <div className="errorField">
                {errors.state?.message ? t(errors.state?.message) : ""}
              </div>
            </div>

            <div className="formItem">
              <LabelText text={t("MyAddress.city")} />
              <InputText
                placeholder={t("MyAddress.enterCity")}
                name="city"
                register={register}
              />
              <div className="errorField">
                {errors.city?.message ? t(errors.city?.message) : ""}
              </div>
            </div>
            <div className="formItem">
              <LabelText text={t("MyAddress.zipCode")} />
              <InputText
                placeholder={t("MyAddress.enterZipCode")}
                name="zip_code"
                register={register}
                readonly
              />
              <div className="errorField">
                {errors.zip_code?.message ? t(errors.zip_code.message) : ""}
              </div>
            </div>
          </div>
          <div className="md:static fixed bottom-0 w-full bg-white md:px-0 p-3 touch-auto pointer-events-auto">
            <button type="submit" className="btn-secondary md:w-auto w-full">
              {t("MyAddress.submit")}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
