export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";

export const SETCATEGORYLIST = (data:any) => {
  return {
    type: SET_CATEGORY_LIST,
    payload: data,
  };
};
export const SETSELECTEDCATEGORY = (data:any) => {
    return {
      type: SET_SELECTED_CATEGORY,
      payload: data,
    };
  };


const initialState:Object = {category:[],selectedCategory:''};


const reducer = (state = initialState, action:any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CATEGORY_LIST:
      return {
        ...state,
        category:payload
      };
      case SET_SELECTED_CATEGORY:
        return {
          ...state,
          selectedCategory:payload
        };

    default:
      return state;
  }
};

export default reducer;
