import {
  SETCARTCOUNT,
  SETCARTDATA,
  SETCARTTOTALPRICE,
} from "../redux/features/CartData";
import { GetTotalPrice } from "./GetTotalPrice";
import { TaxCalcualate } from "./TaxCalculate";

const incrementItemData = (
  data: any,
  cardData: any,
  disPatch: Function,
  itemCount: number,
  taxdata?: any
) => {
  const index = cardData.indexOf(data);
  let TaxData = TaxCalcualate(
    taxdata,
    Number(data?.sub_total) + Number(data?.web_price),
    Number(data.quantity) + 1
  );

  data["quantity"] = Number(data.quantity) + 1;
  data["sub_total"] = Number(data?.sub_total) + Number(data?.web_price);
  data["total_price"] =
    Number(data?.total_price) +
    Number(data?.web_price) +
    TaxData.fixedTax +
    (Number(data?.web_price) * TaxData.percentageTax) / 100;
  data["tax_rate_list"] = TaxData.taxArray;
  cardData.splice(index, 1, data);
  disPatch(SETCARTDATA(cardData));
  disPatch(SETCARTTOTALPRICE(GetTotalPrice(cardData)));
  disPatch(SETCARTCOUNT(itemCount + 1));
  sessionStorage.setItem("items", JSON.stringify(cardData));
};
export default incrementItemData;
