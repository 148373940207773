import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import {
  SETCARTCOUNT,
  SETCARTDATA,
  SETCARTTOTALPRICE,
} from "../../redux/features/CartData";
import { CLEARVARIATIONDATA } from "../../redux/features/Variation";
import ParseFloat from "../../utils/ParseFloat";
import { SortVarition } from "../../utils/SortVariation";
import { TaxCalcualate } from "../../utils/TaxCalculate";
import ItemTitleDetail from "../RightSideBar/ItemTitleDetail/ItemTitleDetail";
import ItemVariationView from "../RightSideBar/ItemVariationView/ItemVariationView";
import StepTitle from "../RightSideBar/StepTitle/StepTitle";
interface customeType {
  open: boolean;
  onCloseModal: any;
  taxdata: any;
  menu_id: any;
}
const VariationModal = ({
  open,
  onCloseModal,
  menu_id,
  taxdata,
}: customeType) => {
  const {
    variationData,
    cartData: { itemCount, items },
  }: any = useSelector((state: any) => state);
  const [selectData, setSelectData] = useState<any>();
  const [selectAddOn, setSelectedAddOn] = useState<any>([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  useEffect(() => {
    if (variationData) {
      setSelectData(SortVarition(variationData)?.item_variation[0]);
    }
    if (variationData.isEdit) {
      setSelectData(variationData?.selected_variation);
      setSelectedAddOn(variationData?.selected_addOn);
    }
  }, [variationData]);
  const variatiosAdd = async (data: any) => {
    const finalData: any = [];

    const store_variation_price = variationData?.map_store_item_variation?.find(
      (variation: any) => variation.variation_id === data.variation_id
    );
    data["web_price"] = parseFloat(
      store_variation_price
        ? store_variation_price?.offered_variation_price
        : data?.web_price
    );
    let price =
      parseFloat(
        store_variation_price
          ? store_variation_price?.offered_variation_price
          : data?.web_price
      ) || 0;

    selectAddOn.map((data: any) => {
      price =
        price + parseFloat(data.web_offered_price ? data.web_offered_price : 0);
    });
    let TaxData = TaxCalcualate(taxdata, price, 1);
    const varData = variationData;
    varData["map_tax_class"] = taxdata.map_tax_class;
    varData["tax_class"] = taxdata.tax_class;
    varData["price"] = price;
    varData["sub_total"] = price;
    varData["tax_rate_list"] = TaxData.taxArray;
    varData["selected_variation"] = data;

    varData["total_price"] =
      Number(price) +
      TaxData.fixedTax +
      (Number(price) * TaxData.percentageTax) / 100;
    varData["selectedCategory"] = [];
    varData["selected_addOn"] = selectAddOn;
    varData["selectedoptions"] = [];
    varData["map_store_item_variation"] =
      variationData?.map_store_item_variation?.length > 0
        ? variationData?.map_store_item_variation
        : [];
    varData["quantity"] = 1;
    varData["menu_id"] = menu_id;
    variationData.isEdit !== true &&
      (varData["uniqueIndex"] = items ? items.length + 1 : 1);
    finalData.push(varData);
    const previous = items;
    if (previous.length > 0) {
      if (variationData.isEdit) {
        const index = previous.findIndex(
          (item: any) => item.uniqueIndex == varData.uniqueIndex
        );
        previous.splice(index, 1, varData);
      } else {
        previous.push(varData);
        dispatch(SETCARTCOUNT(itemCount + 1));
      }
      dispatch(SETCARTDATA(previous));
      let total = 0;
      previous.map((data: any) => {
        //(data)
        total =
          parseFloat(`${total}`) +
          (data.total_price
            ? parseFloat(data.total_price)
            : parseFloat(data.price));
      });

      dispatch(SETCARTTOTALPRICE(total));
      if (sessionStorage.getItem("backCheckout") == "1") {
        navigation("/checkout");
        sessionStorage.removeItem("backCheckout");
      }
    } else {
      dispatch(SETCARTDATA(finalData));
      let total = 0;
      finalData?.map((data: any) => {
        //(data)
        total =
          parseFloat(`${total}`) +
          (data.total_price
            ? parseFloat(data.total_price)
            : parseFloat(data.price));
      });

      dispatch(SETCARTTOTALPRICE(total));
      dispatch(SETCARTCOUNT(itemCount + 1));
      if (sessionStorage.getItem("backCheckout") == "1") {
        navigation("/checkout");
        sessionStorage.removeItem("backCheckout");
      }
    }
    dispatch(CLEARVARIATIONDATA({}));
    setSelectData({});
    onCloseModal();
    if (sessionStorage.getItem("backCheckout") == "1") {
      navigation("/checkout");
      sessionStorage.removeItem("backCheckout");
    }
  };
  useEffect(() => {
    if (selectData) {
      let price = Number(selectData?.web_price || 0);
      selectAddOn?.map((item: any) => {
        price += Number(item.web_offered_price ? item.web_offered_price : 0);
      });

      setFinalPrice(price);
    }
  }, [selectData]);
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
        closeIcon: "modal-close",
      }}
    >
      {/* <div className="bg-[#1466fa] flex justify-center items-center py-16">
                                                        <img src="/logo.png" width="130" height="30" alt="Logo" />
                                                      </div> */}
      <ItemTitleDetail
        itemTitle={variationData?.name}
        itemDetail={variationData?.description}
      />
      <div className="p-4 md:max-h-96 h-[calc(100vh-12rem)] pb-24 overflow-y-auto scroll-hidden pointer-events-auto touch-auto">
        <div className="variationSection mb-2">
          <StepTitle stepTitle="Please select Variations" success={true} />
          <div className="flex flex-wrap gap-2">
            {selectData &&
              variationData?.item_variation.map((data: any, index: number) => {
                const store_variation_price =
                  variationData?.map_store_item_variation?.find(
                    (variation: any) =>
                      variation.variation_id === data.variation_id
                  );
                return (
                  <div>
                    <ItemVariationView
                      selectedId={selectData?.id}
                      price={
                        store_variation_price
                          ? store_variation_price.offered_variation_price
                          : data.web_price
                      }
                      id={data.id}
                      itemName={data?.variation_detail[0]?.variation_name}
                      onchange={() => setSelectData(data)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="md:static fixed bottom-0 w-full bg-white touch-auto pointer-events-auto">
        <div className="border-t py-3 px-3 flex justify-end">
          <button
            onClick={() => variatiosAdd(selectData)}
            className="inline-flex md:w-auto w-full justify-between gap-14 bgBlue text-[#fff] textMd font-bold md:py-2 py-4 px-3  rounded-xl"
          >
            <span>{ParseFloat(finalPrice)}</span>
            <div className="flex gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 34 28"
                fill="none"
              >
                <path
                  d="M1.62491 0.49999C1.47583 0.497882 1.32783 0.525423 1.18949 0.581014C1.05115 0.636604 0.925236 0.719135 0.81907 0.82381C0.712904 0.928485 0.628601 1.05322 0.57106 1.19076C0.513518 1.3283 0.483887 1.4759 0.483887 1.62499C0.483887 1.77408 0.513518 1.92168 0.57106 2.05922C0.628601 2.19676 0.712904 2.3215 0.81907 2.42617C0.925236 2.53085 1.05115 2.61338 1.18949 2.66897C1.32783 2.72456 1.47583 2.7521 1.62491 2.74999H3.6918C4.23775 2.74999 4.69285 3.12718 4.7963 3.66405L5.02042 4.83593L7.43887 17.5376C7.87621 19.8292 9.89526 21.5 12.2289 21.5H26.2709C28.6046 21.5 30.6245 19.8299 31.0609 17.5376L33.4794 4.83593C33.5104 4.6733 33.5052 4.50583 33.464 4.34547C33.4229 4.1851 33.3468 4.03579 33.2413 3.9082C33.1359 3.7806 33.0035 3.67785 32.8537 3.60729C32.704 3.53673 32.5405 3.50009 32.3749 3.49999H7.05509L7.00675 3.24364C6.7044 1.65914 5.30514 0.49999 3.6918 0.49999H1.62491ZM7.48428 5.74999H31.0155L28.8505 17.1172C28.6135 18.3619 27.5387 19.25 26.2709 19.25H12.2289C10.9615 19.25 9.8875 18.3618 9.64932 17.1172V17.1157L7.48428 5.74999ZM13.9999 23C13.4032 23 12.8309 23.237 12.4089 23.659C11.987 24.081 11.7499 24.6533 11.7499 25.25C11.7499 25.8467 11.987 26.419 12.4089 26.841C12.8309 27.2629 13.4032 27.5 13.9999 27.5C14.5966 27.5 15.1689 27.2629 15.5909 26.841C16.0129 26.419 16.2499 25.8467 16.2499 25.25C16.2499 24.6533 16.0129 24.081 15.5909 23.659C15.1689 23.237 14.5966 23 13.9999 23ZM24.4999 23C23.9032 23 23.3309 23.237 22.9089 23.659C22.487 24.081 22.2499 24.6533 22.2499 25.25C22.2499 25.8467 22.487 26.419 22.9089 26.841C23.3309 27.2629 23.9032 27.5 24.4999 27.5C25.0966 27.5 25.6689 27.2629 26.0909 26.841C26.5129 26.419 26.7499 25.8467 26.7499 25.25C26.7499 24.6533 26.5129 24.081 26.0909 23.659C25.6689 23.237 25.0966 23 24.4999 23Z"
                  fill="#fff"
                ></path>
              </svg>
              Add to Order
            </div>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VariationModal;
