import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  SETCARTCOUNT,
  SETCARTDATA,
  SETCARTTOTALPRICE,
} from "../../redux/features/CartData";
import { CLEARCUSTOMIZEDDATA } from "../../redux/features/CustomizedData";
import GetStepOptionId from "../../utils/CustomizedCalculation/GetStepOptionId";
import getSubData from "../../utils/CustomizedCalculation/GetSubData";
import GetCustomizedSubTotal from "../../utils/CustomizedCalculation/GetSubTotal";
import selectOptionData from "../../utils/CustomizedCalculation/SelectOptionData";
import { GetTotalPrice } from "../../utils/GetTotalPrice";
import ParseFloat from "../../utils/ParseFloat";
import { SortVarition } from "../../utils/SortVariation";
import { TaxCalcualate } from "../../utils/TaxCalculate";
import { Vibrate } from "../../utils/Vibrate/Vibrate";
import ItemOption from "../RightSideBar/ItemOption/ItemOption";
import ItemTitleDetail from "../RightSideBar/ItemTitleDetail/ItemTitleDetail";
import ItemVariationView from "../RightSideBar/ItemVariationView/ItemVariationView";
import StepTitle from "../RightSideBar/StepTitle/StepTitle";
import { SuccessToast } from "../ToastComponent/SuccessToast";

interface customeType {
  open: boolean;
  onCloseModal: any;
  taxdata: any;
  menu_id: any;
}
const CustomizedModal = ({
  open,
  onCloseModal,
  menu_id,
  taxdata,
}: customeType) => {
  const {
    customizedData,
    cartData: { itemCount, items },
  }: any = useSelector((state: any) => state);
  const [selectedVariation, setSelectedVariation] = useState<any>();
  const [stepOptionId, setStepOptionId] = useState<any>([]);
  const [categoryId, setCategoryId] = useState<any>([]);
  const [selectAddOn, setSelectedAddOn] = useState<any>([]);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [finalPrice, setFinalPrice] = useState(0);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (customizedData) {
      setSelectedVariation(SortVarition(customizedData)?.item_variation[0]);
    }
    if (customizedData.isEdit) {
      setFinalPrice(customizedData?.total_price);
      setStepOptionId(customizedData.selectedoptions);
      setCategoryId(customizedData.selectedCategory);
      setSelectedVariation(customizedData.selected_variation);
      setSelectedAddOn(customizedData?.selected_addOn);
    }
  }, [customizedData]);

  // add to cart ...........
  const addToCart = async () => {
    const item = structuredClone(customizedData);
    if (customizedData.isEdit) {
      const required = item?.item_step.filter(
        (data: any) => data.required === "1"
      );
      const categoryRequired = item?.step_sub_category_data?.filter(
        (item: any) => item?.required === "1"
      );
      if (required.length > 0 || categoryRequired?.length > 0) {
        const match = [];

        const match1 = [];

        required.map((data: any) => {
          const find = stepOptionId.find(
            (data1: any) => data1.step_id === data.item_step_id
          );
          if (find) {
            match.push(find);
          }
        });
        categoryRequired.map((data: any) => {
          const find = categoryId.find(
            (data1: any) => data1?.side_id === data?.item_step_id
          );
          if (find) {
            match1.push(find);
          }
        });
        if (
          required.length <= match.length &&
          categoryRequired?.length <= match1.length
        ) {
          item["selectedoptions"] = GetStepOptionId({
            stepOptionId,
            selectedVariation,
          });
          item["selectedCategory"] = categoryId;
          item["quantity"] = 1;
          item["uniqueIndex"] = customizedData.uniqueIndex;
          item["selected_addOn"] = customizedData?.selected_addOn;
          item["selected_variation"] = selectedVariation;
          item["menu_id"] = menu_id;
          item["map_tax_class"] = taxdata.map_tax_class;
          item["tax_class"] = taxdata.tax_class;
          let TaxData = TaxCalcualate(taxdata, finalPrice, 1);
          item["total_price"] =
            Number(finalPrice) +
            TaxData.fixedTax +
            (Number(finalPrice) * TaxData.percentageTax) / 100;

          item["map_tax_class"] = taxdata.map_tax_class;
          item["price"] = selectedVariation?.variation_price
            ? parseFloat(
                selectedVariation?.web_price
                  ? selectedVariation?.web_price
                  : item.variation_price
              )
            : item?.map_store_item?.length > 0
            ? parseFloat(item?.map_store_item[0]?.offered_price)
            : parseFloat(item.variation_price);
          item["sub_total"] = finalPrice;
          item["tax_rate_list"] = TaxData.taxArray;

          const findData = items;
          const ui = findData.find(
            (e: any) => e.uniqueIndex === item.uniqueIndex
          );
          const index = findData.indexOf(ui);
          findData.splice(index, 1, item);
          dispatch(SETCARTDATA(findData));
          dispatch(CLEARCUSTOMIZEDDATA({}));
          onCloseModal();
          dispatch(SETCARTTOTALPRICE(GetTotalPrice(findData)));
          if (sessionStorage.getItem("backCheckout") == "1") {
            navigation("/checkout");
            sessionStorage.removeItem("backCheckout");
          }
        } else {
          setError(true);
          Vibrate();

          // ErrorToast("please select Mandatory options");
        }
      } else {
        item["selectedoptions"] = GetStepOptionId({
          stepOptionId,
          selectedVariation,
        });
        item["selectedCategory"] = categoryId;
        item["quantity"] = null;
        item["uniqueIndex"] = customizedData.uniqueIndex;
        item["selected_addOn"] = customizedData?.selected_addOn;
        item["selected_variation"] = selectedVariation;
        item["menu_id"] = menu_id;
        let TaxData = TaxCalcualate(taxdata, finalPrice, 1);
        item["total_price"] =
          Number(finalPrice) +
          TaxData.fixedTax +
          (Number(finalPrice) * TaxData.percentageTax) / 100;
        item["price"] = selectedVariation?.variation_price
          ? parseFloat(
              selectedVariation?.variation_price
                ? selectedVariation?.variation_price
                : item.web_price
            )
          : item?.map_store_item?.length > 0
          ? parseFloat(item?.map_store_item[0]?.offered_price)
          : parseFloat(item.web_price);
        item["map_tax_class"] = taxdata.map_tax_class;
        item["tax_class"] = taxdata.tax_class;
        item["sub_total"] = finalPrice;
        item["tax_rate_list"] = TaxData.taxArray;
        const findData = items;
        const ui = findData.find(
          (e: any) => e.uniqueIndex === item.uniqueIndex
        );
        const index = findData.indexOf(ui);
        findData.splice(index, 1, item);
        dispatch(SETCARTDATA(findData));
        dispatch(SETCARTTOTALPRICE(GetTotalPrice(findData)));
        dispatch(CLEARCUSTOMIZEDDATA({}));
        onCloseModal();
        if (sessionStorage.getItem("backCheckout") == "1") {
          navigation("/checkout");
          sessionStorage.removeItem("backCheckout");
        }
      }
    } else {
      const required = item?.item_step?.filter(
        (data: any) => data.required === "1"
      );
      const categoryRequired = item?.step_sub_category_data?.filter(
        (item: any) => item?.required === "1"
      );

      if (required?.length > 0 || categoryRequired?.length > 0) {
        const match = [];
        const match1 = [];

        required.map((data: any) => {
          const find = stepOptionId.find(
            (data1: any) => data1?.step_id === data?.item_step_id
          );
          if (find) {
            match.push(find);
          }
        });
        categoryRequired.map((data: any) => {
          const find = categoryId.find(
            (data1: any) => data1?.side_id === data?.item_step_id
          );
          if (find) {
            match1.push(find);
          }
        });

        if (
          required.length <= match.length &&
          categoryRequired?.length <= match1.length
        ) {
          item["selectedoptions"] = GetStepOptionId({
            stepOptionId,
            selectedVariation,
          });
          item["selectedCategory"] = categoryId;
          item["selected_addOn"] = [];
          item["quantity"] = null;
          item["menu_id"] = menu_id;
          let TaxData = TaxCalcualate(taxdata, finalPrice, 1);
          item["total_price"] =
            Number(finalPrice) +
            TaxData.fixedTax +
            (Number(finalPrice) * TaxData.percentageTax) / 100;
          item["price"] = selectedVariation?.web_price
            ? parseFloat(
                selectedVariation?.web_price
                  ? selectedVariation?.web_price
                  : item.variation_price
              )
            : item?.map_store_item?.length > 0
            ? parseFloat(item?.map_store_item[0]?.offered_price)
            : parseFloat(item.variation_price);
          item["map_tax_class"] = taxdata.map_tax_class;
          item["tax_class"] = taxdata.tax_class;
          item["sub_total"] = finalPrice;
          item["tax_rate_list"] = TaxData.taxArray;
          //(price)

          if (itemCount > 0) {
            const items1 = items;
            item["uniqueIndex"] =
              items.length > 0 ? items1[items1.length - 1].uniqueIndex + 1 : 1;
            item["selected_variation"] = selectedVariation;
            //(items)
            items1.push(item);
            //(items)
            dispatch(SETCARTDATA(items1));
            dispatch(SETCARTCOUNT(itemCount + 1));
            dispatch(CLEARCUSTOMIZEDDATA({}));
            onCloseModal();
            dispatch(SETCARTTOTALPRICE(GetTotalPrice(items1)));

            if (sessionStorage.getItem("backCheckout") == "1") {
              navigation("/checkout");
              sessionStorage.removeItem("backCheckout");
            }
          } else {
            item["uniqueIndex"] = 1;
            item["selected_variation"] = selectedVariation;
            const array = [item];
            dispatch(SETCARTDATA(array));
            dispatch(SETCARTCOUNT(itemCount + 1));
            dispatch(CLEARCUSTOMIZEDDATA({}));
            onCloseModal();
            dispatch(SETCARTTOTALPRICE(GetTotalPrice(array)));

            //(array)
          }
          SuccessToast(`${item?.name} is added`);
          if (sessionStorage.getItem("backCheckout") == "1") {
            navigation("/checkout");
            sessionStorage.removeItem("backCheckout");
          }
        } else {
          setError(true);
          Vibrate();
          // ErrorToast("please select Mandatory options");
        }
      } else {
        item["selectedoptions"] = GetStepOptionId({
          selectedVariation,
          stepOptionId,
        });
        item["selectedCategory"] = categoryId;
        item["selected_addOn"] = [];
        item["quantity"] = 1;
        item["selected_variation"] = selectedVariation;
        item["menu_id"] = menu_id;
        let TaxData = TaxCalcualate(taxdata, finalPrice, 1);
        item["total_price"] =
          Number(finalPrice) +
          TaxData.fixedTax +
          (Number(finalPrice) * TaxData.percentageTax) / 100;
        item["price"] = selectedVariation?.variation_price
          ? parseFloat(
              selectedVariation?.variation_price
                ? selectedVariation?.variation_price
                : item.web_price
            )
          : item?.map_store_item?.length > 0
          ? parseFloat(item?.map_store_item[0]?.offered_price)
          : parseFloat(item.web_price);
        item["map_tax_class"] = taxdata.map_tax_class;
        item["tax_class"] = taxdata.tax_class;
        item["sub_total"] = finalPrice;
        item["tax_rate_list"] = TaxData.taxArray;
        if (items.length > 0) {
          const allItems = items;
          item["uniqueIndex"] =
            allItems.length > 0
              ? allItems[items.length - 1].uniqueIndex + 1
              : 1;
          allItems.push(item);
          dispatch(SETCARTDATA(allItems));
          dispatch(SETCARTCOUNT(itemCount + 1));
          dispatch(CLEARCUSTOMIZEDDATA({}));
          onCloseModal();
          dispatch(SETCARTTOTALPRICE(GetTotalPrice(allItems)));
        } else {
          item["uniqueIndex"] = 1;
          const array = [item];
          dispatch(SETCARTDATA(array));
          dispatch(SETCARTCOUNT(itemCount + 1));
          dispatch(CLEARCUSTOMIZEDDATA({}));
          onCloseModal();
          dispatch(SETCARTTOTALPRICE(GetTotalPrice(array)));
        }
        SuccessToast(`${item?.name} is added`);
      }
    }
  };

  // update price ..........
  useEffect(() => {
    setFinalPrice(
      GetCustomizedSubTotal({
        selectAddOn,
        stepOptionId,
        categoryId,
        customizedData,
        selectedVariation,
      })
    );
  }, [selectedVariation, stepOptionId, categoryId, customizedData]);

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      classNames={{
        overlay: "customOverlay",
        modal: "customModal",
        closeIcon: "modal-close",
      }}
    >
      <ItemTitleDetail
        itemTitle={customizedData?.name}
        itemDetail={customizedData?.description}
      />
      <div className="p-4 md:max-h-96 h-[calc(100vh-12rem)] pb-24 overflow-y-auto scroll-hidden pointer-events-auto touch-auto">
        {/*--------------------- variation Start ------------------------- */}
        <div className="variationSection mb-2">
          {customizedData.item_variation &&
            customizedData.item_variation.length > 0 && (
              <StepTitle
                stepTitle="Please select Variations"
                err={false}
                success={true}
              />
            )}

          <div className="flex flex-wrap gap-2">
            {selectedVariation &&
              customizedData?.item_variation.map((data: any, index: number) => {
                const store_variation_price =
                  customizedData?.map_store_item_variation?.find(
                    (variation: any) =>
                      variation.variation_id === data.variation_id
                  );

                return (
                  <div>
                    <ItemVariationView
                      selectedId={selectedVariation?.id}
                      price={
                        store_variation_price
                          ? store_variation_price.offered_variation_price
                          : data.web_price
                      }
                      id={data.id}
                      itemName={data?.variation_detail[0]?.variation_name}
                      onchange={() => setSelectedVariation(data)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        {/*--------------------- variation End ------------------------- */}
        {/* --------------------- Option Start -------------------------- */}
        {customizedData?.item_step?.map((stepList: any) => {
          return (
            <div className="variationSection mb-2" key={stepList.item_step_id}>
              {stepList?.option?.length > 0 && (
                <StepTitle
                  key={stepList.item_step_id}
                  stepTitle={stepList?.title}
                  required={stepList?.required == 1}
                  err={
                    error &&
                    stepList?.required == 1 &&
                    stepOptionId?.filter((obj: any) => {
                      return obj.step_id === stepList?.item_step_id;
                    })?.length == 0
                  }
                  success={
                    stepList?.required == 1 &&
                    stepOptionId?.filter((obj: any) => {
                      return obj.step_id === stepList?.item_step_id;
                    })?.length
                  }
                />
              )}
              <div className="flex flex-wrap gap-2">
                {stepList?.option?.map((optionData: any) => {
                  return (
                    <div key={optionData?.item_step_option_id}>
                      <ItemOption
                        defaultChecked={
                          customizedData.isEdit &&
                          customizedData.selectedoptions.find(
                            (e: any) =>
                              e.item_step_option_id ===
                              optionData.item_step_option_id
                          )
                            ? true
                            : false
                        }
                        id={optionData?.item_step_option_id}
                        price={optionData?.web_price}
                        itemName={optionData?.title}
                        onchange={(e: any) => {
                          setError(false);
                          selectOptionData({
                            e,
                            optionData,
                            stepList,
                            stepOptionId,
                            setStepOptionId,
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {/* --------------------- Option End -------------------------- */}
        {/* --------------------- Category Start -------------------------- */}

        {customizedData?.step_sub_category_data?.map((stepList: any) => {
          return (
            <>
              {stepList ? (
                <div
                  className="variationSection mb-2"
                  key={stepList.item_step_id}
                >
                  {stepList?.map_item_side?.length > 0 && (
                    <StepTitle
                      key={stepList.item_step_id}
                      stepTitle={stepList?.title}
                      required={stepList?.required == 1}
                      err={
                        error &&
                        stepList?.required == 1 &&
                        categoryId?.filter((obj: any) => {
                          return obj.side_id === stepList?.item_step_id;
                        })?.length == 0
                      }
                      success={
                        stepList?.required == 1 &&
                        categoryId?.filter((obj: any) => {
                          return obj.side_id === stepList?.item_step_id;
                        })?.length
                      }
                    />
                  )}
                  <div className="flex flex-wrap gap-2">
                    {stepList?.map_item_side?.map((optionData: any) => {
                      return (
                        <>
                          {optionData ? (
                            <div key={optionData?.item_id}>
                              <ItemOption
                                defaultChecked={
                                  customizedData.isEdit &&
                                  customizedData.selectedCategory.find(
                                    (e: any) => e.item_id === optionData.item_id
                                  )
                                    ? true
                                    : false
                                }
                                id={optionData?.item_id}
                                price={optionData?.mapNewItem?.web_price}
                                itemName={optionData?.mapNewItem?.name}
                                onchange={(e: any) =>
                                  getSubData({
                                    e,
                                    optionData,
                                    stepList,
                                    categoryId,
                                    setCategoryId,
                                    customizedData,
                                  })
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
        {/* --------------------- Category End -------------------------- */}
        {/* add on */}
        {/* <StepTitle stepTitle="You may also want" /> */}
        {/* <input type="checkbox" id="addon" name="addon" />
        <label htmlFor="addon" className="!inline-flex !gap-10">
          <span>Rose Churma </span>
          <div>
            <span className="text-[.7em] line-through mr-1">80.00</span>
            <span className="font-bold">70.00</span>
          </div>
        </label> */}
      </div>
      <div className="md:static fixed bottom-0 w-full bg-white touch-auto pointer-events-auto">
        <div className="border-t py-3 px-3 flex justify-end ">
          <button
            onClick={addToCart}
            className=" inline-flex md:w-72 w-full justify-between gap-14 btn-secondary !px-4"
          >
            <span>{ParseFloat(finalPrice)}</span>
            <div className="flex gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 34 28"
                fill="none"
              >
                <path
                  d="M1.62491 0.49999C1.47583 0.497882 1.32783 0.525423 1.18949 0.581014C1.05115 0.636604 0.925236 0.719135 0.81907 0.82381C0.712904 0.928485 0.628601 1.05322 0.57106 1.19076C0.513518 1.3283 0.483887 1.4759 0.483887 1.62499C0.483887 1.77408 0.513518 1.92168 0.57106 2.05922C0.628601 2.19676 0.712904 2.3215 0.81907 2.42617C0.925236 2.53085 1.05115 2.61338 1.18949 2.66897C1.32783 2.72456 1.47583 2.7521 1.62491 2.74999H3.6918C4.23775 2.74999 4.69285 3.12718 4.7963 3.66405L5.02042 4.83593L7.43887 17.5376C7.87621 19.8292 9.89526 21.5 12.2289 21.5H26.2709C28.6046 21.5 30.6245 19.8299 31.0609 17.5376L33.4794 4.83593C33.5104 4.6733 33.5052 4.50583 33.464 4.34547C33.4229 4.1851 33.3468 4.03579 33.2413 3.9082C33.1359 3.7806 33.0035 3.67785 32.8537 3.60729C32.704 3.53673 32.5405 3.50009 32.3749 3.49999H7.05509L7.00675 3.24364C6.7044 1.65914 5.30514 0.49999 3.6918 0.49999H1.62491ZM7.48428 5.74999H31.0155L28.8505 17.1172C28.6135 18.3619 27.5387 19.25 26.2709 19.25H12.2289C10.9615 19.25 9.8875 18.3618 9.64932 17.1172V17.1157L7.48428 5.74999ZM13.9999 23C13.4032 23 12.8309 23.237 12.4089 23.659C11.987 24.081 11.7499 24.6533 11.7499 25.25C11.7499 25.8467 11.987 26.419 12.4089 26.841C12.8309 27.2629 13.4032 27.5 13.9999 27.5C14.5966 27.5 15.1689 27.2629 15.5909 26.841C16.0129 26.419 16.2499 25.8467 16.2499 25.25C16.2499 24.6533 16.0129 24.081 15.5909 23.659C15.1689 23.237 14.5966 23 13.9999 23ZM24.4999 23C23.9032 23 23.3309 23.237 22.9089 23.659C22.487 24.081 22.2499 24.6533 22.2499 25.25C22.2499 25.8467 22.487 26.419 22.9089 26.841C23.3309 27.2629 23.9032 27.5 24.4999 27.5C25.0966 27.5 25.6689 27.2629 26.0909 26.841C26.5129 26.419 26.7499 25.8467 26.7499 25.25C26.7499 24.6533 26.5129 24.081 26.0909 23.659C25.6689 23.237 25.0966 23 24.4999 23Z"
                  fill="#fff"
                ></path>
              </svg>
              Add to Order
            </div>
          </button>
        </div>
      </div>

      <ToastContainer />
    </Modal>
  );
};
export default CustomizedModal;
