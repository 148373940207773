import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import orderApi from "../../api/OrderApi";
import LoaderButton from "../../components/Buttons/LoaderButton";
import CheckoutDetails from "../../components/Checkout/CheckoutDetails";
import {
  CollectionSchema,
  DeliverySchema,
  initialDeliveryState,
} from "../../components/Checkout/DeliveryValidation";
import DeliveryView from "../../components/Checkout/DeliveryView";
import PaymentMethod from "../../components/Checkout/PaymentMethod";
import Header from "../../components/Header/Header";
import PaymentModal from "../../components/Modal/PaymentModal";
import useAllCharges from "../../hooks/AllCharge";
import { CLEARALLORDER } from "../../redux/features/AllOrder";
import { CLEARCART } from "../../redux/features/CartData";
import { SETLOADER } from "../../redux/features/common";
import FilterTaxes from "../../utils/FilterTaxes";
import ParseFloat from "../../utils/ParseFloat";

export default function Checkout() {
  const { items, itemCount } = useSelector((state: any) => state?.cartData);
  const [taxes, discount, service_charge, remaining_amount, totalPrice] =
    useAllCharges();
  const {
    store: { selectedStore },
    userData: { user },
    commonData: { loader },
  } = useSelector((state: any) => state);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDelivery, setisDelivery] = useState("Collection");
  const [sub_total, setSubTotal] = useState(0);
  const [tax_amount, setTaxAmount] = useState(0);
  const [grand_total, setGrandTotal] = useState(0);
  const [delivery_charges, setDeliveryCharge] = useState(1);
  useEffect(() => {
    let taxData = FilterTaxes(items);
    setSubTotal(taxData.sub_total);
    setTaxAmount(taxData.tax_amount);

    setDeliveryCharge(1);
    setGrandTotal(taxData.sub_total + taxData.tax_amount + 1 + 1);
  }, [items?.length, selectedStore]);

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(
      isDelivery == "Delivery" ? DeliverySchema : CollectionSchema
    ),
    defaultValues: initialDeliveryState,
  });
  useEffect(() => {
    setisDelivery(watch("isDelivery"));
  }, [watch("isDelivery")]);

  useEffect(() => {
    setValue(
      "isDelivery",
      `${
        (sessionStorage?.getItem("store_type") || "") == "2"
          ? "Delivery"
          : "Collection"
      }`
    );
    setValue("firstName", user.first_name || "");
    setValue("lastName", user.last_name || "");
  }, []);
  const onSubmitHandler = async (state: any) => {
    if (loader) {
      return;
    }
    dispatch(SETLOADER(true));
    const data: any = {};
    data["address_lat"] = "";
    data["address_long"] = "";
    data["commission"] = "";
    data["complete_address"] = state?.deliveryAddress;
    data["currency"] = "GBP";
    data["delivery_address"] = state?.deliveryAddress;
    data["delivery_charges"] = null;
    data["device_type"] = "Web";
    data["discountAmount"] = delivery_charges;
    data["email"] = "";
    data["final_price"] = grand_total;
    data["first_name"] = state?.firstName;
    data["house_no"] = "";
    data["id"] = "";
    data["ip_address"] = "0.0.0.0";
    data["is_asap"] = 1;
    data["iso_code"] = "GB";
    data["items"] = items;
    data["last_name"] = state?.lastName;
    data["name"] = `${state?.firstName} ${state?.lastName}`;
    data["near_land_mark"] = "";
    data["order_category"] = 1;
    data["order_comments"] = "";
    data["payment_option"] = "cash";
    data["phone"] = "08712345678";
    data["phone_code"] = "+91";
    data["pre_order_status"] = 0;
    data["quantity"] = itemCount;
    data["remaning_amount"] = 0;
    data["selectedDate"] = "Tuesday 27-02-2024";
    data["service_charge"] = service_charge;
    data["store_status"] = 3;
    data["store_type"] = 3;
    data["street_name"] = "";
    data["stripe_id"] = "";
    data["sub_total"] = sub_total;
    data["time"] = "04:11 PM";
    data["total_price"] = grand_total;
    data["zip_code"] = "";
    data["total_tax"] = tax_amount;
    let status = await orderApi(data);

    if (status) {
      setTimeout(() => {
        dispatch(CLEARCART());
        dispatch(CLEARALLORDER());
        sessionStorage.removeItem("items");
      }, 1500);
      navigate("/order-success");
    } else {
    }
    dispatch(SETLOADER(false));
  };
  const [showSideBar, setShowSideBar] = useState(false);
  const handleSideBar = () => {
    !showSideBar ? setShowSideBar(true) : setShowSideBar(false);
  };
  const [openPayment, setOpenPayment] = useState(false);
  const onClosePaymentModal = () => setOpenPayment(false);
  return (
    <>
      {/* <LogoHeader /> */}
      <div className="topheader">
        <div className="pageHeader">
          <Header />
        </div>
      </div>
      <div className="bg-[#F3F5FA] md:pt-20 pt-16 h-screen md:px-20">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="flex gap-3 flex-wrap md:flex-nowrap">
            <div className="md:w-2/3 md:h-[calc(100vh-6rem)] h-auto scroll-hidden overflow-y-auto overflow-x-visible">
              <DeliveryView
                register={register}
                errors={errors}
                isDelivery={watch("isDelivery")}
              />
            </div>

            <div className="md:w-2/6 w-full">
              <CheckoutDetails />
              {items?.length > 0 && (
                <PaymentMethod
                  service_charge={service_charge}
                  sub_total={sub_total}
                  tax_amount={tax_amount}
                  tax={taxes}
                  delivery_charge={delivery_charges}
                  grand_total={grand_total}
                  loader={loader}
                />
              )}
            </div>
          </div>
          <div className="p-2 bg-white fixed bottom-0 w-full md:hidden block">
            {loader ? (
              <LoaderButton message="Please wait" />
            ) : (
              <button
                className="btn-secondary block w-full"
                type="submit"
                // onClick={() => setOpenPayment(true)}
              >
                <span className="textMd font-normal mr-2">
                  {t("Checkout.confirmOrder")}
                </span>{" "}
                {ParseFloat(totalPrice || 0)}
              </button>
            )}
          </div>
        </form>
      </div>
      {/* <ToastContainer /> */}
      {openPayment ? (
        <PaymentModal
          openPayment={openPayment}
          onClosePaymentModal={onClosePaymentModal}
        />
      ) : (
        ""
      )}
    </>
  );
}
