import { interfaceAllChareges } from "../../interface/AllChargesInterface";

export const SET_CART_DATA = "SET_CART_DATA";
export const SET_CART_TOTAL_PRICE = "SET_CART_TOTAL_PRICE";
export const SET_CART_COUNT = "SET_CART_COUNT";
export const CLEAR_CART = "CLEAR_CART";
export const ALL_CHARGES = "ALL_CHARGES";
export const STORE_PICKUP_DATE = "STORE_PICKUP_DATE";
export const STORE_PICKUP_TIME = "STORE_PICKUP_TIME";
export const SETCARTDATA = (data: any) => {
  return {
    type: SET_CART_DATA,
    payload: data,
  };
};

export const STOREPICKUPTIME = (days: string, data: any) => {
  return {
    type: STORE_PICKUP_TIME,
    payload: { days, data },
  };
};
export const STOREPICKUPDATE = (data: any) => {
  return {
    type: STORE_PICKUP_DATE,
    payload: data,
  };
};
export const SETCARTTOTALPRICE = (data: any) => {
  return {
    type: SET_CART_TOTAL_PRICE,
    payload: data,
  };
};
export const SETCARTCOUNT = (data: any) => {
  return {
    type: SET_CART_COUNT,
    payload: data,
  };
};
export const SETALLCHARGES = (data: interfaceAllChareges) => {
  return {
    type: ALL_CHARGES,
    payload: data,
  };
};
export const CLEARCART = () => {
  return {
    type: CLEAR_CART,
    payload: null,
  };
};
const initialState: any = {
  itemCount: 0,
  totalPrice: 0,
  items: [],
  finalPrice: 0,
  remainingOrder: 0,
  discountAmount: 0,
  serviceFee: 0,
  merServiceFee: 0,
  commissionFee: 0,
  storePickupDate: [],
  storePickupTime: {},
  deliveryCharge: 0,
};

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CART_DATA:
      return {
        ...state,
        items: payload,
      };
    case SET_CART_TOTAL_PRICE:
      return {
        ...state,
        totalPrice: payload,
      };
    case SET_CART_COUNT:
      return {
        ...state,
        itemCount: payload,
      };
    case CLEAR_CART:
      sessionStorage.removeItem("items");
      return {
        itemCount: 0,
        totalPrice: 0,
        items: [],
        finalPrice: 0,
        remainingOrder: 0,
        discountAmount: 0,
        serviceFee: 0,
        merServiceFee: 0,
        commissionFee: 0,
        deliveryCharge: 0,
      };
    case ALL_CHARGES:
      return {
        ...state,
        finalPrice: payload.finalPrice,
        remainingOrder: payload.remainingOrder,
        discountAmount: payload.discountAmount,
        serviceFee: payload.serviceFee,
        merServiceFee: payload.merServiceFee,
        commissionFee: payload.commissionFee,
        deliveryCharge: payload.deliveryCharge,
      };
    case STORE_PICKUP_DATE:
      return {
        ...state,
        storePickupDate: payload,
      };
    case STORE_PICKUP_TIME:
      return {
        ...state,
        storePickupTime: {
          ...state.storePickupTime,
          [payload.days]: payload.data,
        },
      };
    default:
      return state;
  }
};

export default reducer;
