import axiosInstance from "../Axios-Config/Axios-Config";
import { ErrorToast } from "../components/ToastComponent/ErrorToast";
const orderApi = async (state: any) => {
  try {
    const { data }: any = await axiosInstance.post("save/order", state);

    if (data.success) {
      // SuccessToast(data.message);
      return true;
    } else {
      ErrorToast(data.message);
      return false;
    }
  } catch (error: any) {
    ErrorToast(error.message);
    return false;
  }
};
export default orderApi;
