const FilterTaxes = (item: any) => {
  let newData = structuredClone(item);

  let tax: any = [];
  let sub_total = 0;
  let tax_amount = 0;
  newData?.map((_: any) => {
    sub_total += Number(_.sub_total || 0);
    _?.tax_rate_list?.map((__: any) => {
      let index = tax.findIndex((i: any) => i.tax_name == __.tax_name);
      let newObj: any = {};
      if (index != -1) {
        newObj = tax[index];
        tax_amount += __.actual_tax_amount;
        newObj["actual_tax_amount"] =
          (newObj["actual_tax_amount"] || 0) + __.actual_tax_amount;
        tax.splice(index, 1, newObj);
      } else {
        tax_amount += __.actual_tax_amount;

        tax.push(__);
      }
    });
  });
  return { tax, sub_total, tax_amount };
};
export default FilterTaxes;
