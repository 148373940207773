import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SETALLCHARGES } from "../redux/features/CartData";
import CustomerServiceCharge from "../utils/CustomerServiceCharge";
import DiscountPrice from "../utils/DiscountPrice";
import FilterTaxes from "../utils/FilterTaxes";
import MerchentServiceCharge from "../utils/MerchentServiceCharge";
import GetRemainingAmount from "../utils/MinOrderFee";

const useAllCharges = () => {
  const {
    cartData: { items, itemCount, totalPrice, deliveryCharge },
    addressData: {
      selectedAddressType,
      selectedAddress: { tempAddress, saveAddress },
    },
    store: { selectedType, selectedStore },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [taxes, setTaxes] = useState({ sub_total: 0, tax: [] });
  const [discount, setDiscount] = useState(0);
  const [service_charge, SetService_charge] = useState(0);
  const [remaining_amount, setRemaining_amount] = useState(0);
  const [final_price, setFinal_price] = useState(0);

  useEffect(() => {
    let tax = FilterTaxes(items);
    let disc = DiscountPrice(tax.sub_total, selectedStore);
    let serv = CustomerServiceCharge(tax.sub_total, selectedStore);
    let rem = GetRemainingAmount(
      tax.sub_total,
      disc,
      selectedStore,
      selectedType
    );
    let deliveryChargeCalculate =
      selectedType == 3
        ? selectedAddressType == 0
          ? tempAddress?.price || 0
          : saveAddress?.price || 0
        : 0;
    console.log(
      "deliveryChargeCalculate",
      deliveryChargeCalculate,
      saveAddress
    );
    let total = totalPrice + serv + rem - disc + deliveryChargeCalculate;
    let merCharge = MerchentServiceCharge(total, selectedStore);

    setTaxes(tax);
    setDiscount(disc);
    SetService_charge(serv);
    setRemaining_amount(rem);
    setFinal_price(total);
    dispatch(
      SETALLCHARGES({
        discountAmount: disc,
        serviceFee: serv,
        commissionFee: serv + merCharge,
        finalPrice: total,
        merServiceFee: merCharge,
        remainingOrder: rem,
        deliveryCharge: deliveryChargeCalculate,
      })
    );
  }, [
    items,
    itemCount,
    totalPrice,
    totalPrice,
    selectedType,
    selectedAddressType,
    tempAddress,
    saveAddress?.price,
  ]);

  return [
    taxes,
    discount,
    service_charge,
    remaining_amount,
    final_price,
    totalPrice,
  ];
};

export default useAllCharges;
