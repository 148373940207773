import axiosInstance from "../Axios-Config/Axios-Config";
import { ErrorToast } from "../components/ToastComponent/ErrorToast";
import { SuccessToast } from "../components/ToastComponent/SuccessToast";
const OTPVerify = async (otp: any) => {
  try {
    const { data }: any = await axiosInstance.post("verify-otp", {
      otp: otp,
      phone_number: sessionStorage.getItem("yop-phone"),
    });
    if (data.success) {
      //   sessionStorage.removeItem("yop-phone");

      SuccessToast(data.message);
    } else {
      ErrorToast(data.message);
    }
    return data;
  } catch (error: any) {
    ErrorToast(error.message);
    return error;
  }
};
export default OTPVerify;
