export const SET_USER_DATA = "SET_USER_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

export const SETUSERDATA = (data: any) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};
export const CLEARUSER = () => {
  return {
    type: CLEAR_USER_DATA,
    payload: {},
  };
};

const initialState: Object = {
  user: {},
};

const reducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_DATA:
      return {
        ...state,
        user: payload,
      };
    case CLEAR_USER_DATA:
      return {
        ...state,
        user: {},
      };

    default:
      return state;
  }
};

export default reducer;
