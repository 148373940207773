function addressFactor(value: any){
    let temp=value?.split(',');
    return {
        state:temp?.at(-1),
        city:temp?.at(-2),
        street:temp?.at(-3)||'',
        full_address:value
    }
}

export default addressFactor