export default function StepTitle(props: any) {
  return (
    <>
      <div className="titleHeader flex justify-between">
        {props.stepTitle && (
          <h4
            className={`${
              props.err
                ? "rounded-[4px] border border-[#F00] bg-[#FFE9E9] text-[#FF0000] p-2 inline-block"
                : ""
            } textMd font-bold mb-2`}
          >
            {props.err && <i className="fa fa-exclamation-circle mr-1"></i>}
            {props.stepTitle} {props.required && <span className="">*</span>}
          </h4>
        )}

        <p
          className={`textMd primary ${
            props.success
              ? "text-emerald-400"
              : props.err
              ? "text-rose-500"
              : ""
          } `}
        >
          Required
        </p>
      </div>
    </>
  );
}
