import distanceCalculate from "../api/DistanceCalculator";
import { GetPostCodeData } from "../api/GetPostCodeData";
import {
  CheckDeliveryAvailable,
  CheckDeliveryPrice,
} from "./CheckDeliveryAvailable";

export async function DeliveryPriceAndOrderFee(postCode: any, store: any) {
  try {
    const data = await GetPostCodeData(postCode);
    if (data.success) {
      if (data?.special_post_code) {
      } else if (data?.matched_data?.length) {
      } else {
        const distance = await distanceCalculate(
          `${data?.data?.latitude},${data?.data?.longitude}`,
          `${store?.lattitude},${store?.longitude}`
        );
        if (distance?.dis != null && CheckDeliveryAvailable(store))
          console.log(
            "CheckDeliveryPrice",
            CheckDeliveryPrice(distance.dis || 0, store)
          );
        return {
          status: true,
          distance: distance.dis,
          price: CheckDeliveryPrice(distance.dis || 0, store),
          address: data?.data?.address || [],
          latitude: data?.data?.latitude,
          longitude: data?.data?.longitude,
          zip_code: data?.data?.postcode,
        };
      }
    } else {
      return { success: false, message: data.message };
    }
  } catch (error) {}
}
