import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GetAllOrderApi from "../../../api/allOrderApi";
import {
  SETALLORDERDATA,
  SETORDERDETAIL,
} from "../../../redux/features/AllOrder";
import AddOnCalculate from "../../../utils/AddOn";
import { OrderStatusType } from "../../../utils/OrderStatusType";
import ParseFloat from "../../../utils/ParseFloat";
import CurrentOrderSkelton from "./CurrentOrderSkelton";
import NoOrderFound from "./NoOrderFound";

export default function CurrentOrder() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orders } = useSelector((state: any) => state?.allOrder);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!orders.length) {
      getOrder();
    }
  }, []);
  async function getOrder() {
    setLoading(true);
    const { order_list } = await GetAllOrderApi();

    dispatch(SETALLORDERDATA(order_list));
    setLoading(false);
  }

  const handleOrderDetail = (item: any) => {
    dispatch(SETORDERDETAIL(item));
    navigate(`/order-detail/${item.order_id}`);
  };
  return (
    <>
      <div className="mb-3">
        {loading ? (
          <CurrentOrderSkelton />
        ) : orders && orders?.length > 0 ? (
          orders.map((order: any) => (
            <div
              className="md:border border-b border-[#E0E0E0] md:rounded-xl overflow-hidden mb-3"
              key={order.order_id}
              onClick={() => handleOrderDetail(order)}
            >
              <div className="md:bg-[#F8F8F8] py-2 px-4">
                <div className="flex md:flex-row flex-col gap-2 ">
                  <div className="w-full flex gap-2">
                    <div className="rounded-md overflow-hidden md:h-auto h-16 md:w-[6rem] w-16 relative">
                      <img
                        src={`${order?.store_data?.[0]?.store_image?.base_url}${order?.store_data?.[0]?.store_image?.attachment_url}`}
                        className="size-full mix-blend-multiply"
                        alt={order?.store_name}
                      />
                    </div>
                    <div>
                      <h4 className="text-lg font-bold text-black">
                        {order?.store_name}
                      </h4>
                      <p className="text-[#828282] textSm font-normal">
                        {order?.store_data[0].description}
                      </p>
                      <div className="flex  textSm font-normal gap-1 items-center mb-2">
                        <i className="fa fa-star text-yellow-500"></i>
                        <p>
                          4.9{" "}
                          <span className="text-[#828282]">(2000 ratings)</span>
                        </p>
                      </div>
                      <div className="md:flex hidden justify-between">
                        <p className="flex  textSm font-normal gap-1 items-center mb-2">
                          <img
                            src="/images/location-icon.svg"
                            className="size-5"
                          />
                          {order?.store_data[0]?.address}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="md:hidden flex justify-between ">
                    <p className="flex  textSm font-normal gap-1 items-start mb-2">
                      <img src="/images/location-icon.svg" className="size-5" />
                      {order?.store_data[0]?.address}
                    </p>
                  </div>
                  <div className=" flex md:flex-col flex-row-reverse justify-between">
                    <h4 className="textMd font-bold text-[#1466FA] text-right whitespace-nowrap">
                      {order?.order_id}
                    </h4>
                    <p className="textSm font-bold text-[#30C53E] text-right">
                      {OrderStatusType(order?.order_status)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="py-2 px-4">
                {order?.orderlist?.map((item: any) => {
                  let addons = AddOnCalculate(item);
                  return (
                    <div key={item.item_name}>
                      <div className="grid grid-cols-3">
                        <div className="flexBox gap-2 items-center">
                          <h4 className="textMd  font-bold">
                            {item?.item_name}
                          </h4>
                        </div>
                        <div className="itemSize textMd font-bold text-center">
                          {item?.quantity || 1}X
                        </div>
                        <div className="Price textMd font-bold  text-right">
                          {ParseFloat(item?.price * (item.quantity || 1))}
                        </div>
                      </div>
                      {addons?.addonsItem ? (
                        <div className="grid grid-cols-3 text-[#868686] textSm my-2">
                          <div className="flex gap-2 items-center capitalize">
                            <i className="fa fa-circle text-[3px]"></i>
                            <p>{addons?.addonsItem || "No Sub Items Added"}</p>
                          </div>
                          <p className="text-center"></p>
                          <p className="text-right text-black">
                            {ParseFloat(addons.addonsPrice)}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
                <div className="flex justify-between md:flex-row flex-col items-center gap-3">
                  <p className="text-black textMd font-bold">
                    {t("MyOrders.orderPlaceOn")}{" "}
                    {moment(order?.updated_at).format("lll")}
                  </p>
                  <button className="btn-secondary md:w-auto w-full ">
                    {t("MyOrders.reOrder")}
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoOrderFound content={t("MyOrders.noOrders")} />
        )}
      </div>
    </>
  );
}
