import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  SETSELECTEDADDRESSType,
  SETTEMPADDRESS,
} from "../../redux/features/address";
import addressFactor from "../../utils/AddressFactor";
import { DeliveryPriceAndOrderFee } from "../../utils/DeliveryPriceAndOrderFee";
import InputText from "../FormFields/InputText";
import SavedAddress from "./Address/SavedAddress";

const AddressSelection = ({ register }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    addressData: {
      selectedAddressType,
      selectedAddress: { tempAddress },
    },
    store: { selectedStore },
  } = useSelector((state: any) => state);
  const [search, setSearch] = useState(tempAddress?.zip_code || "");
  const [instruction, setInstruction] = useState(false);
  const [manualAddress, setManualAddress] = useState(false);
  const handleInstruction = () => {
    !instruction ? setInstruction(true) : setInstruction(false);
  };
  const handleManualAddress = () => {
    !manualAddress ? setManualAddress(true) : setManualAddress(false);
  };
  const handleChangeStore = (index: number) => {
    dispatch(SETSELECTEDADDRESSType(index));
  };

  const handlePostCode = async () => {
    const data = await DeliveryPriceAndOrderFee(search, selectedStore);
    if (data?.status) {
      console.log("data", data);
      dispatch(SETTEMPADDRESS(data));
    }
  };
  const onHandleDropDownCLick = (e: any) => {
    const data = addressFactor(e.address);
    console.log("address", data);
  };
  return (
    <>
      <Tabs
        selectedIndex={selectedAddressType}
        className="address-container"
        onSelect={handleChangeStore}
      >
        <TabList className="tab-list mb-4">
          <Tab>Choose a new address</Tab>
          <Tab>Choose Saved address</Tab>
        </TabList>

        <TabPanel className=" ">
          <div className="border border-[#C5C5C5] radius flex mb-3">
            <input
              className="formControl !border-none"
              placeholder="Search for a postcode"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              onClick={handlePostCode}
              className="radius flex flex-nowrap whitespace-nowrap items-center gap-1 textSm text-white bgBlue px-10 font-semibold"
              type="button"
            >
              <i className="fa fa-search"></i>
              Find Address
            </button>
          </div>
          <div>
            {tempAddress?.address?.length ? (
              <Select
                onChange={onHandleDropDownCLick}
                className="basic-single "
                //           classNamePrefix="select"
                isSearchable={true}
                // defaultValue={{ ...saveAddress, label: saveAddress.full_address }}
                options={tempAddress?.address?.map((item: any) => ({
                  ...item,
                  label: item.address,
                }))}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: `13px`,
                    fontSize: `14px`,
                  }),
                  option: (base) => ({
                    ...base,
                    backgroundColor: `white`,
                    color: `black`,
                    fontSize: `14px`,
                  }),
                }}
              />
            ) : (
              ""
            )}
          </div>
          {manualAddress && (
            <div className="mt-3">
              <div className="grid md:grid-cols-1 md:gap-4">
                <div className="formItem">
                  <InputText
                    register={register}
                    name="fullAddress"
                    placeholder="Enter Your Full Address"
                  />
                  <div className="errorField"></div>
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-4">
                <div className="formItem">
                  <InputText
                    register={register}
                    name="houseNo"
                    placeholder="Enter Your House No."
                  />
                  <div className="errorField"></div>
                </div>
                <div className="formItem">
                  <InputText
                    register={register}
                    name="streetName"
                    placeholder="Enter Your Street Name"
                  />
                  <div className="errorField"></div>
                </div>
                {/* <div className="formItem">
                  <InputText
                    register={register}
                    name="zipcode"
                    placeholder="Enter Your Zip Code "
                  />
                  <div className="errorField"></div>
                </div> */}
                {/* <div className="formItem">
                  <InputText
                    register={register}
                    name="country"
                    placeholder="Enter Your Country"
                  />
                  <div className="errorField"></div>
                </div> */}
              </div>
              <div className="grid md:grid-cols-2 md:gap-4">
                {/* <div className="formItem">
                  <InputText
                    register={register}
                    name="state"
                    placeholder="State"
                  />
                  <div className="errorField"></div>
                </div> */}
                {/* <div className="formItem">
                  <InputText
                    register={register}
                    name="district"
                    placeholder="Enter Your District"
                  />
                  <div className="errorField"></div>
                </div> */}
              </div>
              <div className="grid md:grid-cols-2 md:gap-4">
                {/* <div className="formItem">
                  <InputText
                    register={register}
                    name="city"
                    placeholder="Enter Your City"
                  />
                  <div className="errorField"></div>
                </div> */}
                {/* <div className="formItem">
                  <InputText
                    register={register}
                    name="zipcode"
                    placeholder="Enter Your Zip Code "
                  />
                  <div className="errorField"></div>
                </div> */}
              </div>
              {/* <div className="formItem radio">
                <p className="textMd text-[#828282] mb-2">Select Addres type</p>
                <div className="flex gap-4 md:flex-row flex-col md:items-center">
                  <fieldset className="flex md:gap-3 gap-2" id="addressType">
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        id="home"
                        value="home"
                        name="addressType"
                      />
                      <label htmlFor="home">{t("MyAddress.home")}</label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        id="office"
                        value="office"
                        name="addressType"
                      />
                      <label htmlFor="office">{t("MyAddress.office")}</label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input
                        type="radio"
                        id="other"
                        value="other"
                        name="addressType"
                      />
                      <label htmlFor="other">{t("MyAddress.other")}</label>
                    </div>
                  </fieldset>
                  <button className="btn-secondary" type="button">
                    Save Address
                  </button>
                </div>
              </div> */}
            </div>
          )}

          <p className="textSm mt-3">
            Can't find your address{" "}
            <span
              className="textBlue underline cursor-pointer ml-1"
              onClick={handleManualAddress}
            >
              Enter address manually
            </span>
          </p>
          <p
            className="textSm mt-3 textBlue cursor-pointer"
            onClick={handleInstruction}
          >
            + Add instruction
          </p>
          {instruction && (
            <textarea
              className="border border-[#C5C5C5] radius px-4 py-3 textSm w-full mt-3"
              rows={4}
              placeholder="Instruction for delivery partner"
            ></textarea>
          )}
        </TabPanel>
        <TabPanel className=" ">
          <SavedAddress />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default AddressSelection;
