import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SETSELECTEDCATEGORY } from "../../redux/features/categoryList";
import { useNavigate } from "react-router-dom";
import SideBarSkelton from "../Sidebar/SideBarSkelton";
import NoInternet from "../Modal/NoInternet";

const MobileMenuPopup = ({
  menuOpen,
  onCloseModal,
}: {
  menuOpen: boolean;
  onCloseModal: any;
}) => {
  const {store} = useSelector((store:any) => store)
  
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const goToViolation = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const {
    categoryData: { category, selectedCategory },
    store: { selectedStore, allCategory },
  } : any = useSelector((state: any) => state);

  const onHandleChangeCategory = (slug : string) =>{
    dispatch(SETSELECTEDCATEGORY(slug))
    goToViolation(slug);
    navigate(`?slug=${slug}`);
    onCloseModal();
  }

  return (

    // This map is for Main menu pop up 
    <>
      <div className="absolute bottom-[9.5rem] right-5 h-[400px] w-[200px] rounded-lg z-50">
      <div className="bg-white rounded-lg h-full p-3 overflow-y-auto scroll-hidden">
        <ul>
          {/* <li className=" mb-1 font-bold text-[#34558B]">Thali</li>
          {store?.allCategory.map((item : any) =>(
          <li key={item.slug} className=" mb-1 font-medium">{item.slug}</li>
          ))} */}
          {loading ? (
            <SideBarSkelton />
          ) : (
            allCategory?.map((value : any) =>{
              return(
                <li   className={` menu-popup-item ${
                  value?.slug == selectedCategory ? "active" : ""
                }`}
                onClick={()=>onHandleChangeCategory(value?.slug)}
                key={value?.id || value?.created_at} 


                >
                  {/* {value.slug} */}
                  <button type="button" className=" navBtn">
                        {value?.name}
                      </button>
                  </li>
              )
            })
          )}
   
        </ul>
      </div>
      <div
        className="bg-white border-2 border-[#34558B] rounded-[12px] h-[4.5rem] w-[4.5rem] flex justify-center items-center ml-auto mt-4"
        onClick={onCloseModal}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
        >
          <path
            d="M2.86442 0.802246L0.802246 2.86442L11.4378 13.5L0.802246 24.1356L2.86442 26.1978L13.5 15.5622L24.1356 26.1978L26.1978 24.1356L15.5622 13.5L26.1978 2.86442L24.1356 0.802246L13.5 11.4378L2.86442 0.802246Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
    {!isOnline && <NoInternet isOnline={isOnline} />}

    </>
    
  );
};

export default MobileMenuPopup;
