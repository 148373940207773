import { AsyncImage } from "loadable-image";
import Modal from "react-modal";
import { Blur } from "transitions-kit";

const InfoModal = ({
  item,
  isInfo,
  setIsInfo,
}: {
  item: any;
  isInfo: boolean;
  setIsInfo: Function;
}) => {
  return (
    <Modal
      ariaHideApp={false}
      className="confirmation"
      isOpen={isInfo}
      contentLabel=""
    >
      <div className="modalHeader !mb-0 !p-4">
        <div className="headerTitle">
          <h3 className="text-base">{item?.name}</h3>
        </div>
        <button className="close" onClick={() => setIsInfo(!isInfo)}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.0076 4.29736C12.5719 4.29736 4.08168 12.7876 4.08168 23.2233C4.08168 33.6589 12.5719 42.1491 23.0076 42.1491C33.4432 42.1491 41.9335 33.6589 41.9335 23.2233C41.9335 12.7876 33.4432 4.29736 23.0076 4.29736ZM23.0076 7.13625C31.909 7.13625 39.0946 14.3218 39.0946 23.2233C39.0946 32.1247 31.909 39.3103 23.0076 39.3103C14.1061 39.3103 6.92056 32.1247 6.92056 23.2233C6.92056 14.3218 14.1061 7.13625 23.0076 7.13625ZM29.1455 15.6326C28.7707 15.6414 28.4145 15.7982 28.1549 16.0688L23.0076 21.2161L17.8602 16.0688C17.7279 15.9325 17.5696 15.8242 17.3947 15.7502C17.2198 15.6762 17.0318 15.6381 16.8419 15.6381C16.5594 15.6382 16.2833 15.7225 16.0491 15.8804C15.8148 16.0382 15.6329 16.2623 15.5267 16.5241C15.4205 16.7859 15.3949 17.0734 15.453 17.3498C15.5112 17.6263 15.6504 17.8791 15.8531 18.0759L21.0004 23.2233L15.8531 28.3706C15.7168 28.5014 15.6081 28.658 15.5332 28.8314C15.4582 29.0047 15.4187 29.1913 15.4167 29.3801C15.4148 29.569 15.4506 29.7563 15.522 29.9312C15.5934 30.106 15.6989 30.2648 15.8325 30.3984C15.966 30.5319 16.1248 30.6375 16.2997 30.7088C16.4745 30.7802 16.6618 30.816 16.8507 30.8141C17.0395 30.8122 17.2261 30.7726 17.3994 30.6977C17.5728 30.6227 17.7295 30.514 17.8602 30.3778L23.0076 25.2304L28.1549 30.3778C28.2857 30.514 28.4424 30.6227 28.6157 30.6977C28.7891 30.7726 28.9756 30.8122 29.1645 30.8141C29.3533 30.816 29.5406 30.7802 29.7155 30.7088C29.8903 30.6375 30.0492 30.5319 30.1827 30.3984C30.3162 30.2648 30.4218 30.106 30.4932 29.9312C30.5645 29.7563 30.6003 29.569 30.5984 29.3801C30.5965 29.1913 30.5569 29.0047 30.482 28.8314C30.4071 28.658 30.2983 28.5014 30.1621 28.3706L25.0148 23.2233L30.1621 18.0759C30.3685 17.8778 30.5102 17.622 30.5687 17.3419C30.6272 17.0618 30.5997 16.7706 30.4898 16.5065C30.3799 16.2423 30.1927 16.0175 29.9528 15.8616C29.713 15.7056 29.4316 15.6258 29.1455 15.6326Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <div className="p-8">
        <div className="card card--block ">
          {item?.item_attachment?.attachment_url && (
            <AsyncImage
              key={`${item?.item_attachment?.base_url}${item?.item_attachment?.attachment_url}`}
              src={`${item?.item_attachment?.base_url}${item?.item_attachment?.attachment_url}`}
              Transition={Blur}
              loader={<div style={{ background: "#d3d3d3" }} />}
            />
          )}
        </div>
        <div className="card card--block ">
          <h3 className="text-[#676767] textMd">{item?.description}</h3>
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
