import React from "react";

const HeaderSearch = () => {
  return (
    <div className="md:flex gap-2 items-center textMd bg-[#E6E9EF] text-[#6A6A6A] border border-[#E6E9EF] rounded-[10px] px-3 py-2 min-w-60 hidden ">
      <i className="fa fa-search"></i>
      <input
        type="text"
        placeholder="Search"
        className="outline-none border-none bg-transparent"
      />
    </div>
  );
};

export default HeaderSearch;
