import axiosInstance from "../Axios-Config/Axios-Config";

async function distanceCalculate(destination: any, origin: any) {
  try {
    const {
      data: { routes, status },
    } = await axiosInstance.get(`get/address/by/${destination}/${origin}`);
    if (routes?.[0]?.legs) {
      return {
        dis:
          Number(routes?.[0]?.legs?.[0]?.distance?.value / 1000) * 0.621371 ||
          0,
        status: status,
      };
    } else {
      return {
        dis: 0,
        status: status,
      };
    }
  } catch (error) {
    return {
      status: "ZERO_RESULTS",
    };
  }
}
export default distanceCalculate;
