const IndivisualQuantity = (items: any, item_id: number) => {
  let remaining = items?.filter((data: any) => data.item_id == item_id);
  if (remaining?.length == 0) {
    return 0;
  }
  let total = 0;
  remaining.map((item: any) => {
    total += item.quantity || 1;
  });
  return total;
};
export default IndivisualQuantity;
