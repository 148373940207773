import { FindOption } from "../FindOption";
interface GetStepOptionIdInterFace {
  stepOptionId: any;
  selectedVariation: any;
}
const GetStepOptionId = ({
  stepOptionId,
  selectedVariation,
}: GetStepOptionIdInterFace) => {
  return stepOptionId?.map((step: any) => ({
    attachment_id: step?.attachment_id,
    created_at: step?.created_at,
    group_step_id: step?.group_step_id,
    is_option: step?.is_option,
    item_id: step?.item_id,
    item_step_option_id: step?.item_step_option_id,
    option_price: step?.option_price,
    position: step?.position,
    price: step?.option_price?.length
      ? Number(
          FindOption(step?.option_price, selectedVariation?.variation_id)
            ?.price || 0
        )
      : step?.price || 0,
    short_description: step?.short_description,
    step_attachment: step?.step_attachment,
    step_id: step?.step_id,
    title: step?.title,
    updated_at: step?.updated_at,
  }));
};
export default GetStepOptionId;
