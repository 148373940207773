const StripePayment = () => {
  return (
    <>
      <div className="p-5">
        <p className="text-base font-bold mb-3">Choose a Payment Option</p>
        <div className="flex gap-2 mb-3 flex-wrap">
          <div className="choose-payment ">
            <input type="radio" className="" id="card" name="variation" />
            <label htmlFor="card">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 34 34"
                fill="none"
              >
                <path
                  d="M5.7098 5.92499C4.17109 5.92499 2.9248 7.17128 2.9248 8.70999V10.1025H30.7748V8.70999C30.7748 7.17128 29.5285 5.92499 27.9898 5.92499H5.7098ZM2.9248 14.28V25.42C2.9248 26.9587 4.17109 28.205 5.7098 28.205H27.9898C29.5285 28.205 30.7748 26.9587 30.7748 25.42V14.28H2.9248ZM21.0273 18.4575C21.537 18.4575 22.009 18.6056 22.4198 18.8437C22.8306 18.6056 23.3027 18.4575 23.8123 18.4575C25.351 18.4575 26.5973 19.7038 26.5973 21.2425C26.5973 22.7812 25.351 24.0275 23.8123 24.0275C23.3027 24.0275 22.8306 23.8794 22.4198 23.6413C22.009 23.8794 21.537 24.0275 21.0273 24.0275C19.4886 24.0275 18.2423 22.7812 18.2423 21.2425C18.2423 19.7038 19.4886 18.4575 21.0273 18.4575ZM22.4198 23.6413C22.7891 23.6413 23.1432 23.3886 23.4044 22.9387C23.6655 22.4889 23.8122 21.8787 23.8123 21.2425C23.8122 20.6062 23.6655 19.9961 23.4044 19.5463C23.1432 19.0964 22.7891 18.8437 22.4198 18.8437C22.0505 18.8437 21.6964 19.0964 21.4353 19.5463C21.1741 19.9961 21.0274 20.6062 21.0273 21.2425C21.0274 21.8787 21.1741 22.4889 21.4353 22.9387C21.6964 23.3886 22.0505 23.6413 22.4198 23.6413Z"
                  fill="#777777"
                />
              </svg>
              Card
            </label>
          </div>
          <div className="choose-payment ">
            <input type="radio" className="" id="gpay" name="variation" />
            <label htmlFor="gpay">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="35"
                viewBox="0 0 34 35"
                fill="none"
              >
                <g clip-path="url(#clip0_7381_5867)">
                  <path
                    d="M12.8289 18.0093H7.07504V20.1009H9.86282C9.63306 20.5813 9.30304 21.0004 8.90061 21.3402L8.902 21.3416C8.26563 21.8735 7.44684 22.1924 6.55285 22.1924C5.003 22.1924 3.67734 21.2288 3.14401 19.8669C2.98109 19.4519 2.89197 19.0022 2.89197 18.5315C2.89197 17.7726 3.12313 17.0694 3.5172 16.4845L3.58544 16.394C4.24827 15.4694 5.32745 14.8706 6.55146 14.8706C7.529 14.8706 8.4188 15.2522 9.07189 15.8802L10.8181 13.9293C9.70129 12.8947 8.20018 12.2569 6.55285 12.2569C4.38752 12.2569 2.4784 13.3556 1.34908 15.0238C0.672325 16.0264 0.276855 17.2337 0.276855 18.5329C0.276855 19.5633 0.52472 20.5353 0.96475 21.3931C2.00495 23.4206 4.11598 24.8089 6.55146 24.8089C8.22385 24.8089 9.74307 24.1558 10.8682 23.0892L10.8668 23.0878C12.0741 21.9487 12.8275 20.3264 12.8275 18.5343V18.0093H12.8289ZM29.6099 22.3414L27.141 15.8551H28.6407L30.327 20.7915L32.3949 15.8551H33.6969C33.6969 15.8551 29.6586 25.3826 29.6252 25.462L28.3232 25.4954C28.3469 25.4411 29.6099 22.3414 29.6099 22.3414ZM25.3864 19.8335C25.3864 20.637 24.679 21.2093 23.7433 21.2093C23.008 21.2093 22.5388 20.8681 22.5388 20.3459C22.5388 19.807 22.9899 19.4937 23.8519 19.4436L25.3906 19.3308L25.3864 19.8335ZM24.1095 15.8551C22.6432 15.8551 21.5584 16.6642 21.5181 17.7754H22.7616C22.8646 17.2462 23.3715 16.9009 24.0691 16.9009C24.913 16.9009 25.3906 17.2796 25.3906 17.9801V18.4633L23.6639 18.5524C22.0611 18.6457 21.1936 19.2793 21.1936 20.3793C21.1936 21.4906 22.089 22.2286 23.3729 22.2286C24.2404 22.2286 25.0453 21.8053 25.4101 21.1341H25.4379L25.4421 22.2286H26.7163V17.8923C26.7163 16.653 25.6886 15.8551 24.1095 15.8551ZM17.7945 17.9787H16.0943V14.581H17.7945C19.0074 14.581 19.7858 15.1533 19.7858 16.2812C19.7858 17.4091 19.0129 17.9787 17.7945 17.9787ZM18.2011 13.3068H14.8201V22.2286H16.0943V19.2542H18.1538C19.9222 19.2542 21.1922 18.0205 21.1922 16.2798C21.1922 14.5392 19.9459 13.3068 18.2011 13.3068Z"
                    fill="#777777"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7381_5867">
                    <rect
                      width="33.42"
                      height="33.42"
                      fill="white"
                      transform="translate(0.304688 0.962402)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Google Pay
            </label>
          </div>
          <div className="choose-payment ">
            <input type="radio" className="" id="upi" name="variation" />
            <label htmlFor="upi">
              <img src="/images/upi-icon.png" />
              UPI
            </label>
          </div>
        </div>
        <div className="mb-3">
          <label className="mb-1 font-semibold">Card Number</label>
          <input
            className="border border-[#E7E7E7]  rounded-lg p-2 w-full"
            placeholder="123456"
          />
        </div>
        <div className="grid grid-cols-2 gap-3 mb-3">
          <div className="">
            <label className="mb-1 font-semibold">Expiration date</label>
            <input
              type="calender"
              className="border border-[#E7E7E7]  rounded-lg p-2 w-full"
              placeholder="MM / YY"
            />
          </div>
          <div className="">
            <label className="mb-1 font-semibold">Security code</label>
            <input
              className="border border-[#E7E7E7]  rounded-lg p-2 w-full"
              placeholder="CVC"
            />
          </div>
        </div>
      </div>
      <div className="w-full md:static fixed bottom-0 p-3 md:px-5 md:pt-0">
        <button className="btn-secondary w-full ">
          <span className="textMd font-normal mr-2">Confirm & Pay</span>{" "}
          ₹1702.07
        </button>
      </div>
    </>
  );
};

export default StripePayment;
