import ParseFloat from "../../../utils/ParseFloat";

export default function ItemOption(props: any) {
  return (
    <>
      <input
        type="checkbox"
        defaultChecked={props?.defaultChecked}
        id={props.id}
        name={props.name}
        onChange={props.onchange}
      />
      <label htmlFor={props.id}>
        {props.itemName && <span>{props.itemName} </span>}
        {props.price > 0 && <span> {ParseFloat(props.price)}</span>}
      </label>

      {/* <div className="chooseVariation">
        <input
          type="checkbox"
          defaultChecked={props?.defaultChecked}
          id={props.id}
          name={props.name}
          onChange={props.onchange}
        />
        <label htmlFor={props.id}>
          <div className="variation_option">
            <div className="flexBox justifyBetween">
              {props.itemName && <p>{props.itemName}</p>}
              {props.price > 0 && <p>{ParseFloat(props.price)}</p>}
            </div>
          </div>
        </label>
      </div> */}
    </>
  );
}
