import { object, string } from 'yup';
export interface interfaceForgotPasswordState {
    password: string;
    confirmPassword: string
}
export const forgotPasswordSchema = object().shape({
    password: string()
        .required("Password is required")
        .min(4, () => "Password must be at lease 4 characters"),
    confirmPassword: string()
        .required("Confirm Password is required"),
});
export const initialForgotPasswordState = {
    password: '',
    confirmPassword: ''
}
