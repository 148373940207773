import { useTranslation } from "react-i18next";

const PageNotFound = () => {
    const { t } = useTranslation();
    return (
        <div className="pageNotFOund">
            <div className="iconBlock">
                <i className="fa fa-question-circle" aria-hidden="true"></i>
            </div>
            <h2 className="mt-4 mb-4">{t('NotFound.line1')}</h2>
            <p>{t('NotFound.line2')}</p>
        </div>
    )
}

export default PageNotFound