export const SET_CATEGORY_ITEM = "SET_CATEGORY_ITEM";



export const SETCATEGORYITEM = (data:any) => {
  return {
    type: SET_CATEGORY_ITEM,
    payload: data,
  };
};

const initialState:Object = {}


const reducer = (state = initialState, action:any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CATEGORY_ITEM:
      return {
        ...state,
        ...payload,
      };
     

    default:
      return state;
  }
};

export default reducer;
