import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SETCUSTOMIZEDDATA } from "../../redux/features/CustomizedData";
import { SETVARIATIONDATA } from "../../redux/features/Variation";
import KitchenCard from "../Home/KitchenCard";
import AddressSelection from "./AddressSelection";
import PickupView from "./PickupView";

export default function DeliveryView(props: any) {
  const { t } = useTranslation();
  const {
    store: { selectedStore, selectedType },
    categoryData: { selectedCategory },
  } = useSelector((state: any) => state);

  const [addCard, setAddCard] = useState(false);
  const disPatch = useDispatch();
  const [open, setOpen] = useState(false);
  const onCloseModal = () => setOpen(false);

  const handleEditItem = (data: any) => {
    if (
      data?.item_variation?.length > 0 &&
      data?.variation_status != 0 &&
      data?.customize_status == 0
    ) {
      data["isEdit"] = true;
      disPatch(SETVARIATIONDATA(data));
    } else if (data?.customize_status === 0) {
    } else {
      data["isEdit"] = true;
      disPatch(SETCUSTOMIZEDDATA(data));
    }
    setOpen(true);
    // navigation("/menu");
  };

  return (
    <div>
      <KitchenCard
        kitchenName={selectedStore?.store_name || ""}
        kitchenDistance="35.07 mi away"
        kitchenAddress={selectedStore?.address || ""}
        kitchenStatus={2}
        store=""
        className="checkout border-b border-b-[#E9E9E9] md:border-none"
      />
      {selectedType == 3 ? (
        <div className="store-card checkout">
          <p className="text-base font-bold mb-3">Delivery Address</p>
          <AddressSelection register={props.register} />
        </div>
      ) : (
        <PickupView />
      )}

      {/* <div className="store-card flex justify-between items-center">
        <div className="flex gap-3 items-center">
          <img src="/images/phone-icon.svg" className="size-6" />
          <div>
            <p className="textMd text-[#676767]">Contact</p>
            <p className="textMd text-black font-bold">
              First name, 987385893
            </p>
          </div>
        </div>
        <i className="fa fa-chevron-right"></i>
      </div> */}
      {/* <button className="btn-secondary block w-full">
        <span className="textMd font-normal mr-2">Confirm & Pay</span>{" "}
        300.00
      </button> */}
    </div>
  );
}
