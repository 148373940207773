export const AddOnCalculate = (addOn: any) => {
  let addonsItem: any = [];
  let addonsPrice = 0;
  addOn?.addon_data?.map((item: any) => {
    addonsItem.push(item.item_name);
    addonsPrice += item.price || 0;
  });
  addOn?.order_item_step?.map((item: any) => {
    addonsPrice += item.price || 0;
    addonsItem.push(item?.option_title);
  });
  addOn?.order_item_sub_categories?.map((item: any) => {
    addonsPrice += item.web_price || 0;
    addonsItem.push(item.name);
  });

  return { addonsItem: addonsItem.join(","), addonsPrice };
};
export default AddOnCalculate;
