import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../components/Buttons/ButtonBlue";
import InputText from "../../components/FormFields/InputText";
import LabelText from "../../components/FormLabels/LabelText";
import Logo from "../../components/FormLogo/Logo";
import {
  forgotPasswordSchema,
  initialForgotPasswordState,
  interfaceForgotPasswordState,
} from "./ForgotPasswordValidation";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: initialForgotPasswordState,
  });
  const onSubmitHandler = async (data: interfaceForgotPasswordState) => {};
  return (
    <>
      <div className="userForm loginPage">
        <form className="formGroup" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="formTitle">
            <Logo />
          </div>
          <div className="formItem">
            <LabelText text={t("Forgot.newPassword")} />
            <InputText
              name="password"
              register={register}
              placeholder={t("Forgot.enterNewPassword")}
            />
          </div>
          <div className="formItem">
            <LabelText text={t("Forgot.confirmPassword")} />
            <InputText
              placeholder={t("Forgot.enterNewPassword")}
              name="confirmPassword"
              register={register}
            />
          </div>
          <div className="btnAction">
            <ButtonBlue buttonText={t("Forgot.submit")} />
          </div>
        </form>
      </div>
    </>
  );
}
