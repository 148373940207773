import { ToastContainer } from "react-toastify";
import OtpView from "../../components/Otp/OtpView";
import MainWrapper from "../../Wrapper/MainWrapper";
export default function Otp() {
  return (
    <>
    <ToastContainer />
      <MainWrapper>
        <OtpView />
      </MainWrapper>
    </>
  );
}
