import React from "react";

export default function SectionTitle({
  sectionTitle,
}: {
  sectionTitle?: string;
}) {
  return (
    <>
      <h2 className="secTitle mb-2">{sectionTitle}</h2>
    </>
  );
}
