import { createBrowserRouter } from "react-router-dom";
import ProtectedRouter from "./ProtectedRouter";
import WithoutProtectdRouter from "./WithoutProtectedRouter";

const router = createBrowserRouter([
  ...ProtectedRouter,
  ...WithoutProtectdRouter,
]);

export default router;
