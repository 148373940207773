import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, Tabs } from "react-tabs";
import GetStore from "../../api/storeApi";
import {
  SETDOMAINSETTING,
  SETSTORECOLLECTIONDATA,
  SETSTOREDELIVERYDATA,
  SETSTORETYPE,
} from "../../redux/features/store";
const Store = React.lazy(() => import("./Store"));
const StoreSkelton = React.lazy(() => import("./StoreSkelton"));
const NoStoreFound = React.lazy(() => import("../NoStoreFound/NoStoreFound"));

export default function TabSelectionMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {
    store: { collection, delivery, selectedType },
  }: any = useSelector((state) => state);
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(success, error);
  //   sessionStorage.setItem("store_type", "3");
  //   function success(positions: any) {
  //     const search = {
  //       search_item: "",
  //       longitude: positions.coords.longitude,
  //       latitude: positions.coords.latitude,
  //       store_type: 3,
  //     };
  //     sessionStorage.setItem("longitude", positions.coords.longitude);
  //     sessionStorage.setItem("latitude", positions.coords.latitude);

  //     getLatLongToAddress(search.latitude, search.longitude, dispatch);

  //     if (delivery?.length == 0) {
  //       getStoreData(search);
  //     } else {
  //       setLoading(false);
  //     }
  //   }
  //   function error(err: any) {
  //     sessionStorage.removeItem("longitude");
  //     sessionStorage.removeItem("latitude");

  //     const search = {
  //       search_item: "",
  //       longitude: "",
  //       latitude: "",
  //       store_type: 3,
  //     };
  //     if (delivery?.length == 0) {
  //       getStoreData(search);
  //     } else {
  //       setLoading(false);
  //     }
  //   }
  // }, []);

  const getStoreData = async (param: any) => {
    setLoading(true);
    const { store, loading, domain_setting } = await GetStore(param);
    sessionStorage.setItem("domain_setting", JSON.stringify(domain_setting));
    dispatch(SETDOMAINSETTING(domain_setting));
    if (param?.store_type == 3) {
      dispatch(SETSTOREDELIVERYDATA(store?.data));
    } else {
      dispatch(SETSTORECOLLECTIONDATA(store?.data));
    }
    setLoading(loading);
  };
  const handleChangeStore = (index: any) => {
    const search = {
      search_item: "",
      longitude: sessionStorage.getItem("longitude") || "",
      latitude: sessionStorage.getItem("latitude") || "",
      store_type: index === 0 ? 3 : 1,
    };
    sessionStorage.setItem("store_type", `${search?.store_type}`);
    dispatch(SETSTORETYPE(search?.store_type));
    if (delivery?.length == 0 && search?.store_type == 3) {
      getStoreData(search);
    } else if (collection?.length == 0 && search?.store_type == 1) {
      getStoreData(search);
    }
  };
  return (
    <>
      <Tabs
        selectedIndex={selectedType == 3 ? 0 : 1}
        onSelect={handleChangeStore}
        className="tab-container"
      >
        <TabList className="tab-list ">
          <Tab>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                opacity="0.35"
                d="M19 21C20.6569 21 22 19.6569 22 18C22 16.3431 20.6569 15 19 15C17.3431 15 16 16.3431 16 18C16 19.6569 17.3431 21 19 21Z"
                fill="#535353"
              />
              <path
                opacity="0.35"
                d="M11 4.5C11 3.672 10.328 3 9.5 3C9.114 3 3.886 3 3.5 3C2.672 3 2 3.672 2 4.5C2 4.886 2 9.114 2 9.5C2 10.328 2.672 11 3.5 11C3.886 11 9.114 11 9.5 11C10.328 11 11 10.328 11 9.5C11 9.114 11 4.886 11 4.5Z"
                fill="#535353"
              />
              <path
                opacity="0.35"
                d="M16 4C15.743 4 14.257 4 14 4C13.448 4 13 4.448 13 5C13 5.552 13.448 6 14 6C14.257 6 15.743 6 16 6C16.552 6 17 5.552 17 5C17 4.448 16.552 4 16 4Z"
                fill="#535353"
              />
              <path
                opacity="0.35"
                d="M5 21C6.65685 21 8 19.6569 8 18C8 16.3431 6.65685 15 5 15C3.34315 15 2 16.3431 2 18C2 19.6569 3.34315 21 5 21Z"
                fill="#535353"
              />
              <path
                d="M17.8258 12.7349C21.3028 12.7349 22.9998 14.9999 22.9998 15.9999C22.9998 16.9999 21.9998 16.9999 20.9998 16.9999C19.9998 16.9999 15.2588 17.0139 15.2588 17.0139L17.8258 12.7349Z"
                fill="#535353"
              />
              <path
                d="M16.5 6H18.5C18.776 6 19 5.776 19 5.5V3.5C19 3.224 18.776 3 18.5 3H16.5C15.672 3 15 3.672 15 4.5C15 5.328 15.672 6 16.5 6Z"
                fill="#535353"
              />
              <path
                d="M15.9999 4L14.9999 4.5C14.9999 6.04 15.9999 7.055 15.9999 10C15.9999 13 15.7109 14 13.9999 14H10.9999V13C10.9999 11.895 10.1049 11 8.99991 11H3.99991C3.17091 11 1.65591 11.984 1.16091 13.781C0.980915 14.434 1.32491 15.122 1.95891 15.359L8.49091 17.808C8.82691 17.935 9.18392 18 9.54391 18H13.7569C14.5529 18 15.3159 17.684 15.8779 17.121L19.9999 13C19.9379 9.094 17.9999 6 15.9999 4Z"
                fill="#535353"
              />
              <path
                d="M7 5C6.814 5 6.186 5 6 5C5.448 5 5 5.448 5 6C5 6.552 5.448 7 6 7C6.186 7 6.814 7 7 7C7.552 7 8 6.552 8 6C8 5.448 7.552 5 7 5Z"
                fill="#535353"
              />
            </svg>
            Delivery
          </Tab>
          <Tab>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_7392_4240)">
                <path
                  opacity="0.35"
                  d="M22.4328 9.916C21.9568 9.333 21.2518 9 20.4998 9H3.49979C2.74779 9 2.04279 9.333 1.56679 9.916C1.08979 10.498 0.900791 11.254 1.04879 11.991L2.20879 17.785C2.58179 19.648 4.23079 21 6.13079 21H17.8688C19.7678 21 21.4178 19.648 21.7908 17.785L22.9508 11.991C23.0988 11.253 22.9098 10.497 22.4328 9.916Z"
                  fill="#535353"
                />
                <path
                  d="M17 13C17 12.448 16.552 12 16 12C15.448 12 15 12.448 15 13C15 13.257 15 13.743 15 14H17C17 13.743 17 13.257 17 13Z"
                  fill="#535353"
                />
                <path
                  d="M9 13C9 12.448 8.552 12 8 12C7.448 12 7 12.448 7 13C7 13.257 7 16.743 7 17C7 17.552 7.448 18 8 18C8.552 18 9 17.552 9 17C9 16.743 9 13.257 9 13Z"
                  fill="#535353"
                />
                <path
                  d="M13 13C13 12.448 12.552 12 12 12C11.448 12 11 12.448 11 13C11 13.257 11 16.743 11 17C11 17.552 11.448 18 12 18C12.552 18 13 17.552 13 17C13 16.743 13 13.257 13 13Z"
                  fill="#535353"
                />
                <path
                  d="M7.562 9L8.373 5.757C8.484 5.311 8.883 5 9.342 5H14.657C15.117 5 15.516 5.311 15.627 5.757L16.438 9H18.5L17.568 5.272C17.234 3.937 16.034 3 14.658 3H9.342C7.965 3 6.765 3.937 6.432 5.272L5.5 9H7.562Z"
                  fill="#535353"
                />
                <path
                  d="M23.8 20.8L20.437 17.563L21.8 16.2C22.2 15.8 21.9 15 21.3 15H16.2C15.5 15 15 15.5 15 16.2V21.3C15 21.9 15.8 22.2 16.2 21.8L17.6 20.4L20.9 23.7C21.3 24.1 22 24.1 22.4 23.7L23.8 22.3C24.2 21.9 24.2 21.2 23.8 20.8Z"
                  fill="#535353"
                />
              </g>
              <defs>
                <clipPath id="clip0_7392_4240">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Pickup
          </Tab>
        </TabList>

        {/* <TabPanel className=" ">
          {loading ? (
            <StoreSkelton />
          ) : delivery?.length === 0 ? (
            <NoStoreFound />
          ) : (
            <>
              <Store stores={delivery} />
            </>
          )}
        </TabPanel>
        <TabPanel className=" py-6 px-8">
          {loading ? (
            <StoreSkelton />
          ) : collection?.length === 0 ? (
            <NoStoreFound />
          ) : (
            <Store stores={collection} />
          )}
        </TabPanel> */}
      </Tabs>
    </>
  );
}
