import { t } from "i18next";
import { number, object, string } from "yup";

export const formInitialValues = {
  id: "",
  address_type: "",
  full_address: "",
  house_no: "",
  street_name: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  lattitude: null,
  longitude: null,
};

export const formSchemaInitial = object().shape({
  address_type: string().required("MyAddress.addressRequired"),
  full_address: string().required("MyAddress.fullAddressRequired"),
  house_no: string().required("MyAddress.houseNoRequired"),
  street_name: string().required("MyAddress.streetNameRequired"),
  country: string().required("MyAddress.countryRequired"),
  city: string().required("MyAddress.cityRequired"),
  zip_code: string(),
  state: string().required("MyAddress.stateRequired"),
  lattitude: number().nullable().notRequired(),
  longitude: number().nullable().notRequired(),
  id: string().notRequired(),
});
