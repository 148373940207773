import { useTranslation } from "react-i18next";

export default function PoweredBy() {
  const { t } = useTranslation();
  return (
    <>
      <div className="poweredBy">
        <h4>{t("poweredBy")}</h4>
        <div className="footerLogo">
          <img src="/logo.png" width="100" height="36" alt="Logo" />
        </div>
      </div>
    </>
  );
}
