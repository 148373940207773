import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OrderStatusType } from "../../utils/OrderStatusType";
import ParseFloat from "../../utils/ParseFloat";

export default function OrderDetail() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { orderDetail } = useSelector((state: any) => state?.allOrder);

  return (
    <>
      <div className="md:border border-[#E0E0E0] md:rounded-xl overflow-hidden md:mb-3 mb-24">
        <div className="md:bg-[#F8F8F8] py-2 px-4">
          <div className="flex md:flex-row flex-col gap-2 ">
            <div className="w-full flex gap-2">
              <div className="rounded-md overflow-hidden md:h-auto h-16 md:w-[6rem] w-16 relative">
                {orderDetail.store_data ? (
                  <img
                    src={`${orderDetail?.store_data?.[0]?.store_image?.base_url}${orderDetail?.store_data?.[0]?.store_image?.attachment_url}`}
                    className="size-full mix-blend-multiply"
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
              <div>
                <h4 className="text-lg font-bold text-black">
                  {orderDetail?.store_data
                    ? orderDetail?.store_data?.[0]?.store_name
                    : ""}
                </h4>
                <p className="text-[#828282] textSm font-normal">
                  Burgers, Fast Food, Sandwiches
                </p>
                <div className="flex  textSm font-normal gap-1 items-center mb-2">
                  <i className="fa fa-star text-yellow-500"></i>
                  <p>
                    4.9 <span className="text-[#828282]">(2000 ratings)</span>
                  </p>
                </div>
                <div className="md:flex hidden justify-between">
                  <p className="flex  textSm font-normal gap-1 items-center mb-2">
                    <img src="/images/location-icon.svg" className="size-5" />
                    {orderDetail?.store_data
                      ? orderDetail?.store_data?.[0]?.address
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="md:hidden flex justify-between ">
              <p className="flex  textSm font-normal gap-1 items-center mb-2">
                <img src="/images/location-icon.svg" className="size-5" />
                {orderDetail?.store_data
                  ? orderDetail?.store_data?.[0]?.address
                  : ""}
              </p>
            </div>
            <div className="flex md:flex-col flex-row-reverse justify-between">
              <h4 className="textMd font-bold text-[#1466FA] text-right whitespace-nowrap">
                {orderDetail?.order_id}
              </h4>
              <p className="textSm font-bold text-[#30C53E] text-right">
                {OrderStatusType(orderDetail?.order_status)}
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-between py-2 px-4">
          {
            <div className="text-base">
              <p className="font-bold textMd">Deliver to:</p>
              <p className="textMd">{orderDetail?.delivery_address}</p>
            </div>
          }

          <div className="text-base text-right">
            <p className="font-bold textMd">Payment Mode:</p>
            <p className="  textMd">{orderDetail?.payment_method}</p>
            <p className="font-bold textMd">Mobile no.</p>
            <p className=" textMd">
              {orderDetail?.store_data?.[0]?.phone_number}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-3 bg-[#F8F8F8]  textSm py-2 px-4">
          <p>Items</p>
          <p className="text-center">Qty</p>
          <p className="text-right">Amount</p>
        </div>

        {orderDetail?.orderlist?.map((item: any) => (
          <>
            <div className="py-2 px-4">
              <div className="repeatItem !mb-2">
                <div className="grid grid-cols-3 mb-1">
                  <p className="textMd whitespace-nowrap font-bold ">
                    {item?.item_name}
                  </p>

                  <div className="itemSize textMd font-bold text-center">
                    {item.quantity || 1}x
                  </div>
                  <div className="Price textMd font-bold text-right">
                    {ParseFloat(item?.price)}
                  </div>
                </div>
                <div className="ml-3">
                  {/* {/ step option .... /} */}

                  {item?.order_item_step?.map((step: any) => {
                    return (
                      <div className="flexBox justifyBetween">
                        <div className="sizeDetail fs-7">
                          <span className="flex items-center gap-2 textMd capitalize">
                            <i className="fa fa-circle text-[5px]"></i>
                            {step?.step_title}
                          </span>
                        </div>
                        <div className="textMd capitalize">
                          {step?.price ? ParseFloat(step?.price) : "Free"}
                        </div>
                      </div>
                    );
                  })}

                  {/* {/ category ... /} */}
                  {item?.order_item_sub_categories?.map((step: any) => {
                    return (
                      <div className="flexBox justifyBetween">
                        <div className="">
                          <span className="flex items-center gap-2 textMd capitalize">
                            {" "}
                            <i className="fa fa-circle text-[5px]"></i>{" "}
                            {step?.name}
                          </span>
                        </div>
                        <div className="textMd capitalize">
                          {step?.web_price
                            ? ParseFloat(step?.web_price)
                            : "Free"}
                        </div>
                      </div>
                    );
                  })}
                  {item?.addon_data?.map((step: any) => {
                    return (
                      <div className="flexBox justifyBetween">
                        <div className="">
                          <div className="flex items-center gap-2 textMd capitalize">
                            <i className="fa fa-circle text-[5px]"></i>{" "}
                            {step?.item_name}
                          </div>
                        </div>
                        <div className="textMd capitalize">
                          {step?.price ? ParseFloat(step?.price) : "Free"}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ))}
        <hr />
        <div className="flex justify-end gap-16 text-right py-2 px-4">
          <div className="textMd ">
            <p className=" font-bold mb-2">Item Total</p>
            <p className=" text-[#00A838] mb-2">Discount</p>
            <p className="  mb-2">Taxes</p>
            <p className="text-base font-bold ">Grand Total</p>
          </div>
          <div className="textMd ">
            <p className="font-bold mb-2">
              {ParseFloat(orderDetail?.sub_total)}
            </p>
            <p className="text-[#00A838] mb-2">
              {ParseFloat(orderDetail?.discount)}
            </p>
            <p className="mb-2">{ParseFloat(orderDetail?.total_tax)}</p>
            <p className="text-base font-bold ">
              {ParseFloat(orderDetail?.total_price)}
            </p>
          </div>
        </div>
      </div>
      <div className="md:hidden flex justify-between md:flex-row flex-col items-center gap-3 fixed bottom-0 p-2 bg-white w-full">
        <p className="text-black textMd font-bold">
          {t("MyOrders.orderPlaceOn")}{" "}
          {moment(orderDetail?.updated_at).format("lll")}
        </p>
        <button className="btn-secondary md:w-auto w-full ">
          {t("MyOrders.reOrder")}
        </button>
      </div>
    </>
  );
}
