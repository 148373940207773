export const SortVarition = (items: any) => {
  items?.item_variation.sort((p1: any, p2: any) =>
    Number(p1.variation_price) > Number(p2.variation_price)
      ? 1
      : Number(p1.variation_price) < Number(p2.variation_price)
      ? -1
      : 0
  );
  return items;
};
