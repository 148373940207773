const DiscountPrice = (price: number, store: any) => {
  if ((sessionStorage.getItem("store_type") as any) == 3) {
    if (store?.store_discount?.delivery_discount) {
      return (
        (Number(price) * Number(store?.store_discount?.delivery_discount)) / 100
      );
    } else {
      return 0;
    }
  } else if ((sessionStorage.getItem("store_type") as any) == 1) {
    if (store?.store_discount?.pickup_discount) {
      return (
        (Number(price) * Number(store?.store_discount?.pickup_discount)) / 100
      );
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export default DiscountPrice;
