export const Hindi = {
  poweredBy: "द्वारा संचालित",
  cartEmpty: "आपकी कार्ट खाली है",
  clearCart: "कार्ट को खाली करें",
  change: "परिवर्तन",
  mycart: "मेरी कार्ट",
  Extra_Large: "एक्स्ट्रा लार्ज",
  Large: "बड़ा",
  Medium: "मध्यम",
  Small: "छोटा",
  Customize_Shake: "शेक को अनुकूलित करें",
  Shakes001: "शेक001",
  Vanila_Choc_Shake: "वेनिला चॉक शेक",
  Mixed_Fruit_Juice: "मिश्रित फलों का रस",
  Mocktail: "मॉकटेल",
  Shakes: "शेक",
  Juices: "रस",
  NotFound: {
    line1: "पृष्ठ नहीं मिला।",
    line2: "उफ़! हमें वह पेज नहीं मिल सका जिसे आप ढूंढ रहे हैं।",
  },
  Login: {
    forgot: "पासवर्ड भूल गए?",
    customerName: "ग्राहक का नाम",
    mobile: "मोबाइल नंबर",
    signme: "मुझे भाग दिलाएं",
    submit: "जमा करें",
    enterCustomer: "ग्राहक का नाम दर्ज करें",
    enterMobile: "मोबाइल नंबर दर्ज करें",
    customerRequired: "ग्राहक का नाम आवश्यक है।",
    customerChar: "ग्राहक का नाम पट्टे पर 4 अक्षर का होना चाहिए",
    mobileRequired: "मुझे मोबाइल नंबर चाहिए।",
    welcome: "योप में आपका स्वागत है।",
    register: "यदि आपके पास खाता नहीं है तो रजिस्टर करें।",
  },
  VerifyOtp: {
    heading: "आपके नंबर पर भेजा गया ओटीपी दर्ज करें",
    submit: "ओटीपी सत्यापित करें",
    error: "सही ओटीपी दर्ज करें",
    dontReceive: "क्या कोई ओटीपी प्राप्त नहीं हुआ?",
    resentOtp: "ओटीपी पुनः भेजें",
    enterOtp: "ओटीपी दर्ज करें।",
    continue: "जारी रखे",
    info: "इस नंबर पर 6 अंकों का कोड भेजा गया है",
    newNumber: "नया नंबर दर्ज करें",
    cancel: "रद्द करे",
    update: "बदले",
  },
  Forgot: {
    newPassword: "नया पासवर्ड",
    confirmPassword: "नए पासवर्ड की पुष्टि करें",
    enterNewPassword: "नया पासवर्ड दर्ज करें",
    enterConfirmPassword: "नया पासवर्ड दर्ज करें पुष्टि करें",
    submit: "जमा करें",
  },
  Home: {
    delivery: "वितरण",
    collection: "संग्रह",
    pickCol: "क्लिक करें और एकत्र करें/डिलीवरी करें",
    orderOnline: "ऑनलाइन ऑर्डर",
    away: "दूर",
  },
  Index: {
    title: "Hei maailma!!",
    subtitle: "Kuis menee?",
    alertMessage: "Jotaki meni ny pielee!",
  },
  Register: {
    register: "पंजीकरण करें",
    registerText: "यदि आपके पास खाता नहीं है तो रजिस्टर करें।",
    firstName: "पहला नाम",
    lastName: "उपनाम",
    email: "ईमेल",
    enterFirstName: "अपना पहला नाम दर्ज करें",
    enterLastName: "अपना उपनाम दर्ज करें",
    enterEmail: "ईमेल दर्ज करें",
    skip: "छोडे",
  },
  Settings: {
    settings: "सेटिंग्स",
    profileDetails: "प्रोफ़ाइल विवरण",
    orders: "ऑर्डर",
    myAddress: "मेरा पता",
    changePassword: "पासवर्ड बदलें",
    oldPassword: "पुराना पासवर्ड",
    newPassword: "नया पासवर्ड",
  },
  Profile: {
    profile: "प्रोफ़ाइल",
    upload: "चित्र अपलोड करें",
    firstName: "पहला नाम",
    lastName: "उपनाम",
    phoneNumber: "फ़ोन नंबर",
    email: "ईमेल",
    cancel: "रद्द करे",
    save: "सुरक्षित करे",
    edit: "संपादन करे",
    mobileNumberRequired: "मोबाइल नंबर आवश्यक है",
    firstNameRequired: "पहला नाम आवश्यक है",
    lastNameRequired: "उपनाम आवश्यक है",
    invalidEmail: "अमान्य ईमेल पता",
    emailRequired: "ईमेल आवश्यक है",
  },
  MyOrders: {
    noOrders: "कोई ऑर्डर नहीं",
    reOrder: "पुन: व्यवस्थित करें",
    orderPlaceOn: "ऑर्डर दे दिया गया",
  },
  MyAddress: {
    myAddress: "मेरा पता",
    noAddress: "अभी तक कोई पता नहीं जोड़ा गया",
    newAddress: "नया पता",
    edit: "संपादन करे",
    delete: "मिटांए",
    addAddress: "पता जोड़ें",
    editAddress: "पता संपादित करें",
    addressType: "पता मुद्रलेख",
    home: "घर",
    office: "कार्यालय",
    other: "अन्य",
    fullAddress: "पूरा पता",
    enterFullAddress: "अपना पूरा पता दर्ज करें",
    houseNo: "मकान नंबर",
    enterHouseNo: "अपना मकान नंबर दर्ज करें",
    streetName: "सड़क का नाम",
    enterStreetName: "अपनी सड़क का नाम दर्ज करें",
    country: "देश",
    enterCountry: "अपना देश दर्ज करें",
    state: "राज्य",
    district: "जिला",
    enterDistrict: "अपना जिला दर्ज करें",
    city: "शहर",
    enterCity: "अपना शहर दर्ज करें",
    zipCode: "ज़िप कोड",
    enterZipCode: "अपना ज़िप कोड दर्ज करें",
    submit: "जमा करें",
    addressRequired: "पता आवश्यक है",
    fullAddressRequired: "पूरा पता आवश्यक है",
    houseNoRequired: "मकान संख्या आवश्यक है",
    streetNameRequired: "सड़क का नाम आवश्यक है",
    countryRequired: "देश आवश्यक है",
    districtRequired: "जिला आवश्यक है",
    cityRequired: "शहर आवश्यक है",
    stateRequired: "राज्य आवश्यक है",
  },
  OrderSuccess: {
    orderConfirmed: "ऑर्डर की पुष्टि हो गई",
    thankYou: "धन्यवाद !",
    orderOnWay: "आपका ऑर्डर आने वाला है",
    continueOrder: "ऑर्डर जारी रखें",
  },
  CartDetails: {
    subTotal: "उप-योग",
    total: "कुल-योग",
    items: "सामान",
    price: "कीमत",
    checkOut: "चेक आउट",
  },
  SearchFilter: {
    enterAddress: "कृपया पता दर्ज करें",
  },
  Header: {
    notification: "अधिसूचना",
    profile: "प्रोफ़ाइल",
    myOrders: "मेरे ऑर्डर",
    logout: "लॉग आउट",
    selectOneItem: "कृपया कम से कम एक आइटम चुनें",
  },
  Checkout: {
    delivery: "वितरण",
    collection: "संग्रह",
    deliveryDetails: "वितरण विवरण",
    firstName: "पहला नाम",
    lastName: "उपनाम",
    enterFirstName: "कृपया प्रथम नाम दर्ज करें",
    enterLastName: "कृपया उपनाम नाम दर्ज करें",
    paymentMethod: "भुगतान विधि",
    addPaymentMethod: "भुगतान विधि जोड़ें",
    orderSummary: "ऑर्डर सारांश",
    items: "सामान",
    price: "कीमत",
    continueToPayment: "भुगतान जारी रखें",
    orderTotal: "कुल आर्डर",
    subTotal: "उप-योग",
    serviceFee: "सेवा शुल्क",
    deliveryFee: "वितरण शुल्क",
    firstNameLength: "पहला नाम कम से कम 4 अक्षर का होना चाहिए",
    lastNameLength: "उपनाम कम से कम 4 अक्षर का होना चाहिए",
    pleaseSelect: "कृपया चयन कीजिए",
    firstNameRequired: "पहला नाम आवश्यक है",
    lastNameRequired: "उपनाम आवश्यक है",
    deliveryAddress: "डिलिवरी का पता",
  },
};
