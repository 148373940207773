import axiosInstance from "../Axios-Config/Axios-Config";
const CreatePaymentIntentApi = async ({
  totalPrice,
  commission,
  user_id,
  email,
  first_name,
  phone,
}: {
  totalPrice: Number;
  commission: number;
  user_id: number;
  email: string;
  first_name: string;
  phone: string;
}) => {
  try {
    const data: any = await axiosInstance.get(
      `create/payment/intent?amount=${totalPrice}&service_charge=${commission}&user_id=${process.env.REACT_APP_USER_ID}&email=${email}&phone=${phone}&name=${first_name}`
    );
    return { data: data, loading: false };
  } catch (error: any) {
    return { data: null, loading: false };
  }
};
export default CreatePaymentIntentApi;
