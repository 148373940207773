import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetPickupDate, GetPickupTime } from "../../api/PickupApi";
import FilterCurrentTimeInStore from "../../utils/FilterCurrentTimeInStore";
import LabelText from "../FormLabels/LabelText";

const PickupView = () => {
  const [selectedDate, setSelectedDate] = useState(moment().format("dddd"));
  const [selectedTime, setSelectedTime] = useState();
  const {
    cartData: { storePickupDate, storePickupTime },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!storePickupDate?.length) {
      GetPickupDate(dispatch);
      GetPickupTime(dispatch, moment().format("dddd"));
    }
  }, []);
  useEffect(() => {
    if (!storePickupTime?.[selectedDate]?.length) {
      GetPickupTime(dispatch, selectedDate);
    }
  }, [selectedDate]);
  return (
    <div className="store-card checkout">
      <p className="text-base font-bold mb-3">Pickup Details</p>
      <LabelText text="Pickup Date" />
      <select
        onChange={(e: any) => setSelectedDate(e.target.value?.split(" ")?.[0])}
        className="border border-[#C5C5C5] radius px-4 py-3 textSm w-full"
      >
        {storePickupDate?.map((item: any) => {
          return <option value={item}>{item}</option>;
        })}
      </select>
      <LabelText text="Pickup Time" />

      <select className="border border-[#C5C5C5] radius px-4 py-3 textSm w-full">
        {storePickupTime &&
          FilterCurrentTimeInStore(storePickupTime, selectedDate)?.map(
            (item: any) => {
              return <option value={item}>{item}</option>;
            }
          )}
      </select>
    </div>
  );
};

export default PickupView;
