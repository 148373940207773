import axiosInstance from "../Axios-Config/Axios-Config";
import { ErrorToast } from "../components/ToastComponent/ErrorToast";
import { SuccessToast } from "../components/ToastComponent/SuccessToast";

export const resendOtp = async () => {
  try {
    const mobileNumber = sessionStorage.getItem("yop-phone");
    const countryCode = sessionStorage.getItem("you-phone_code");
    const { data }: any = await axiosInstance.post("login", {
      phone_number: mobileNumber,
      customer_name: "",
      phone_code: countryCode,
    });
    if (data?.success) {
      SuccessToast(data?.message);
    } else {
      ErrorToast(data?.message);
    }
    return false;
  } catch (error: any) {
    ErrorToast(error?.message);
    return false;
  }
};
