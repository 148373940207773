import ParseFloat from "../../../utils/ParseFloat";

export default function ItemVariationView(props: any) {
  return (
    <>
      <div className="chooseVariation ">
        <input
          defaultChecked={props.selectedId == props.id}
          type="radio"
          className=""
          id={props.id}
          name="variation"
          onChange={props.onchange}
        />
        <label htmlFor={props.id}>
          <div className="variation_option ">
            {props.itemName && <span>{props.itemName}</span>}
            {props.price && <span> {ParseFloat(props.price)}</span>}
          </div>
        </label>
      </div>
    </>
  );
}
