import axiosInstance from "../Axios-Config/Axios-Config";
import { ErrorToast } from "../components/ToastComponent/ErrorToast";
import { interfaceProfileState } from "../interface/ProfileInterface";
const updateProfile = async (state: interfaceProfileState) => {
  try {
    const { data }: any = await axiosInstance.postForm("manage-profile", {
      first_name: state.first_name,
      last_name: state.last_name,
      phone: state.mobileNumber,
      email: state.email,
      image: state.profileImage,
    });
    if (data.success) {
      sessionStorage.setItem("yop-phone", state.mobileNumber);
      // SuccessToast(data.message);
    } else {
      ErrorToast(data.message);
    }
    return data;
  } catch (error: any) {
    ErrorToast(error.message);
    return error;
  }
};
export default updateProfile;
