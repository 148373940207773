import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import MyInfoWrapper from "../../Wrapper/MyInfoWrapper";
import MyAddressView from "../../components/MyAddress/MyAddressview";

export default function MyAddress() {
  return (
    <>
      <MyInfoWrapper>
        <MyAddressView/>
      </MyInfoWrapper>
    </>
  );
}
