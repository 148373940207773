import React from "react";
import MyInfoWrapper from "../../Wrapper/MyInfoWrapper";
import OrderDetail from "../../components/OrderDetail/OrderDetail";

const OrderDetails = () => {
  return (
    <MyInfoWrapper>
      <OrderDetail />
    </MyInfoWrapper>
  );
};

export default OrderDetails;
