import axiosInstance from "../Axios-Config/Axios-Config";
import { ErrorToast } from "../components/ToastComponent/ErrorToast";
import { SuccessToast } from "../components/ToastComponent/SuccessToast";
const loginApi = async (state: any, navigate: any) => {
  try {
    const { data }: any = await axiosInstance.post("login", {
      phone_number: state.mobileNumber,
      customer_name: "",
      phone_code: state.phone_code,
    });
    if (data.success) {
      sessionStorage.setItem("yop-phone", state.mobileNumber);
      sessionStorage.setItem("you-phone_code", state.phone_code);
      setTimeout(() => {
        navigate("/verify-otp", { replace: true });
      }, 0);
      SuccessToast(data.message);
    } else {
      ErrorToast(data.message);
    }
    return false;
  } catch (error: any) {
    ErrorToast(error.message);
    return false;
  }
};
export default loginApi;
