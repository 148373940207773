import checkItemType from "./checkItemType";

const ItemSizeName = (item: any) => {
  if (checkItemType(item) == 3) {
    return "";
  } else {
    return (
      `(${item?.selected_variation?.variation_detail?.[0]?.variation_name})` ??
      ""
    );
  }
};

export default ItemSizeName;
