import { ErrorToast } from "../../components/ToastComponent/ErrorToast";
interface getSubData {
  e: any;
  optionData: any;
  stepList: any;
  customizedData: any;
  categoryId: any;
  setCategoryId: any;
}
const getSubData = async ({
  e,
  optionData,
  stepList,
  customizedData,
  categoryId,
  setCategoryId,
}: getSubData) => {
  if (customizedData.isEdit) {
    if (e.target.checked) {
      var result = categoryId.filter((obj: any) => {
        return obj.side_id == stepList.item_step_id;
      });

      if (stepList.max_count > result.length) {
        const category = [...categoryId, optionData];
        await setCategoryId(category);
      } else {
        (
          document.getElementById(optionData.item_id) as HTMLInputElement
        ).checked = false;
        ErrorToast(`You can add maximum ${stepList?.max_count}`);
      }
    } else {
      const allCategory = [...categoryId];
      const findDataId = allCategory.find(
        (e) => e.item_id === optionData.item_id
      );
      const index = allCategory.indexOf(findDataId);
      if (index !== -1) {
        allCategory.splice(index, 1);
        await setCategoryId(allCategory);
      }
    }
  } else {
    if (e.target.checked) {
      var result = categoryId.filter((obj: any) => {
        return obj.side_id == stepList.item_step_id;
      });

      if (stepList.max_count > result.length) {
        const category = [...categoryId, optionData];
        await setCategoryId(category);
      } else {
        (
          document.getElementById(optionData.item_id) as HTMLInputElement
        ).checked = false;
        ErrorToast(`You can add maximum ${stepList?.max_count}`);
      }
    } else {
      const allCategory = [...categoryId];
      const index = allCategory.indexOf(optionData);
      if (index !== -1) {
        allCategory.splice(index, 1);
        const newData = allCategory;
        setCategoryId(newData);
      }
    }
  }
};
export default getSubData;
