import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CLEARUSER } from "../../redux/features/userData";
import LinkLoginUserButton from "../Buttons/LinkLoginUserButton";
import Logo from "../HeaderLogo/Logo";
import { ErrorToast } from "../ToastComponent/ErrorToast";
import HeaderProfilPic from "./HeaderProfilePic";
import HeaderSearch from "./HeaderSearch";

export default function Header({ handleSidebar }: any) {
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState(false);
  const { itemCount, totalPrice } = useSelector((state: any) => state.cartData);
  const {
    userData: { user },
  } = useSelector((state: any) => state);

  const { t } = useTranslation();
  const navigation = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    sessionStorage.removeItem("yop-token");
    sessionStorage.removeItem("yop-phone");
    dispatch(CLEARUSER());
    navigation("/");
  };

  function myCart() {
    if (itemCount == 0) {
      ErrorToast("Please select atleast one item");
    } else {
      navigation("/checkout");
    }
  }

  const onHandleLogOut = () => {
    sessionStorage.removeItem("yop-token");
    sessionStorage.removeItem("yop-phone");
    navigation("/");
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex gap-4 items-center">
          <Logo />
          {location.pathname !== "/login" &&
            location.pathname !== "/register" &&
            location.pathname !== "/verify-otp" && <HeaderSearch />}
        </div>
        <div className="flex gap-3 items-center justify-between">
          {location.pathname !== "/login" &&
            location.pathname !== "/register" &&
            location.pathname !== "/verify-otp" && (
              <>
                {/* <div className="bg-[#34558B1A] rounded-[13px] px-2 py-2">
                    <img
                      src="/images/header-notification.svg"
                      className="size-6"
                    />
                  </div> */}
                {
                  // <div className="cartItem " onClick={myCart}>
                  //   <span>
                  //     <svg
                  //       xmlns="http://www.w3.org/2000/svg"
                  //       width="20"
                  //       height="20"
                  //       viewBox="0 0 34 28"
                  //       fill="none"
                  //     >
                  //       <path
                  //         d="M1.62491 0.49999C1.47583 0.497882 1.32783 0.525423 1.18949 0.581014C1.05115 0.636604 0.925236 0.719135 0.81907 0.82381C0.712904 0.928485 0.628601 1.05322 0.57106 1.19076C0.513518 1.3283 0.483887 1.4759 0.483887 1.62499C0.483887 1.77408 0.513518 1.92168 0.57106 2.05922C0.628601 2.19676 0.712904 2.3215 0.81907 2.42617C0.925236 2.53085 1.05115 2.61338 1.18949 2.66897C1.32783 2.72456 1.47583 2.7521 1.62491 2.74999H3.6918C4.23775 2.74999 4.69285 3.12718 4.7963 3.66405L5.02042 4.83593L7.43887 17.5376C7.87621 19.8292 9.89526 21.5 12.2289 21.5H26.2709C28.6046 21.5 30.6245 19.8299 31.0609 17.5376L33.4794 4.83593C33.5104 4.6733 33.5052 4.50583 33.464 4.34547C33.4229 4.1851 33.3468 4.03579 33.2413 3.9082C33.1359 3.7806 33.0035 3.67785 32.8537 3.60729C32.704 3.53673 32.5405 3.50009 32.3749 3.49999H7.05509L7.00675 3.24364C6.7044 1.65914 5.30514 0.49999 3.6918 0.49999H1.62491ZM7.48428 5.74999H31.0155L28.8505 17.1172C28.6135 18.3619 27.5387 19.25 26.2709 19.25H12.2289C10.9615 19.25 9.8875 18.3618 9.64932 17.1172V17.1157L7.48428 5.74999ZM13.9999 23C13.4032 23 12.8309 23.237 12.4089 23.659C11.987 24.081 11.7499 24.6533 11.7499 25.25C11.7499 25.8467 11.987 26.419 12.4089 26.841C12.8309 27.2629 13.4032 27.5 13.9999 27.5C14.5966 27.5 15.1689 27.2629 15.5909 26.841C16.0129 26.419 16.2499 25.8467 16.2499 25.25C16.2499 24.6533 16.0129 24.081 15.5909 23.659C15.1689 23.237 14.5966 23 13.9999 23ZM24.4999 23C23.9032 23 23.3309 23.237 22.9089 23.659C22.487 24.081 22.2499 24.6533 22.2499 25.25C22.2499 25.8467 22.487 26.419 22.9089 26.841C23.3309 27.2629 23.9032 27.5 24.4999 27.5C25.0966 27.5 25.6689 27.2629 26.0909 26.841C26.5129 26.419 26.7499 25.8467 26.7499 25.25C26.7499 24.6533 26.5129 24.081 26.0909 23.659C25.6689 23.237 25.0966 23 24.4999 23Z"
                  //         fill="#5B5B5B"
                  //       />
                  //     </svg>
                  //     <div className="countNumber">
                  //       <span>{itemCount || 0}</span>
                  //     </div>
                  //   </span>
                  //   {t("mycart")}
                  //   {/* {/ {ParseFloat(totalPrice)} /} */}
                  // </div>
                }
                {sessionStorage.getItem("yop-token") != undefined ? (
                  <Link
                    to="/my-order"
                    className="text-base text-[#151e3f] font-semibold hidden md:block"
                  >
                    My Orders
                  </Link>
                ) : (
                  ""
                )}
                {Object.keys(user)?.length > 0 ? (
                  <div className="dropDown">
                    <HeaderProfilPic
                      user={user}
                      handleSidebar={handleSidebar}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="rightButton"
                  onClick={() => {
                    if (!sessionStorage.getItem("yop-token")) {
                      sessionStorage.setItem("previous-page", "/menu");
                    }
                  }}
                >
                  {sessionStorage.getItem("yop-token") == undefined ? (
                    <>
                      <LinkLoginUserButton
                        buttonText="Login"
                        url="/login"
                        btnClass="btnBlue loginIcon"
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
}
