export const ItemCount=(data:any)=>{
    let length = 0;
    data?.map((item:any) => {
      if (item.quantity) {
        length += item?.quantity;
      } else {
        length ++;
      }
    });
    return length;
}