import { t } from "i18next";
import { object, string } from "yup";
export interface interfaceDeliveryState {
  firstName: string;
  lastName: string;
  deliveryAddress: string;
  pickupTime: string;
}
export const DeliverySchema = object().shape({
  firstName: string().optional(),
    // .required(t("Checkout.firstNameRequired"))
    // .min(4, () => t("Checkout.firstNameLength")),
  lastName: string().optional(),
    // .required(t("Checkout.lastNameRequired"))
    // .min(4, () => t("Checkout.lastNameLength")),

  // deliveryAddress: string().required(t("Checkout.deliveryAddress")),
  isDelivery: string().required(t("Checkout.pleaseSelect")),
});
export const CollectionSchema = object().shape({
  firstName: string().optional(),
    // .required(t("Checkout.firstNameRequired"))
    // .min(4, () => t("Checkout.firstNameLength")),
  lastName: string().optional(),
    // .required(t("Checkout.lastNameRequired"))
    // .min(4, () => t("Checkout.lastNameLength")),
  // pickupTime: string().required("Pickup time is  required"),
  isDelivery: string().required(t("Checkout.pleaseSelect")),
});
export const initialDeliveryState = {
  firstName: "",
  lastName: "",
  deliveryAddress: "",
  pickupTime: "dd",
  isDelivery: "Delivery",
};
