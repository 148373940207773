import { AsyncImage } from "loadable-image";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { Blur } from "transitions-kit";
import AddToCart from "../../utils/AddToCart";
import DecrementItemData from "../../utils/DecrementItemData";
import { GetItemPrice } from "../../utils/GetItemPrice";
import incrementItemData from "../../utils/Increment";
import ParseFloat from "../../utils/ParseFloat";
import ShortText from "../../utils/ShortText";
import checkItemType from "../../utils/checkItemType";
import CustomizedModal from "../Modal/Customized-Modal";
import InfoModal from "../Modal/InfoModal";
import VariationModal from "../Modal/Variation-Modal";
export default function MenuCard({
  classNameType,
  item,
  quantity,
  menu_id,
  taxdata,
}: {
  classNameType?: string;
  item: any;
  quantity: number;
  menu_id: number;
  taxdata: any;
}) {
  const { items, itemCount } = useSelector((state: any) => state?.cartData);
  const {
    variationData,
    customizedData,
    categoryItem,
    categoryData: { selectedCategory },
  }: any = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isInfo, setIsInfo] = useState(false);
  const onCloseModal = () => setOpen(false);
  return (
    <>
      <div className="rounded-[13px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)] p-3 bg-white w-full">
        {/* <div className={`sortType ${classNameType}`}>
          <div className="circleSize"></div>
        </div> */}
        <div
          className="cardDetail"
          onClick={(e) => {
            AddToCart(
              item,
              item,
              items,
              dispatch,
              itemCount,
              menu_id,
              taxdata,
              setOpen
            );
          }}
        >
          <div className="cardHeadWrapper items-start gap-2 mb-2">
            <div className="">
              <div className="cardTitle">{item?.name}</div>
              {item?.description && (
                <p className="cardDesciption">
                  {ShortText(item?.description)}
                  <span
                    className="text-[#34558B] cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsInfo(true);
                    }}
                  >
                    ...More
                  </span>
                </p>
              )}
            </div>

            {item?.item_attachment?.attachment_url && (
              <AsyncImage
                key={`${item?.item_attachment?.base_url}${item?.item_attachment?.attachment_url}`}
                src={`${item?.item_attachment?.base_url}${item?.item_attachment?.attachment_url}`}
                Transition={Blur}
                loader={<div style={{ background: "#d3d3d3" }} />}
              />
            )}
          </div>

          <div className="priceWrap">
            <p className=" text-[.8em] text-[#151E3F] font-bold">
              {ParseFloat(GetItemPrice(item))}
            </p>
            {/* <button className="btn-secondary">Add</button> */}
            <button className="btn-secondary-sm">
              {!quantity ? (
                <span
                  className="block"
                  onClick={(e) => {
                    e.stopPropagation();
                    AddToCart(
                      item,
                      item,
                      items,
                      dispatch,
                      itemCount,
                      menu_id,
                      taxdata,
                      setOpen
                    );
                  }}
                >
                  Add
                </span>
              ) : (
                <div className="allCounterButton">
                  {quantity == 1 && checkItemType(item) == 3 && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        DecrementItemData(
                          item,
                          items,
                          dispatch,
                          itemCount,
                          taxdata
                        );
                      }}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 44 50"
                        fill="currentColor"
                      >
                        <path d="M28.9062 42.1875H30.4688C30.8594 42.1875 31.25 41.8945 31.25 41.4062V14.8438C31.25 14.4531 30.8594 14.0625 30.4688 14.0625H28.9062C28.418 14.0625 28.125 14.4531 28.125 14.8438V41.4062C28.125 41.8945 28.418 42.1875 28.9062 42.1875ZM13.2812 42.1875H14.8438C15.2344 42.1875 15.625 41.8945 15.625 41.4062V14.8438C15.625 14.4531 15.2344 14.0625 14.8438 14.0625H13.2812C12.793 14.0625 12.5 14.4531 12.5 14.8438V41.4062C12.5 41.8945 12.793 42.1875 13.2812 42.1875ZM42.9688 6.25H32.8125L29.4922 1.95312C28.7109 0.878906 27.0508 0 25.7812 0H17.9688C16.6016 0 14.9414 0.878906 14.1602 1.95312L10.9375 6.25H0.78125C0.292969 6.25 0 6.64062 0 7.03125V8.59375C0 9.08203 0.292969 9.375 0.78125 9.375H3.125V45.3125C3.125 47.9492 5.17578 50 7.8125 50H35.9375C38.4766 50 40.625 47.9492 40.625 45.3125V9.375H42.9688C43.3594 9.375 43.75 9.08203 43.75 8.59375V7.03125C43.75 6.64062 43.3594 6.25 42.9688 6.25ZM16.6992 3.80859C16.8945 3.41797 17.4805 3.22266 17.9688 3.125H25.7812C26.1719 3.22266 26.7578 3.41797 26.9531 3.80859L28.9062 6.25H14.8438L16.6992 3.80859ZM37.5 45.3125C37.5 46.1914 36.7188 46.875 35.9375 46.875H7.8125C6.93359 46.875 6.25 46.1914 6.25 45.3125V9.375H37.5V45.3125ZM21.0938 42.1875H22.6562C23.0469 42.1875 23.4375 41.8945 23.4375 41.4062V14.8438C23.4375 14.4531 23.0469 14.0625 22.6562 14.0625H21.0938C20.6055 14.0625 20.3125 14.4531 20.3125 14.8438V41.4062C20.3125 41.8945 20.6055 42.1875 21.0938 42.1875Z"></path>
                      </svg>
                    </span>
                  )}
                  {quantity > 1 && checkItemType(item) == 3 && (
                    <span
                      className=""
                      onClick={(e) => {
                        e.stopPropagation();
                        DecrementItemData(
                          item,
                          items,
                          dispatch,
                          itemCount,
                          taxdata
                        );
                      }}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 38 5"
                        fill="currentColor"
                      >
                        <path d="M36.7188 0H0.78125C0.292969 0 0 0.390625 0 0.78125V3.90625C0 4.39453 0.292969 4.6875 0.78125 4.6875H36.7188C37.1094 4.6875 37.5 4.39453 37.5 3.90625V0.78125C37.5 0.390625 37.1094 0 36.7188 0Z"></path>
                      </svg>
                    </span>
                  )}
                  {quantity > 0 && <span>{quantity}</span>}
                  {!quantity ? (
                    <span
                      className=""
                      onClick={(e) => {
                        e.stopPropagation();
                        AddToCart(
                          item,
                          item,
                          items,
                          dispatch,
                          itemCount,
                          menu_id,
                          taxdata,
                          setOpen
                        );
                      }}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 38 38"
                        fill="currentColor"
                      >
                        <path d="M36.7188 16.4062H21.0938V0.78125C21.0938 0.390625 20.7031 0 20.3125 0H17.1875C16.6992 0 16.4062 0.390625 16.4062 0.78125V16.4062H0.78125C0.292969 16.4062 0 16.7969 0 17.1875V20.3125C0 20.8008 0.292969 21.0938 0.78125 21.0938H16.4062V36.7188C16.4062 37.207 16.6992 37.5 17.1875 37.5H20.3125C20.7031 37.5 21.0938 37.207 21.0938 36.7188V21.0938H36.7188C37.1094 21.0938 37.5 20.8008 37.5 20.3125V17.1875C37.5 16.7969 37.1094 16.4062 36.7188 16.4062Z"></path>
                      </svg>
                    </span>
                  ) : (
                    checkItemType(item) != 3 && (
                      <span
                        className=""
                        onClick={(e) => {
                          e.stopPropagation();
                          AddToCart(
                            item,
                            item,
                            items,
                            dispatch,
                            itemCount,
                            menu_id,
                            taxdata,
                            setOpen
                          );
                        }}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 38 38"
                          fill="currentColor"
                        >
                          <path d="M36.7188 16.4062H21.0938V0.78125C21.0938 0.390625 20.7031 0 20.3125 0H17.1875C16.6992 0 16.4062 0.390625 16.4062 0.78125V16.4062H0.78125C0.292969 16.4062 0 16.7969 0 17.1875V20.3125C0 20.8008 0.292969 21.0938 0.78125 21.0938H16.4062V36.7188C16.4062 37.207 16.6992 37.5 17.1875 37.5H20.3125C20.7031 37.5 21.0938 37.207 21.0938 36.7188V21.0938H36.7188C37.1094 21.0938 37.5 20.8008 37.5 20.3125V17.1875C37.5 16.7969 37.1094 16.4062 36.7188 16.4062Z"></path>
                        </svg>
                      </span>
                    )
                  )}

                  {quantity >= 1 && checkItemType(item) == 3 && (
                    <span
                      className=""
                      onClick={(e) => {
                        e.stopPropagation();
                        incrementItemData(
                          item,
                          items,
                          dispatch,
                          itemCount,
                          taxdata
                        );
                      }}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 38 38"
                        fill="currentColor"
                      >
                        <path d="M36.7188 16.4062H21.0938V0.78125C21.0938 0.390625 20.7031 0 20.3125 0H17.1875C16.6992 0 16.4062 0.390625 16.4062 0.78125V16.4062H0.78125C0.292969 16.4062 0 16.7969 0 17.1875V20.3125C0 20.8008 0.292969 21.0938 0.78125 21.0938H16.4062V36.7188C16.4062 37.207 16.6992 37.5 17.1875 37.5H20.3125C20.7031 37.5 21.0938 37.207 21.0938 36.7188V21.0938H36.7188C37.1094 21.0938 37.5 20.8008 37.5 20.3125V17.1875C37.5 16.7969 37.1094 16.4062 36.7188 16.4062Z"></path>
                      </svg>
                    </span>
                  )}
                </div>
              )}
            </button>
          </div>
        </div>

        {/* customized .... */}
        {open && customizedData && Object.keys(customizedData).length > 0 && (
          <CustomizedModal
            menu_id={menu_id}
            taxdata={taxdata}
            open={open}
            onCloseModal={onCloseModal}
          />
        )}

        {/* variation ... */}
        {open && variationData && Object.keys(variationData).length > 0 && (
          <VariationModal
            menu_id={menu_id}
            taxdata={taxdata}
            open={open}
            onCloseModal={onCloseModal}
          />
        )}
        {<InfoModal item={item} isInfo={isInfo} setIsInfo={setIsInfo} />}
      </div>
    </>
  );
}
