export const TaxCalcualate = (
  taxdata: any,
  total_price?: number,
  quantity?: number
) => {
  let fixedTax: number = 0;
  let percentageTax: number = 0;
  let taxArray: any = [];
  taxdata.map_tax_class.map((tax: any) => {
    if (tax?.tax_rate[0]?.tax_type == "fixed") {
      taxArray.push({
        tax_id: tax?.tax_rate[0].id,
        tax_name: tax?.tax_rate[0].tax_name,
        tax_type: tax?.tax_rate[0].tax_type,
        tax_amount: tax?.tax_rate[0]?.tax_amount,
        actual_tax_amount: tax?.tax_rate[0]?.tax_amount * (quantity || 0),
      });
      fixedTax += tax?.tax_rate[0].tax_amount;
    } else {
      taxArray.push({
        tax_id: tax?.tax_rate[0].id,
        tax_name: tax?.tax_rate[0].tax_name,
        tax_type: tax?.tax_rate[0].tax_type,
        tax_amount: tax?.tax_rate[0]?.tax_amount,
        actual_tax_amount:
          (Number(total_price) * tax?.tax_rate[0]?.tax_amount) / 100,
      });
      percentageTax += tax?.tax_rate[0].tax_amount;
    }
  });
  return { fixedTax, percentageTax, taxArray };
};
