import { t } from "i18next";
import { object, string } from "yup";

export const myAccountSchema = object().shape({
  mobileNumber: string()
    .matches(/^[6-9]\d{9}$/, {
      message: "Profile.mobileNumberRequired",     
      excludeEmptyString: false,
    })
    // .required("Profile.mobileNumberRequired"),
    .required("Profile.mobileNumberRequired"),

  first_name: string().required("Profile.firstNameRequired"),
  last_name: string().required("Profile.lastNameRequired"),
  email: string()
    .email(t("Profile.invalidEmail"))
    .required("Email Required"),
  image: string().optional(),
});
export const initialmyAccountState = {
  mobileNumber: "",
  first_name: "",
  last_name: "",
  email: "",
  image: "",
};
