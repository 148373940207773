import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }: { children: any }) => {
  const { itemCount } = useSelector((state: any) => state?.cartData);
  let location = useLocation();

  if (!sessionStorage.getItem("yop-token")) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (location.pathname == ("/order-details" || "/order-details/")) {
    return children;
  } else if (
    itemCount == 0 &&
    location.pathname == ("/checkout" || "/checkout/")
  ) {
    return <Navigate to="/menu" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
